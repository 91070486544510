<template>
    <v-card v-scroll="onScroll" >

        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"

            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deleteError }}
        </v-snackbar>
        <v-app-bar v-if="isVisibleNavBar" app class="navbar-album">
            <v-toolbar-title>
                <v-img
                    :src="entrypoint+'/image_galleries/'+item['thumbnail']+'/big.jpeg'"
                    contain
                    class="avatar_img"
                ></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="nav_bar_titile" >
                <span>{{item['headline']}}</span>
                <br>
<!--                <span>({{item['fromYear']}} - {{item['toYear']}})</span>-->
            </div>

            <v-btn icon @click="performSearch">
                <v-icon color="black">mdi-magnify</v-icon>
            </v-btn>
            <v-btn icon  @click.stop="closeWin">
                <v-icon color="black">mdi-close</v-icon>
            </v-btn>
        </v-app-bar>
        <v-card
            class="mx-auto"
            v-if="item"

        >
            <v-tooltip left v-if="item && !isVisibleNavBar">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click.stop="closeWin" icon
                           class="closeWinButton"
                           right
                           fixed
                           v-on="onTooltip">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Close') }}</span>
            </v-tooltip>

            <v-img
                :alt="item['headline']"
                class="align-end text-white album-cover"
                @load="onScroll"
                :src="entrypoint+'/image_galleries/'+item['thumbnail']+'/big.jpeg'"
                cover
            >
                <div v-if="item['titleAlreadyInImage'] !== null && item['titleAlreadyInImage'] === false" class="text-center text-h5">
                    <p>{{ item['headline'] }}</p>
                </div>
<!--                <div class="text-center text-h6">-->
<!--                    <p>({{item['fromYear']}} - {{item['toYear']}})</p>-->
<!--                </div>-->
            </v-img>
        </v-card>
        <v-container v-if="item?.mentionImagePaths" fluid class="ma-0 pa-0 mention-container"  @click="toggleImages" >
            <v-row no-gutters>
                <v-col
                    v-for="(image, index) in item.mentionImagePaths"
                    :key="index"
                    class="d-flex child-flex"
                    cols="4"
                >
                    <v-img
                        :src="entrypoint + '/media/mentions/' + image"
                        aspect-ratio="1"
                        class="bg-grey-lighten-2"
                        cover
                    >
                        <template v-slot:placeholder>
                            <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                            >
                                <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-col>
            </v-row>

        </v-container>
        <div v-show="hasPlace" class="places-of-origin">
            {{ $t('app.PlacesOfOrigin') }}
            <br>
            <span v-html="formattedCityCount"></span>
        </div>
        <v-card
            v-if="item"
            class="mx-auto container-description"
            :class="isShadowVisibleDesc ? 'inner-shadow' : ''"
            :style="{ height: isExpandedDesc ? 'auto' : '130px' }"
            @click="toggleExpandDesc"
            width="100%"
        >
            <v-card-title class="d-flex justify-center align-center description-title">
                {{ item['headline'] }}
            </v-card-title>

<!--            <v-card-subtitle class="d-flex justify-center align-center description-subtitle">-->
<!--                ({{ item['fromYear'] }} - {{ item['toYear'] }})-->
<!--            </v-card-subtitle>-->

            <v-card-text class="d-flex justify-center align-center description-text" v-html="item['description']">
            </v-card-text>
        </v-card>
        <div class="text-justify pa-1" style="margin-bottom: 100px"
             v-if="item"
        >


            <div
                v-if="(typeof item['photographs']!=='undefined' && item['photographs'].length>0) || photographComments.length>0 || (typeof item['retrosnaps']!=='undefined' && item['retrosnaps'].length>0) || (typeof item['subGalleries']!=='undefined' && item['subGalleries'].length>0)"
                class="masonry-wrapper" style="margin-bottom:60px;">
                <div class="masonry" >
                    <div
                        :class="'masonry-item'+getRatioClass(slide)"
                        v-for="(slide) in item['subGalleries']"
                        :key="slide['@id']"
                    >
                        <v-img
                            v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"
                            class="masonry-content"
                            :aspect-ratio="1"
                            :src="entrypoint+'/image_galleries/'+slide.thumbnail+'/display'+(webpSupported ?'.webp' : '.jpeg')"
                            :lazy-src="entrypoint+'/image_galleries/'+slide.thumbnail+'/lazy'+(webpSupported ?'.webp' : '.jpeg')"
                            @dblclick.stop="carouselItemClick(slide['@id'])"
                            @click.stop="carouselItemClick(slide['@id'])"
                        >
                        </v-img>
                        <v-card-actions
                            transition="fade-transition"
                            class="homevcardactions">
                            <v-spacer></v-spacer>
                            <v-tooltip top
                                       v-if="personItem && item.owner && personItem['@id']===item.owner['@id'] && item && item['@type']==='https://schema.org/ImageGallery'">
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn
                                        @click.native.stop.prevent="removeGalleryFromGallery(slide)"
                                        v-on="onTooltip"
                                        icon>
                                        <v-icon>mdi-image-remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Gallery.RemoveFromGallery') }}</span>
                            </v-tooltip>
                        </v-card-actions>
                    </div>
                    <div
                        :class="'masonry-item'+getRatioClass(slide)"
                        v-for="(slide) in item['photographs']"
                        :key="slide['@id']"
                    >
                        <v-img v-if="(!slide.depthMapPath || !show25DPhotos)"
                               v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"

                               class="masonry-content"
                               :aspect-ratio="getRatioRounded(slide)"
                               :src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))+
                               (slide.thumbnailCoordinates && slide.thumbnailCoordinates.feed ? '?c='+slide.thumbnailCoordinates.feed.left+','+slide.thumbnailCoordinates.feed.top+','+slide.thumbnailCoordinates.feed.width+','+slide.thumbnailCoordinates.feed.height+',' : '')"
                               :lazy-src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))+
                               (slide.thumbnailCoordinates && slide.thumbnailCoordinates.feed ? '?c='+slide.thumbnailCoordinates.feed.left+','+slide.thumbnailCoordinates.feed.top+','+slide.thumbnailCoordinates.feed.width+','+slide.thumbnailCoordinates.feed.height+',' : '')"

                               @dblclick.stop="carouselItemClick(slide['@id'])"
                               @click.stop="carouselItemClick(slide['@id'])"
                        >
                            <v-img v-if="slide.retrosnaps.length>0"
                                   :class="slide.retrosnaps.length>0 ? 'retrosnapImg fadingEffect' : ''"
                                   :src="slide.retrosnaps.length > 0 ? entrypoint+'/thumbnail/list/'+slide.retrosnaps[slide.retrosnaps.length-1].filePath.replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg')) : entrypoint+'/thumbnail/list/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"
                                   :lazy-src="slide.retrosnaps.length>0 ? entrypoint+'/thumbnail/lazy/'+slide.retrosnaps[slide.retrosnaps.length-1].filePath : entrypoint+'/thumbnail/lazy/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"
                            ></v-img>

                        </v-img>

                        <Viewer25D
                            v-if="slide.retrosnaps.length===0 && slide.depthMapPath && show25DPhotos"
                            :imageOriginal="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+slide.filePath"
                            :imageLazy="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+slide.filePath"
                            :imageDepth="entrypoint+'/thumbnail/list/'+slide.depthMapPath"
                            :aspectRatio="getRatioRounded(slide)"
                            :horizontalThreshold="45"
                            :verticalThreshold="45"></Viewer25D>
                        <div class="retrosnapOverlay"
                             @dblclick.stop="carouselItemClick(slide['@id'])"
                             @click.stop="carouselItemClick(slide['@id'])"
                             v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"
                        >
                            <v-card-actions

                                transition="fade-transition"
                                class="homevcardactions">
                                <v-spacer></v-spacer>

                                <v-tooltip top
                                           v-if="personItem && item && item.owner && personItem['@id']===item.owner['@id'] && item['@type']==='https://schema.org/ImageGallery'">
                                    <template v-slot:activator="{ on: onTooltip }">
                                        <v-btn
                                            @click.native.stop.prevent="removePhotographFromGallery(slide)"
                                            v-on="onTooltip"
                                            icon>
                                            <v-icon>mdi-image-remove</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('app.Gallery.RemoveFromGallery') }}</span>
                                </v-tooltip>
                                <v-btn v-if="typeof slide.retrosnaps !=='object' && slide.retrosnaps.length>1"
                                       @click.native.stop.prevent=""
                                       :to="{name: 'PhotographRetrosnaps'+ (showColorized ? 'Color' : ''), params: {id: slide['id']}}" icon>
                                    <v-icon>icon-RetroSnap</v-icon>
                                </v-btn>
                            </v-card-actions>

                        </div>
                        <div v-if="slide.backFilePath" class="flipsideIcon">
                            <img style="width:32px;height:32px" src="/img/icons/FlipSide_Available_complete.svg"
                                 alt="flip"/>
                        </div>


                    </div>
                    <div
                        :class="'masonry-item'+getRatioClass(slide.photograph)"
                        v-for="(slide) in item['retrosnaps']"
                        :key="slide['@id']"

                    >


                        <v-img
                            v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"

                            class="masonry-content"
                            :aspect-ratio="getRatioRounded(slide.photograph)"
                            :src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"
                            :lazy-src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"

                            @dblclick.stop="carouselItemClick(slide['@id'])"
                            @click.stop="carouselItemClick(slide['@id'])"
                        >
                            <v-img
                                :class="'retrosnapImg fadingEffect'"
                                :src="entrypoint+'/thumbnail/list/'+slide.photograph.filePath.replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg'))"
                                :lazy-src="entrypoint+'/thumbnail/lazy/'+slide.photograph.filePath"
                            ></v-img>
                        </v-img>
                    </div>
                    <div
                        :class="'masonry-item'+getRatioClass(slide)"
                        v-for="(slide) in photographComments"
                        :key="slide['@id']"

                    >
                        <v-img
                            class="masonry-content"
                            :aspect-ratio="getRatioRounded(slide)"
                            :src="entrypoint+'/comments/'+slide.id+'/showThumbnail/'+(showColorized ? 'colorized.' : '')+'list'+(webpSupported ?'.webp' : '.jpeg')"
                            :lazy-src="entrypoint+'/comments/'+slide.id+'/showThumbnail/'+(showColorized ? 'colorized.' : '')+'lazy'+(webpSupported ?'.webp' : '.jpeg')"
                            @dblclick.stop="photographCommentClick(slide)"
                            @click.stop="photographCommentClick(slide)"
                            @mouseover="() => setActiveTouchEle(slide)"
                            v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"
                        >
                            <v-card-text class="homevcarddescription"
                                         v-if="showGalleryInfosOverlay || activeTouchEle===slide"
                                         transition="fade-transition"
                                         v-html="slide.text"
                            >
                            </v-card-text>
                        </v-img>
                        <v-card-actions
                            v-if="showGalleryInfosOverlay || activeTouchEle===slide"
                            transition="fade-transition"
                            class="homevcardactions">
                            <v-spacer></v-spacer>

                            <v-tooltip top
                                       v-if="personItem && item.owner && personItem['@id']===item.owner['@id'] && item && item['@type']==='https://schema.org/ImageGallery'">
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn
                                        @click.native.stop.prevent="removeCommentFromGallery(slide)"
                                        v-on="onTooltip"
                                        icon>
                                        <v-icon>mdi-image-remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Gallery.RemoveFromGallery') }}</span>
                            </v-tooltip>
                        </v-card-actions>
                    </div>

                </div>
            </div>
        </div>
    </v-card>
</template>
<style>
.places-of-origin{
    background-color: #dfd9d3;
    text-align: center;
    color: black;
    min-height: 50px;
    padding-top: 5px;
    font-family: "JetBrains Mono Thin",cursive;
    font-style: italic;
}
.inner-shadow{
    box-shadow: inset 0px 0px 30px rgba(0,0,0,0.9) !important;
}
.nav_bar_titile{
    font-size: 14px;
    position: absolute;
    width: 200px;
    left: 75px;
}
.avatar_img{
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 20px;
    left: 1%;
}
.navbar-album{
    background-color: #D0C2AF !important;
    border-radius: unset !important;
    box-shadow: inset 0px 10px 20px rgba(0,0,0,0.9) !important;
    filter: drop-shadow(0 0 0.75rem  black);
}
.description-title{
    color: black;
    font-family: Poppins,sans-serif;
    font-size: 28px;
}
.description-subtitle{
    color: black !important;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}
.description-text{
    color: black !important;
    font-family: Poppins,sans-serif;
    font-size: 18px;
}
.container-description{
    background-color: #D0C2AF !important;
}
.album-cover{
    margin: auto;
    max-width: 400px;

}




.album p {
    margin-bottom: 0 !important;
}


</style>
<script>
import {mapActions, mapGetters} from "vuex";
import {ENTRYPOINT} from "@/config/entrypoint";
import isWebpSupported from "@/utils/isWebpSupported";
import Viewer25D from "@/components/viewer25d/Viewer25D.vue";
import fetch from "@/utils/fetch";

export default {
    components: {Viewer25D},
    computed:{
        ...mapGetters({
            deleteError: 'imagegallery/del/error',
            isLoading: 'imagegallery/show/isLoading',
            item: 'imagegallery/show/retrieved',
            personItem: 'person/show/retrieved',
        }),
        formattedCityCount() {
            // Convert the cityCount object into the desired string format
            return Object.values(this.cityCount)
                .map(city => `${city.count} mal <strong>${city.cityName}</strong>`)
                .join(', ');
        },
        displayedImages() {
            return this.showAll ? this.images : this.images.slice(0, 3); // Adjust the number of images shown in the initial row
        },

    },
    data: () => ({
        error: null,
        isOpen: false,
        hasPlace:false,
        isVisibleNavBar:false,
        showAll: false,
        entrypoint: ENTRYPOINT,
        isShadowVisibleDesc: true,
        isExpandedDesc: false,
        photographComments: [],
        cityCount:[],
        cities: [],
        cityNamesWithCount:[],
        images: [
            '',
            '',
            '',
            '',
            '',
            '',
        ],
    }),
    watch: {
        settings () {
            this.showColorized = this.settings.showColorized
            this.showGalleryInfosOverlay = this.settings.showInfosOverlay
            this.show25DPhotos = typeof this.settings.show25DPhotos != 'undefined' ? this.settings.show25DPhotos : true
        },
        '$route.params.id': function (id) {
            window.console.log('watch $route.params.id ', this.$route.name);
            this.reset();
            if(!this.$route.name.startsWith('ImageGalleryShow')) return;


            this.oldId=id;
            //this.reset()
            //if (id) this.retrieve(decodeURIComponent(id))
            this.retrieve(decodeURIComponent(this.$route.params.id))
            this.checkActiveComment()
            this.onScroll();
        },
        '$route.params.comment_id': function () {
            this.checkActiveComment()
            if(this.$route.name!=='ImageGalleryShow') return;

        },
        personItem: function () {
            this.imagegalleries = this.personItem.imageGalleries
        },
        item: function () {
            if (this.item === [] || this.item === null) return
            //this.isActive=true;
            this.metaItem = this.item
            this.metaTitle = this.metaItem.headline + ' - Nimmerso'
            this.metaImage = this.entrypoint + '/image_galleries/' + this.metaItem.thumbnail + '/list.jpeg'
            this.metaHashtags = []
            if (this.categories) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }
            this.getPhotographComments()
            this.onScroll();
            this.hasPlace = this.metaItem.photographs && this.metaItem.photographs.some(photo => photo.city !== null);
            if (this.metaItem.photographs) {
                this.cityCount = this.metaItem.photographs.reduce((acc, photo) => {
                    if (photo.city !== null) {
                        // If the city ID is already in the accumulator, increment its count
                        acc[photo.city] = (acc[photo.city] || 0) + 1;
                    }
                    return acc;
                }, {});
                this.updateCityCount()
            }

        },

        commentItems: function () {
            this.checkActiveComment()

        },

        categories: function () {
            this.metaHashtags = []
            if (this.item) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }
            this.updateAttributes()
        },
        offsetTop: function() {


        }
    },
    methods: {
        ...mapActions({
            del: 'imagegallery/del/del',
            reset: 'imagegallery/show/reset',
            retrieve: 'imagegallery/show/retrieve',
        }),
      async updateCityCount() {
            // If photographs exist, proceed to handle city counts and names
            if (this.metaItem.photographs) {
                // Create an array to hold promises that fetch city names for each unique city ID
                const cityPromises = this.metaItem.photographs
                    .filter(photo => photo.city !== null)  // Ignore photos without a city ID
                    .map(photo => photo.city);  // Extract city IDs from the photos

                // Remove duplicates to only fetch unique city IDs
                const uniqueCityIds = [...new Set(cityPromises)];

                // Fetch city names for each unique city ID
                const cityNames = {};

                // Fetch city name for each unique city ID asynchronously
                for (let cityId of uniqueCityIds) {
                    const cityName = await this.getCityNameById(cityId);
                    cityNames[cityId] = cityName;  // Store the city name with its ID
                }

                // Now count the number of occurrences for each city ID
                this.cityCount = this.metaItem.photographs.reduce((acc, photo) => {
                    if (photo.city !== null) {
                        const cityId = photo.city;
                        // If the city doesn't exist in the accumulator, initialize it
                        if (!acc[cityId]) {
                            acc[cityId] = {count: 0, cityName: cityNames[cityId]};
                        }
                        // Increment the city count
                        acc[cityId].count += 1;
                    }
                    return acc;
                }, {});


            }
        },

// This method remains unchanged and will be used to fetch the city name by ID
        async getCityNameById(cityId) {
            try {
                const response = await fetch(`${this.entrypoint}/cities/${cityId}`);
                const data = await response.json();
                return data.name;  // Return the city name
            } catch (error) {
                return 'Unknown City';  // Return fallback if error occurs
            }
        },

        handleScroll() {
            this.isVisibleNavBar = window.scrollY > 400;
        },
        checkActiveComment () {
            //window.console.log('checkActiveComment')
            if (typeof this.$route.params.comment_id !== 'undefined') {
                this.activeComment = decodeURIComponent(this.$route.params.comment_id)
                if (this.activeComment === false) this.activeComment = null
                this.showInfo = true

                setTimeout(() => {
                    if (this.activeComment && this.$refs['comment_' + this.activeComment]) {
                        if (this.scrollY > 0) window.scrollY = this.scrollY
                        //this.$vuetify.goTo(this.$refs['comment_' + this.activeComment][0], {});

                    }
                }, 1000)

            }
        },
        getPhotographComments () {
            return fetch('/comments?galleries=' + this.$route.params.id)
                .then(response => response.json())
                .then((data) => {
                    this.photographComments = data['hydra:member']
                })
                .catch((e) => {
                    window.console.error(e)
                })
        },
        carouselItemClick (item) {
            //window.console.log(item.substr(0,11));
            if(item.substr(0,11)==='/retrosnaps'){
                this.$router.push({name: 'RetrosnapShow', params: {id: item}});
            }else if(item.substr(0,16)==='/image_galleries'){
                this.$router.push({name: 'ImageGalleryShow', params: {id: item}});
            }else{
                this.$router.push({name: 'PhotographShow'+ (this.showColorized ? 'Color' : ''), params: {id: item}});
            }

        },
        getRatioRounded(item){
            let ratio = 1;
            if(item.thumbnailCoordinates && item.thumbnailCoordinates.feed ){
                ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height;
            }else if(item.ratio ){
                ratio = item.ratio;
            }
            if(ratio>1.5){
                return 2;
            }else if(ratio<0.8){
                return 0.5;
            }
            return 1;
        },
        getRatioClass(item){
            let miClass='';
            let ratio = 1;
            if(item.documentType === 'ImageGallery'){
                miClass=' mi-small' ;
                if (item.showBig){
                    miClass=' mi-big';
                }
            }else if(item.thumbnailCoordinates && item.thumbnailCoordinates.feed ){
                ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height;
            }else if(item.box ){
                ratio = item.box.width / item.box.height;
            }else if(item.ratio ){
                ratio = item.ratio;
            }
            if (miClass==''){
                if(ratio>1.5){
                    miClass=' mi-horizontal';
                }else if(ratio<0.8){
                    miClass=' mi-vertical';
                }else if(ratio === 1){
                    // fixes the case with equal width and height which returned ' mi-small ' and the image was squashed
                    miClass=' mi-vertical';
                }else {
                    miClass=' mi-small';
                }
            }
            return miClass;
        },
        toggleExpandDesc() {
            this.isExpandedDesc = !this.isExpandedDesc;
            this.isShadowVisibleDesc = !this.isShadowVisibleDesc;
        },
        toggleImages() {
            this.showAll = !this.showAll;
        },
        closeWin () {
            this.isActive=false;
            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/feed')
        },
        openImages() {
            this.isOpen = !this.isOpen; // Toggle the state
        },
        onScroll () {
            if(this.isActive===false) return;
            if(window.scrollY>0) this.offsetTop = window.scrollY;
            //window.console.log('onScroll offsetTop', this.offsetTop, this.isActive);
            this.albumThumbnailHeight=Math.min(window.innerHeight,window.innerWidth)-this.offsetTop;
            this.albumThumbnailTop=0;
            this.albumThumbnailOpacity=1;
            if (this.albumThumbnailHeight<100){
                this.albumThumbnailTop=(this.albumThumbnailHeight-100);

                this.albumThumbnailOpacity=Math.min(100,this.albumThumbnailHeight)/100;
                this.albumThumbnailHeight=100;
            }
            if(typeof this.$refs['albumThumbnailImage'] !== 'undefined') this.$refs['albumThumbnailImage'].style='width: '+this.albumThumbnailHeight+'px; height: '+this.albumThumbnailHeight+'px; opacity: '+this.albumThumbnailOpacity;
            if(typeof this.$refs['albumThumbnail'] !== 'undefined') this.$refs['albumThumbnail'].style='top:'+this.albumThumbnailTop+'px';
        },
    },
    created () {
        //window.console.log('created',this.isActive);
        this.isActive=true;
        this.webpSupported = isWebpSupported()
        this.retrieve(decodeURIComponent(this.$route.params.id))
        //this.showColorized = this.settings.showColorized
        this.showColorized=this.$route.path.indexOf('/color')!==-1;
        if (this.token_data) {
            this.personRetrieve('/people/' + this.token_data.person_id)
        }
        this.onScroll();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
