<template>
    <v-card-actions class="footer-text text-center justify-center" color="gray darken-2" ><v-spacer/>
        <p><a href="https://www.nimmerso.com/">Nimmerso GmbH</a> | <span v-html="$t('app.FooterPrivacyAgb' ,{ impressumlink: 'https://nimmerso.com/impressum.html', privacylink:'https://nimmerso.com/datenschutzerklaerung.html', agblink:'/agb'})"></span></p>
        <v-spacer /></v-card-actions>
</template>

<style>
.footer-text{
    text-shadow: 1px 1px 1px #000000;
    font-size: 0.75rem;
}
.footer-text p a{
   color: #aaaaaa;
}
</style>
