.darken-bg{
    display: none;
}
.img-for-mobile{
    display: none;
}
@keyframes jumpInfinite {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: 20px;
    }
    100% {
        margin-top: 0;
    }
}
.background-image-intro {
    background-image: url("../../src/assets/img/backgr.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height:1024px;
    margin-top: 48px;
}
.content-left{
    position: relative;
}
.text-web{
    padding: 20px;
    position: relative;
    bottom:88%
}
.intro-text-one{
    font-family: Poppins,sans-serif;
    font-size: 21.5px;
    font-weight: 400;
    line-height: 2rem;
    text-align: left;
    width: 290px;
    height: 133px;
    color:#FFE89A;
    position: relative;
    left: 28px;
}
.intro-text-two{
    font-family: Poppins,sans-serif;
    font-size: 26.1px;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
    width: 300px;
    position: relative;
    top: -2rem;
    left: 1.25rem;


}
.intro-text-number{
    font-family: Poppins,sans-serif;
    font-size: 46px;
    font-weight: 900;
    line-height: 69px;
    text-align: center;
    color: #FFE89A;
    width: 320px;
    position: relative;
    top: -32px;
    left: 0px;

}
.intro-text-three{
    font-family: Poppins,sans-serif;
    font-size: 21.5px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: #FFE89A;
    width: 250px;
    position: relative;
    top: -55px;
    left: 45px;

}
.intro-text-four{
    font-family: Poppins,sans-serif;
    font-size: 21.5px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #FFE89A;
    width: 280px;
    position: relative;
    top: -60px;
    left: 28px;

}
.intro-content{
    padding: 10px 40px 10px 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 1024px;
}
.time-image{
    background-image: url("../../src/assets/img/collectionsStart_DieZeitdraengt 1.png");
    height: 104px;
    width: 437px;
    background-size: cover;
}
.join-image-web {
    background-image: url("../../src/assets/img/button-location.png");
    height: 145px;
    width: 218px;
    cursor: pointer;
    background-size: cover;
    position: absolute;
    left: 50%; /* Horizontal center */
    top: 34%; /* Vertical center */
    transform: translate(-50%, -50%); /* Adjust the position by half of the element's width and height */
    z-index: 1;
    animation: jumpInfinite 1.5s infinite;
}

.join-image{
    display: none;
    opacity: 0.8;
    background-image: url("../../src/assets/img/button-location.png");
    height: 145px;
    width: 218px;
    cursor: pointer;
    background-size: cover;
    position: absolute;
    right: 20px;
    top: 84%;
    z-index: 1;
    animation: jumpInfinite 1.5s infinite;
}
.content-left{
    height: 1000px;
    position: relative;
}
.panel_mobile{
    display: none;
}
.panel_web{
    background-image: url("../../src/assets/img/Rectangle 1.png");
    height: 708px;
    width: 430px;
    background-size: cover;
    position: absolute;
}
.border{
    background-image: url("../../src/assets/img/ArtNouveau_with_alpha 1.png");
    height: 694px;
    width: 348px;
    position: relative;
    right: -20%;
    background-size: cover;
}
@media screen and (max-width: 1024px) {
    .join-image{
        display: none;
    }
    .time-image{
        height: 65px;
        width: 290px;
    }
    .panel_web{
        left: 40%;
    }
}
@media screen and (max-width: 960px) {
    .join-image{
        display: none;
    }
    .panel_web{
        width: 400px;
        height: 640px;
        background-size: contain;
        left: 47%;
    }
    .border{
        height: 600px;
        width: 350px;
        background-size: contain;
    }
    .text-web{
        padding: 20px;
        position: relative;
        bottom:88%
    }
    .intro-text-one{
        font-size: 20px;
        width: 284px;
        position: absolute;
        text-align: left;
        top: 25px
    }
    .intro-text-two{
        font-size: 1.25rem;
        width: 350px;
        position: absolute;
        text-align: left;
        top: 150px
    }
    .intro-text-number{
        font-size: 40px;
        width: 300px;
        position: absolute;
        text-align: center;
        top: 210px
    }
    .intro-text-three{
        position: absolute;
        top:270px;
        width: 300px;
        text-align: left;
        font-size: 1.25rem;
        left:27px;

    }
    .intro-text-four{
        position: absolute;
        top:350px;
        width: 300px;
        color: white;
        font-size: 20px;
    }
}
.panel_mobile{
    display: none;
    position: absolute;
    background-size: contain;
    left: 50%;
    top: 70px;
    transform: translateX(-50%);
    height: 793px;
    width: 364px;
}
@media screen and (max-width: 768px) {
    .join-image-web{
        display: none;
    }
    .join-image{
        display: block;
    }
    .intro-content{
        padding: unset;
    }
    .panel_web{
        display: none;
    }
    .background-image-intro{
        height: 870px;
    }
    .time-image{
        height: 65px;
        width: 290px;
    }
    .right-column{
        display: none;
    }
    .content-left{
        margin: auto;
    }
    .panel_mobile {
        display: inline-block;
        position: absolute;
        height: 72%;
        width:70%;
        background-size: contain;
        top: 50%;
        transform: translate(80%, -65%);
    }

    .darken-bg{
        display: block;
        opacity: 0.5;
        background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        filter: blur(10px);
        background-image: url("../../src/assets/img/darken-bg.png");
        position: absolute;
        top: -14px;
        left: -13%;
        height: 560px;
        width: 500px;
        background-size: cover;
    }
    .intro-text-one{
        display: inline-block;
        font-family: Poppins,sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: right;
        width: 198px;
        top:56px;
        left: 13px;
        height: 111px;
    }
    .intro-text-two{
        display: inline-block;
        font-family: Poppins,sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        width: 250px;
        position: absolute;
        left: -3%;
        top: 26%;

    }
    .intro-text-three{
        font-family: Poppins,sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        width: 203px;
        display: inline-block;
        position: absolute;
        top: 44%;
        left: 12%;

    }
    .intro-text-number{
        font-family: Poppins sans-serif;
        font-size: 40px;
        font-weight: 900;
        line-height: 60px;
        text-align: left;
        width: 135px;
        display: inline-block;
        position: absolute;
        top: 38%;
        left:30%;

    }
    .intro-text-four{
        font-family: Poppins,sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: right;
        width: 248px;
        color: white;
        display: inline-block;
        position: absolute;
        top: 53%;
        left:-10%;

    }
    .join-image {
        position: fixed;
        cursor: pointer;
        bottom: 5vh;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
        height: 100px;
        z-index: 1;
    }
}
@media screen and (max-width: 600px) {
    .panel_mobile{
        transform: translate(30%, -65%);
    }
}
@media screen and (max-width: 500px) {
    .panel_mobile{
        transform: translate(-24%, -76%);
    }
}
@media screen and (max-width: 435px) {
    .panel_mobile{
        transform: translate(-26%, -65%);
    }
}

@media screen and (max-width: 425px) {
    .panel_web{
        display: none;
    }
    .time-image{
        height: 65px;
        width: 290px;
    }
    .panel_mobile{
        display: block;
        height: 60%;
        width: 90%;
        top: 50%;
        transform: translate(-15%, -75%);
    }
    .darken-bg{
        display: block;
        opacity: 0.5;
        background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        filter: blur(10px);
        background-image: url("../../src/assets/img/darken-bg.png");
        position: absolute;
        top: -30px;
        left: -13%;
        height: 560px;
        width: 500px;
        background-size: cover;
    }

    .intro-text-one{
        font-family: Poppins,sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: right;
        width: 198px;
        height: 111px;
        left: 20px;
        display: inline-block;
    }
    .intro-text-two{
        font-family: Poppins,sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        width: 250px;
        display: inline-block;
        position: absolute;
        left: -2%;
        top: 30%;

    }
    .intro-text-three{
        font-family: Poppins,sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        width: 203px;
        display: inline-block;
        position: absolute;
        top: 53%;
        left: 6%;

    }
    .intro-text-number{
        font-family: Poppins sans-serif;
        font-size: 40px;
        font-weight: 900;
        line-height: 60px;
        text-align: left;
        width: 135px;
        display: inline-block;
        position: absolute;
        top: 45%;
        left: 22%;

    }
    .intro-text-four{
        font-family: Poppins,sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: right;
        width: 248px;
        color: white;
        display: inline-block;
        position: absolute;
        top: 64%;
        left: -8%;

    }
}


@media screen and (max-width: 390px) {
    .panel_mobile{
        transform: translate(-24%, -75%);
    }
}

