<template>
    <div class="contact-container">
        <div class="background">
            <div id="contact-panel">
                <div class="panel">
                    <h3>Sag uns deine Meinung!</h3>
                    <p>
                        Nimmerso soll toll werden! Teile uns
                        gerne etwas mit, auch wenn es nur ein
                        spontaner Gedanke ist.<br />
                        <strong>Ist dir etwas aufgefallen,
                            was besser sein könnte?</strong>
                    </p>
                </div>

                <div class="actions">
          <textarea
              v-model="feedbackText"
              placeholder="Schreibe etwas..."
          ></textarea>

                    <input
                        v-model="email"
                        type="email"
                        placeholder="E-Mail Adresse (optional)"
                        class="email-input"
                        :class="{'is-invalid': email && !emailPattern.test(email)}"
                    />
                    <div v-if="email && !emailPattern.test(email)" class="not-valid-email">
                        Bitte geben Sie eine gültige E-Mail-Adresse ein.
                    </div>
                    <div class="newsletter">
                        <div class="newsletter-checkbox">
                            <input
                                v-if="email && emailPattern.test(email)"
                                v-model="subscribeNewsletter"
                                type="checkbox"
                                id="newsletter"
                            />
                            <input
                                v-if="!email || !emailPattern.test(email)"
                                type="checkbox"
                                id="newsletter"
                                disabled
                            />
                            <label class="ml-2" for="newsletter">
                                Nimmerso Newsletter abonnieren
                            </label>
                        </div>

                        <div class="newsletter-text">
                            Vielleicht 1-2x im Monat interessante Recherche-Ergebnisse zu Fotos
                            und erfolgreichen Crowdfunding-Ergebnissen
                        </div>
                    </div>

                    <div class="actions-buttons">
                        <v-btn
                            class="cancel-x"
                            alt="Cancel"
                            @click="goBack"
                        >
                            <img class="arrow-cancel" src="@/assets/img/Arrow-simple90.svg" alt="OK">
                            Zurück
                        </v-btn>
                        <v-btn
                            class="send"
                            :disabled="feedbackText.trim() === ''"
                            @click="openCaptcha"
                        >
                            Senden
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <!-- ✅ CAPTCHA Modal -->
        <v-dialog content-class="captcha-dialog" fullscreen v-model="captchaModal">
            <CaptchaPuzzle @solved="captchaSuccess" @close="captchaModal = false" />
        </v-dialog>

        <!-- Email Mismatch Pop-up -->
        <v-dialog v-model="showEmailMismatchModal" max-width="400px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">E-Mail-Übereinstimmung</span>
                </v-card-title>
                <v-card-text>
                    <p>
                        Du bist derzeit mit einer anderen E-Mail-Adresse
                        für den Newsletter angemeldet: <strong>{{ userEmail }}</strong>
                    </p>
                    <p>
                        Möchtest du die neue E-Mail-Adresse <strong>{{ email }}</strong> verwenden?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="closeEmailMismatchModal">Abbrechen</v-btn>
                    <v-btn @click="confirmEmailChange">Bestätigen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
/* Email mismatch modal styling */
.v-dialog__content {
    padding: 20px;
}

.v-btn {
    background-color: #4caf50;
    color: white;
    margin-right: 10px;
}

.v-btn:hover {
    background-color: #45a049;
}
</style>
<style >
/* Modal style */
.captcha-dialog {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
}
.arrow-cancel{
    padding: 2px;
    width: 30px;
    height: 30px;
    rotate: 180deg;

}
.contact-container {
    position: absolute;
    top: 0;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
}


.newsletter{
    background-color: #8b8189;
    padding: 10px;
    border-radius: 10px;
}
.not-valid-email{
    color: #ff1e00;
    font-size: 15px;
}
.email-input {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    font-size: 1rem;
    margin-top: 10px;
    background-color: #f0e4d6;
}

/* Newsletter checkbox container */
.newsletter-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

/* Italicized helper text */
.newsletter-text {
    font-style: italic;
    font-size: 14px !important;
    line-height: 1rem;
    margin-top: 5px;
    width: 300px;
    margin-left: 20px;
    color: black !important;
}

.captcha-dialog {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
}
.contact-container {
    position: absolute;
    top: 0;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1; /* Ensure it's below the list-container */
}
#contact-panel p {
    text-align: left;
    color: #333;
    line-height: 1.5;
    font-size: 1.3rem !important;
    padding: 1rem;
}
.actions {
    background-color: rgba(255, 255, 255, 0.05);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    //justify-content: space-between;
    gap: 10px;
    margin: 0;
    padding: 10px 15px;
    border-radius: 0;
    width: 100%;
    max-width: 400px;
}
.background {
    min-height: 100vh;
    position: relative;
    background-image: url("../../assets/img/Kreuzerweg_Fussballer_beim_Tausziehen_1680x1056.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1%;
}



#contact-panel {
    margin: 0 auto; /* Centers the panel horizontally */
    display: flex;
    flex-direction: column; /* Ensures content inside is stacked */
    align-items: center; /* Aligns content in the center horizontally */
    width: 100%;
    max-width: 400px; /* Ensures consistent width for the panel */
}


#contact-panel p {
    text-align: left;
}


.cancel-x {
  padding: 5px;
    cursor: pointer; /* Adds a pointer cursor for interactivity */
}
button {
    padding: 5px 3px; /* Uniform padding for both buttons */
    border: none;
    border-radius: 5px; /* Same rounded corners for both buttons */
    font-size:1rem;
    flex-shrink: 0; /* Prevents buttons from shrinking in the flex container */
    height: auto; /* Ensures consistent height */
    width: 120px; /* Ensures both buttons have the same width */
    text-align: center;
}
@media (max-width: 768px) {
    button {
        padding: 5px 3px; /* Uniform padding for both buttons */
        border: none;
        border-radius: 5px; /* Same rounded corners for both buttons */
        font-size: 0.9rem;
        flex-shrink: 0; /* Prevents buttons from shrinking in the flex container */
        height: auto; /* Ensures consistent height */
        width: 120px; /* Ensures both buttons have the same width */
        text-align: center;
    }
}

/* Text field styling */
textarea {
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;

    font-size: 1rem;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: #333;
    background-color: #f0e4d6;
    /* background-color: rgba(255, 255, 255, 0.3); */
    resize: none;
}

.actions {
    background-color: rgba(255, 255, 255, 0.05);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* justify-content: space-between; */
    gap: 10px;
    margin: 0;
    padding: 10px 15px;
    border-radius: 0;
    width: 100%;
    max-width: 400px;
}

.actions-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.send {
    background-color: #ccc;
    color: rgb(93, 93, 105);
    cursor: not-allowed;
}

/* Optional: Styling for enabled SENDEN button when it's not grayed out */
.send:not(.disabled) {
    background-color: #4CAF50; /* Green for active state */
    color: white;
    cursor: pointer;
}

.send:not(.disabled):hover {
    background-color: #45a049;
}
h3 {
    font-size: clamp(2rem, 34px, 3rem);
    line-height: 1.2;
    padding: 0 20px;
    color: black;
    font-weight: normal;
    font-family: 'Jost', sans-serif;
}
@media screen and (max-width: 768px) {
    .background {
        height: unset;
        align-items: center;
        padding-top: 0;
        background-size: 330%; /* Scale up the image to 120% */
        /* background-position: top 150px !important; */

    }


}
</style>

<script>
import CaptchaPuzzle from "../captcha/CaptchaPuzzle.vue";
import fetch from "../../utils/fetch";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        CaptchaPuzzle,
    },

    data: () => ({
        feedbackText: "",
        captchaModal: false, // ✅ Controls modal visibility
        email: "",
        userEmail: "",
        subscribeNewsletter: false, // New data property for the newsletter checkbox
        emailPattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, // Email regex pattern
        showEmailMismatchModal: false, // Controls the pop-up visibility
    }),

    computed: {
        ...mapGetters({
            token_data: "user/token/token_data",
            personItem: "person/show/retrieved",
            userRetrieved: "user/show/retrieved",
        }),
    },

    created() {
        if (this.token_data) {
            this.personRetrieve("/people/" + this.token_data.person_id);
            this.userRetrieve("/users/" + this.token_data.id);
        }
    },

    watch: {
        userRetrieved: function () {
            this.userEmail = this.userRetrieved.email;
        },
    },

    methods: {
        ...mapActions({
            personRetrieve: "person/show/retrieve",
            userRetrieve: "user/show/retrieve",
        }),

        goBack() {
            window.history.back();
        },

        openCaptcha() {
            // Check if the user's email is different from the email input
            if (this.userEmail && this.userEmail !== this.email) {
                this.showEmailMismatchModal = true; // Show the pop-up
            }
            else if(this.subscribeNewsletter){
                this.handleSubmit();
            }
            else {
                this.captchaModal = true; // Proceed to CAPTCHA if no mismatch
            }
        },

        captchaSuccess() {
            this.captchaModal = false;
            this.handleSubmit();
        },

        closeEmailMismatchModal() {
            this.showEmailMismatchModal = false; // Close the pop-up without action
        },

        confirmEmailChange() {
            this.showEmailMismatchModal = false; // Close the pop-up
            this.handleSubmit();
        },

        handleSubmit() {
            if (this.feedbackText.trim() !== "") {
                window.console.log("Feedback submitted:", this.feedbackText);
                window.console.log("Email:", this.email);
                window.console.log("Subscribe to Newsletter:", this.subscribeNewsletter);

                let data = new FormData();
                data.append("feedbackText", this.feedbackText);
                data.append("email", this.email);
                data.append("subscribeNewsletter", this.subscribeNewsletter);
                if (this.token_data && this.token_data.id){
                    data.append("userId", this.token_data.id);
                    data.append("userOldEmail", this.userEmail);
                }

                return fetch("/api/submit-feedback", {
                    method: "POST",
                    body: data,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        window.console.log("Response:", data);
                    })
                    .catch((error) => {
                        window.console.error("Error submitting feedback:", error);
                    })
                    .finally(() => {
                        this.feedbackText = "";
                        this.email = "";
                        this.subscribeNewsletter = false;
                    });
            }
        },
    },
};
</script>

