<template>

    <v-dialog
        v-model="startDialog"
        fullscreen
        content-class="justify-center"
        fill-height
        justify="center"
        class="justify-center"
        fluid
    >

        <div class="startDialog justify-center text-center">


            <v-card-title
                class="text-h5 button_style_pointer"
                primary-title
                @click="goToFeed"
            >
                <img src="/img/icons/Ebicon_6-tone_dark.svg" style="width:100px; height:100px" alt="Nimmerso"/> <img
                src="@/assets/img/nimmerso_typeface.svg" style="width:calc( 100% - 110px ); max-width:240px;" alt="Nimmerso"/>
            </v-card-title>
            <Flicking v-if="items" :options="{ circular: true }" :plugins="plugins">
                <div class="flicking-panel" v-for="item in items" :key="item.id">
                    <v-img
                        :aspect-ratio="1"
                        :src="entrypoint+item.images[0]+'/list'+(webpSupported ? '.webp' : '.jpeg')"
                        :lazy-src="entrypoint+item.images[0]+'/lazy'+(webpSupported ? '.webp' : '.jpeg')"
                        @dblclick.stop="carouselItemClick(item)"
                        @click.stop="carouselItemClick(item)"
                    >
                    </v-img>
                </div>

            </Flicking>

            <v-card-text class="text-h5" style="font-weight: bold">Tausende weitere Fotos von früher warten auf dich!</v-card-text>


            <v-card-text class="text-center pa-0 mt-2">
                        <v-btn
                            color="#DDD7FC"
                            class="text-h5"
                            style="color: #282C38; text-transform: none; font-weight: bold"
                            :to="{ name: 'Login' }"
                            raised
                        ><v-icon class="mr-2">icon-Hat</v-icon> {{ $t('app.Login.SignIn') }}

                        </v-btn>
            </v-card-text>
            <v-card-text class="text-center pa-0 mt-2">
                <v-btn
                    class="text-h5 continue-without-login"
                    @click ="openWithoutLogin"
                >
                    <v-avatar class="avatar-img">
                        <v-img
                            src="/img/icons/Ebicon_6-tone_dark.svg"
                            class="avatar-img"
                        ></v-img>
                    </v-avatar>

                    {{ $t('app.Login.ContinueWithoutLogin') }}

                </v-btn>
            </v-card-text>
            <v-card-text class="pt-4 pb-1">oder kostenlos</v-card-text>
            <v-card-text class="text-center pa-0 pb-5">
                <v-btn
                    color="#282C38"
                    class="text-h5"
                    style="color: #DDD7FC; text-transform: none; font-weight: bold"
                    raised
                    :to="{ name: 'Register' }"
                >
                    <v-icon class="mr-2">icon-Star</v-icon> {{ $t('app.Login.Register') }}

                </v-btn>
            </v-card-text>

            <nimmerso-footer class="text-center justify-center"></nimmerso-footer>

        </div>
    </v-dialog>

</template>
<style>
.avatar-img{
    height: 30px !important;
     width: 30px !important;
    min-width: 30px !important;
}
.continue-without-login:hover {
    background-color: unset !important;
    color: rgb(221, 215, 252) !important;
    text-transform: none;
    border: unset !important;
    width: 100%;
}
.continue-without-login {
    cursor: pointer;
    background-color: unset !important;
    color: rgb(221, 215, 252) !important;
    text-transform: none;
    border: unset !important;
    width: 100%;
}

.startDialog {
    /*background: transparent !important;
    margin-left: auto !important;
    margin-right: auto !important;

     */
    background: rgba(0,0,0,0.75);
    max-width: 600px;
    min-width: 300px;
    overflow: auto;
    height: 100vh;
    font-family: "Roboto Condensed", Arial, sans-serif;
}

.startDialog:after {
    background-image: url('~@/assets/img/Login_background.jpg');
    content: "";
    background-size: cover;
    background-position: center;
    background-color: gray;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    z-index: -1;
}
.button_style_pointer{
    cursor:pointer;
}
.startDialog .v-input fieldset {
    background-color: rgba(0, 0, 0, 0.5)
}
.flicking-panel {
    background-color: none;
    /*height: 100%;*/
    width: 100%;
    margin: 0 12px 0 12px;
    /*border-radius: 10px;*/
}
.flicking-panel .v-image {
    /*border-radius: 10px; */
    margin: 0 12px 0 12px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import NimmersoFooter from '../NimmersoFooter'
import { ENTRYPOINT } from '@/config/entrypoint'
import { Flicking } from '@egjs/vue-flicking'
import { Perspective, AutoPlay } from '@egjs/flicking-plugins'
import '@egjs/vue-flicking/dist/flicking.css'
import isWebpSupported from '../../utils/isWebpSupported'

export default {
    components: {
        NimmersoFooter,
        Flicking: Flicking
    },
    created() {
        this.webpSupported=isWebpSupported();
        if (this.token) {
            this.$router.push({name: 'HomeList' })
        }else{
            this.getPage('/feed_items?documentType=ImageGallery&showInFeed=true&categories[]=646f1cf6888df80a64039689&categories[]=646f1f0a46a4247847163d82&order[computedRating]=DESC&itemsPerPage=10' )
        }
    },
    data () {
        return {
            entrypoint: ENTRYPOINT,
            webpSupported: true,
            showPassword: false,
            startDialog: true,
            email: '',
            password: '',
            error: '',
            rules: {
                required: value => !!value || this.$t('app.Login.Required'),
                min: v => v.length >= 6 || this.$t('app.Login.PasswordHint'),
                email: [
                    v => !!v || this.$t('app.Login.EmailRequired'),
                    v => /.+@.+\..+/.test(v) || this.$t('app.Login.EmailMustBeValid'),
                ]
            },
            plugins: [new Perspective({rotate: -1, scale: 2, perspective: 600}), new AutoPlay({duration:4000,animationDuration:2000})]
        }
    },
    computed: {
        ...mapGetters({
            items: 'feeditem/list/items',
            token: 'user/token/token',
        }),
    },
    methods: {
        ...mapActions({
            settoken: 'user/token/settoken',
            setrefreshtoken: 'user/token/setrefreshtoken',
            gettoken: 'user/token/gettoken',
            gettokenexpiretime: 'user/token/gettokenexpiretime',
            gettokendata: 'user/token/gettokendata',
            removetoken: 'user/token/removetoken',
            removerefreshtoken: 'user/token/removerefreshtoken',
            getPage: 'feeditem/list/default',
        }),
        openWithoutLogin(){
          this.removetoken()
          this.removerefreshtoken()
            this.$router.push({name: 'HomeList' })
        },
        setError (message) {
            this.error = message
        },

        openLink (url) {
            window.location = url
        },
        carouselItemClick(item){
            const documentType = item['documentType'];
            this.$router.push({name: documentType + 'Show', params: {id: decodeURIComponent(item['id'])}})

        },
        goToFeed(){
            this.$router.push({path: '/feed'})
        }
    }
}
</script>

<style lang="css">
body {
    background: #605B56;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>
