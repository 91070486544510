<template>
    <div>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deleteError }}
        </v-snackbar>
        <!-- Display the copied image if available -->
        <v-card v-if="imageDataUrl" class="mt-4" outlined>
            <v-card-title>Captured Image</v-card-title>
            <v-card-text>
                <img :src="imageDataUrl" alt="Captured Box" class="max-width-100" />
            </v-card-text>

        </v-card>

        <div id="imageviewer"
             :style="{
                  position: 'fixed',
                  top: '0',
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: item.originalName && item.originalName.includes('_a.') ? '#edecea' : 'black'
                }"
             @mousemove="resetInactivityTimer" @click="resetInactivityTimer"></div>
        <div class="fade-out"   :style="(hideGui ? ' opacity:0;transition:opacity 500ms;' : 'opacity:1;transition:opacity 500ms;')" >

            <v-btn
                v-if="!isZoomedIn"
                class="fit-to-screen"
                @click="resetZoom"
            >
                Zoom 1:1
            </v-btn>

            <v-btn
                v-else
                class="fit-to-screen"
                @click="totalZoom"
            >
                {{ $t('app.Comment.ZoomTotal') }}
            </v-btn>
        </div>
        <div v-if="dialog"
            class="layer-selection">
         <div class="tools-layer"  v-click-outside="closeDialog">
             <v-tooltip left style="transition-delay: 0.15s;" >
                 <template v-slot:activator="{on}">
                     <v-btn @click.stop="selectPerson" icon class="mb-2" v-on="on">
                         <img
                             src="@/assets/img/Select_Person.svg"
                             alt="Custom Icon"
                             width="60"
                             height="60"
                         />
                         <div class="name-selected-item">{{  $t('app.Comment.SelectPerson') }}</div>
                     </v-btn>
                 </template>
             </v-tooltip>
             <v-tooltip left style="transition-delay: 0.15s;">
                 <template v-slot:activator="{ on: onTooltip }">
                     <v-btn @click.stop="selectBuilding" v-on="onTooltip" icon class="mb-2">
                         <img
                             src="@/assets/img/Select_Building.svg"
                             alt="Custom Icon"
                             width="60"
                             height="60"
                         />
                         <div class="name-selected-item">{{ $t('app.Comment.SelectBuilding') }}</div>
                     </v-btn>
                 </template>
             </v-tooltip>
             <v-tooltip left style="transition-delay: 0.15s;">
                 <template v-slot:activator="{ on: onTooltip }">
                     <v-btn @click.stop="selectCar" v-on="onTooltip" icon class="mb-2">
                         <img
                             src="@/assets/img/Select_Car.svg"
                             alt="Custom Icon"
                             width="60"
                             height="60"
                         />
                         <div class="name-selected-item">{{ $t('app.Comment.SelectCar') }}</div>
                     </v-btn>
                 </template>
             </v-tooltip>
             <v-tooltip left style="transition-delay: 0.15s;">
                 <template v-slot:activator="{ on: onTooltip }">
                     <v-btn @click.stop="selectText" v-on="onTooltip" icon class="mb-2">
                         <img
                             src="@/assets/img/Select_text.svg"
                             alt="Custom Icon"
                             width="60"
                             height="60"
                         />
                         <div class="name-selected-item">{{ $t('app.Comment.SelectText')}}</div>
                     </v-btn>
                 </template>
             </v-tooltip>
             <v-tooltip left style="transition-delay: 0.15s;">
                 <template v-slot:activator="{ on: onTooltip }">
                     <v-btn @click.stop="selectSection" v-on="onTooltip" icon class="mb-2">
                         <img
                             src="@/assets/img/Select_empty.svg"
                             alt="Custom Icon"
                             width="60"
                             height="60"
                         />
                         <div class="name-selected-item">{{ $t('app.Comment.SelectEmpty') }}</div>
                     </v-btn>
                 </template>
             </v-tooltip>
         </div>
<!--            <div class="close-selection-layer" @click="closeDialog" >-->
<!--                <img-->
<!--                    src="@/assets/img/X.svg"-->
<!--                    alt="Custom Icon"-->
<!--                    width="60"-->
<!--                    height="60"-->
<!--                />-->
<!--            </div>-->
        </div>

        <v-speed-dial
            v-if="item && !pathEditMode && !dialog && item.originalName && !item.originalName.includes('_a.') "
            v-model="speedDialShow"
            transition="slide-y-reverse-transition"
            direction="left"
            :style="{
                position: 'fixed',
                right: '50px',
                top: '25px',
                opacity: hideGui ? 0 : 1,
                transition: 'opacity 500ms'
            }"
        >
            <template v-slot:activator>
                <v-btn
                    v-model="speedDialShow"
                    icon large
                >
                    <v-icon v-if="speedDialShow">
                        mdi-cog-off-outline
                    </v-icon>
                    <v-icon v-else>
                        mdi-cog
                    </v-icon>
                </v-btn>
            </template>
<!--            <v-tooltip left>-->

<!--                <template v-slot:activator="{ on: onTooltip }">-->
<!--                    <v-btn @click.stop="toggleVoice" v-on="onTooltip" icon>-->
<!--                        <v-icon v-if="audioPlaying">mdi-voice-off</v-icon>-->
<!--                        <v-icon v-if="!audioPlaying">mdi-account-voice</v-icon>-->
<!--                    </v-btn>-->
<!--                </template>-->
<!--&lt;!&ndash;                <span v-if="audioPlaying">{{ $t('app.VoiceOff') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <span v-if="!audioPlaying">{{ $t('app.VoiceOn') }}</span>&ndash;&gt;-->
<!--            </v-tooltip>-->

<!--            <v-tooltip left>-->

<!--                <template v-slot:activator="{ on: onTooltip }">-->
<!--                    <v-btn @click.stop="toggleTextlayer" v-on="onTooltip" icon>-->
<!--                        <v-icon v-if="showTextlayer">mdi-message-bulleted-off</v-icon>-->
<!--                        <v-icon v-if="!showTextlayer">mdi-message-bulleted</v-icon>-->
<!--                    </v-btn>-->
<!--                </template>-->
<!--&lt;!&ndash;                <span v-if="!showTextlayer">{{ $t('app.Photograph.ShowTextlayer') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <span v-if="showTextlayer">{{ $t('app.Photograph.HideTextlayer') }}</span>&ndash;&gt;-->
<!--            </v-tooltip>-->

            <v-tooltip left>
                <template v-slot:activator="{ on: onTooltip }">

                    <v-btn @click.stop="showContrast=true" v-on="onTooltip" icon>
                        <v-icon>mdi-contrast-circle</v-icon>
                    </v-btn>
                </template>

            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.stop="rotateImage" v-on="onTooltip" icon>
                            <v-icon>mdi-rotate-right</v-icon>
                        </v-btn>
                </template>
            </v-tooltip>
            <v-tooltip v-if="item.isBlackAndWhite===true" left>
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click.stop="toggleColorized" v-on="onTooltip" icon>
                        <v-icon v-if="colorized">mdi-invert-colors-off</v-icon>
                        <v-icon v-if="!colorized">mdi-invert-colors</v-icon>
                    </v-btn>
                </template>
<!--                <span>{{ $t('app.Photograph.Colorized') }}</span>-->
            </v-tooltip>
<!--            <v-tooltip left>-->
<!--                <template v-slot:activator="{ on: onTooltip }">-->
<!--                    <v-btn @click.stop="showContrast=true" v-on="onTooltip" icon>-->
<!--                        <v-icon>mdi-contrast-circle</v-icon>-->
<!--                    </v-btn>-->
<!--                </template>-->
<!--                &lt;!&ndash;                <span>{{ $t('app.Photograph.BrightnessContrast') }}</span>&ndash;&gt;-->
<!--            </v-tooltip>-->
        </v-speed-dial>
        <div class="selectPersonOverlayOrig" style="display:none;">
            <v-btn class="selectPersonOverlayOk" primary icon large>
                <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn class="selectPersonOverlayAbort" primary icon large>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="commentOverlayOrig commentEditable" style="display:none;">
            <div class="handle handletl"></div>
            <div class="handle handletr"></div>
            <div class="handle handlebl"></div>
            <div class="handle handlebr"></div>
            <v-btn class="commentOverlayOk" primary icon large>
                <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn class="commentOverlayAbort" primary icon large>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="tooltipBox" v-if="!pathEditMode"
             :style="'position:absolute; top:'+tooltipBoxPos.y+'px; left:'+tooltipBoxPos.x+'px'"
             v-html="this.tooltipText">
        </div>
        <!--<div class="pathEditModeTextBox" v-if="pathEditMode && textBoxPos"
             :style="'position:absolute; top:'+textBoxPos.y+'px; left:'+textBoxPos.x+'px'">
            <v-card>
            <tiptap-vuetify
                v-model="newComment.text"
                :extensions="tiptapExtensions"
                :toolbar-attributes="{ color: 'rgba(20,20,20,.5)' }"
                :placeholder="$t('app.Comment.CommentText')"
                label="Text"
                required="true"
                :card-props="{ flat: true, color: 'rgba(20,20,20,.5)' }"
                width="100%"
            ></tiptap-vuetify>
                <v-card-actions></v-card-actions>
            </v-card>
        </div>-->
        <v-card class="photographShow"
                :style="(hideGui ? ' opacity:0;transition:opacity 500ms;' : 'opacity:1;transition:opacity 500ms;')">

            <v-card-title v-if="item && getTranslation(item).name"
                          class="text-h7 photographTitle"
                          primary-title
                          style="visibility: hidden"

            >
                {{ getTranslation(item).name }}
            </v-card-title>

            <div class="photographShowInfo" v-if="!pathEditMode">
                <v-card v-if="item" :style="hideGui ? 'pointer-events: none !important;' : ''">
                    <!--<v-btn top right absolute icon class="pa-0" @click="showInfo = false" style="z-index:1">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>-->
                    <!--<v-card-title
                        class="text-h7 mr-5"
                        primary-title
                        style="hyphens: auto; hyphenate-limit-chars: auto 5; hyphenate-limit-lines: 2;"
                    >
                        {{ getTranslation(item).name }}
                    </v-card-title>-->
                    <v-card-text>
<!--                        <v-card-subtitle>-->
<!--                            <p v-if="getTranslation(item).description && (getTranslation(item).description.length<100 || descriptionShowAll)"-->
<!--                               class="description-text" v-html="getTranslation(item).description"></p>-->
<!--                            <span-->
<!--                                v-if="getTranslation(item).description && getTranslation(item).description.length>100 && !descriptionShowAll">-->
<!--                                <span class="description-text"-->
<!--                                      v-html="getTranslation(item).description.substring(0, 100).replace('<p>','').replace('</p>','') + ' '">-->
<!--                                </span>-->
<!--                                <a href="#" @click.prevent="descriptionShowAll=true">[more...]</a>-->
<!--                            </span>-->

<!--                        </v-card-subtitle>-->
<!--                        <v-card-subtitle>-->
<!--                            <div v-if="item.fromYear && item.toYear">{{ $t('app.Photograph.Period') }}:-->
<!--                                {{ item.fromYear }}<span-->
<!--                                    v-if="item.fromYear!==item.toYear"> - {{ item.toYear }}</span></div>-->
<!--                            <div v-if="item.place && item.place.name">{{ $t('app.Photograph.Place') }}:-->
<!--                                {{ item.place.name }}-->
<!--                            </div>-->
<!--                            <div v-if="item.material">{{ $t('app.Photograph.Material') }}: {{ item.material }}</div>-->

<!--                            &lt;!&ndash;<a href="#" rel="noreferrer" v-if="item" v-on:click.stop.prevent="showCollectionInfoDialog=true" x-small  v-html="attribution"></a>&ndash;&gt;-->

<!--                        </v-card-subtitle>-->
                        <v-card-actions class="px-0" v-if="isEditor || isAdmin || isOwner">
                            <v-btn x-small :to="{name: 'PhotographUpdate', params: { id: item['@id'] }}"
                            >{{ $t('app.Edit') }}
                            </v-btn>
                        </v-card-actions>

                    </v-card-text>

                </v-card>
<!--                <v-card-actions v-if="item">-->
<!--                    <v-row>-->
<!--                        <v-col xs="10" sm="10" md="8" lg="6" xl="4">-->
<!--                            <v-btn-->
<!--                                rounded-->
<!--                                outlined-->
<!--                                block-->
<!--                                style="margin-bottom:7px; pointer-events: stroke;"-->
<!--                                @click="addComment()"-->


<!--                            >-->
<!--                                <v-row>-->
<!--                                    <v-col class="text-capitalize text-left">{{ $t('app.Comment.Comment') }}...</v-col>-->
<!--                                    <v-col class="text-right">-->
<!--                                        <v-icon>-->
<!--                                            mdi-comment-plus-->
<!--                                        </v-icon>-->
<!--                                    </v-col>-->
<!--                                </v-row>-->


<!--                            </v-btn>-->
<!--                        </v-col>-->
<!--                        <v-col cols="2" xs="2" sm="2" md="4" lg="6" xl="8">-->
<!--                            <v-tooltip top style="transition-delay: 0.15s;" v-if="item.comments.length>0">-->
<!--                                <template v-if="item.comments.length>0" v-slot:activator="{ on: onTooltip }">-->
<!--                                    <v-badge-->

<!--                                        :content="item.comments.length"-->
<!--                                        :value="item.comments.length"-->
<!--                                        offset-x="16"-->
<!--                                        offset-y="16"-->
<!--                                        overlap-->
<!--                                    >-->
<!--                                        <v-btn @click.stop="showComments=true" v-on="onTooltip" icon-->
<!--                                               style="pointer-events: stroke;">-->
<!--                                            <v-icon>icon-SpeechBubble</v-icon>-->
<!--                                        </v-btn>-->
<!--                                    </v-badge>-->


<!--                                </template>-->
<!--                                <span>{{ $t('app.Photograph.ShowComments') }}</span>-->
<!--                            </v-tooltip>-->
<!--                            <v-tooltip v-else top style="transition-delay: 0.15s;">-->
<!--                                <template v-slot:activator="{ on: onTooltip }">-->
<!--                                    <v-btn @click.stop="showComments=true" v-on="onTooltip" icon-->
<!--                                           style="pointer-events: stroke;">-->
<!--                                        <v-icon>icon-SpeechBubble</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </template>-->
<!--                                <span>{{ $t('app.Photograph.ShowComments') }}</span>-->
<!--                            </v-tooltip>-->
<!--                        </v-col>-->
<!--                    </v-row>-->

<!--                </v-card-actions>-->
            </div>

            <v-btn v-if="showTooltip" left @click="showButtonsInfoMethod" icon
                   class="closeWinButton"
                   right
                   fixed
                   >
                <v-icon>mdi-help-circle</v-icon>
            </v-btn>
            <v-tooltip left v-if="item && showcloseButtons && !showMoreInfoPart">

                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click.stop="closeWin" icon
                           class="closeWinButton"
                           right
                           fixed
                           v-on="onTooltip">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-tooltip>

            <v-card-actions v-if="item && !pathEditMode"
                            class="actions-style"
                          >

<!--                <v-speed-dial-->
<!--                    v-if="item && !pathEditMode"-->
<!--                    style="margin-bottom: 20px"-->
<!--                    v-model="speedDialShow"-->
<!--                    direction="top"-->
<!--                    transition="slide-y-reverse-transition"-->
<!--                >-->
<!--                    <template v-slot:activator>-->
<!--                        <v-btn-->
<!--                            v-model="speedDialShow"-->
<!--                            icon large-->
<!--                        >-->
<!--                            <v-icon v-if="speedDialShow">-->
<!--                                mdi-close-->
<!--                            </v-icon>-->
<!--                            <v-icon v-else>-->
<!--                                mdi-dots-horizontal-->
<!--                            </v-icon>-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <v-tooltip left>-->

<!--                        <template v-slot:activator="{ on: onTooltip }">-->
<!--                            <v-btn @click.stop="toggleVoice" v-on="onTooltip" icon>-->
<!--                                <v-icon v-if="audioPlaying">mdi-voice-off</v-icon>-->
<!--                                <v-icon v-if="!audioPlaying">mdi-account-voice</v-icon>-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                        <span v-if="audioPlaying">{{ $t('app.VoiceOff') }}</span>-->
<!--                        <span v-if="!audioPlaying">{{ $t('app.VoiceOn') }}</span>-->
<!--                    </v-tooltip>-->

<!--                    <v-tooltip left>-->

<!--                        <template v-slot:activator="{ on: onTooltip }">-->
<!--                            <v-btn @click.stop="toggleTextlayer" v-on="onTooltip" icon>-->
<!--                                <v-icon v-if="showTextlayer">mdi-message-bulleted-off</v-icon>-->
<!--                                <v-icon v-if="!showTextlayer">mdi-message-bulleted</v-icon>-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                        <span v-if="!showTextlayer">{{ $t('app.Photograph.ShowTextlayer') }}</span>-->
<!--                        <span v-if="showTextlayer">{{ $t('app.Photograph.HideTextlayer') }}</span>-->
<!--                    </v-tooltip>-->

<!--&lt;!&ndash;                    <v-tooltip top>&ndash;&gt;-->
<!--&lt;!&ndash;                        <template v-slot:activator="{ on: onTooltip }">&ndash;&gt;-->
<!--&lt;!&ndash;                            <v-btn v-if="item['place']"&ndash;&gt;-->
<!--&lt;!&ndash;                                   :to="{name: 'MapShowGeo', params: { geo: item.place.geo.latitude+','+item.place.geo.longitude }}"&ndash;&gt;-->
<!--&lt;!&ndash;                                   icon large v-on="onTooltip">&ndash;&gt;-->
<!--&lt;!&ndash;                                <v-icon>icon-Marker</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                            </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;                        </template>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span>{{ $t('app.Photograph.ShowOnMap') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </v-tooltip>&ndash;&gt;-->


<!--                    <v-tooltip left>-->
<!--                        &lt;!&ndash;<v-btn @click.stop="rotateImage" icon>-->
<!--                            <v-icon>mdi-rotate-right</v-icon>-->
<!--                        </v-btn>&ndash;&gt;-->
<!--                        <template v-slot:activator="{ on: onTooltip }">-->
<!--                            <v-btn @click.stop="showContrast=true" v-on="onTooltip" icon>-->
<!--                                <v-icon>mdi-contrast-circle</v-icon>-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                        <span>{{ $t('app.Photograph.BrightnessContrast') }}</span>-->
<!--                    </v-tooltip>-->
<!--                </v-speed-dial>-->

                <!--<v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.stop="showInfo=true" v-on="onTooltip" icon large>
                            <v-icon>icon-info</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Photograph.Info') }}</span>
                </v-tooltip>-->

<!--                <v-speed-dial-->
<!--                    v-if="!speedDialShow && item && !pathEditMode"-->

<!--                    v-model="selectSpeedDialShow"-->
<!--                    direction="left"-->
<!--                    transition="slide-x-reverse-transition"-->
<!--                >-->
<!--                    <template v-slot:activator>-->
<!--                        <v-btn-->
<!--                            v-model="selectSpeedDialShow"-->
<!--                            icon large-->
<!--                        >-->
<!--                            <v-icon v-if="selectSpeedDialShow">-->
<!--                                mdi-close-->
<!--                            </v-icon>-->
<!--                            <v-icon v-else>icon-Select_empty</v-icon>-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <v-tooltip left style="transition-delay: 0.15s;">-->
<!--                        <template v-slot:activator="{ on: onTooltip }">-->
<!--                            <v-btn @click.stop="selectPerson" v-on="onTooltip" icon class="mb-2">-->
<!--                                <v-icon>icon-Select_Person</v-icon>-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                        <span>{{ $t('app.Comment.SelectPerson') }}</span>-->
<!--                    </v-tooltip>-->
<!--                    <v-tooltip left style="transition-delay: 0.15s;">-->
<!--                        <template v-slot:activator="{ on: onTooltip }">-->
<!--                            <v-btn @click.stop="selectBuilding" v-on="onTooltip" icon class="mb-2">-->
<!--                                <v-icon>icon-Select_Building</v-icon>-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                        <span>{{ $t('app.Comment.SelectBuilding') }}</span>-->
<!--                    </v-tooltip>-->
<!--                    <v-tooltip left style="transition-delay: 0.15s;">-->
<!--                        <template v-slot:activator="{ on: onTooltip }">-->
<!--                            <v-btn @click.stop="selectText" v-on="onTooltip" icon class="mb-2">-->
<!--                                <v-icon>icon-Select_X</v-icon>-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                        <span>{{ $t('app.Comment.SelectText') }}</span>-->
<!--                    </v-tooltip>-->

<!--                    <v-tooltip left style="transition-delay: 0.15s;">-->
<!--                        <template v-slot:activator="{ on: onTooltip }">-->
<!--                            <v-btn @click.stop="selectSection" v-on="onTooltip" icon class="mb-2">-->
<!--                                <v-icon>icon-Select_empty</v-icon>-->
<!--                            </v-btn>-->
<!--                        </template>-->
<!--                        <span>{{ $t('app.Comment.SelectEmpty') }}</span>-->
<!--                    </v-tooltip>-->


<!--                </v-speed-dial>-->


<!--                <v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">-->
<!--                    <template v-slot:activator="{ on: onTooltip }">-->
<!--                        <v-btn :to="{name: 'RetrosnapCreate', params: { photograph: item['@id'] }}" v-on="onTooltip"-->
<!--                               icon large class="mb-2">-->
<!--                            <v-icon>icon-RetroSnapPlus</v-icon>-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <span>{{ $t('app.Retrosnap.Create') }}</span>-->
<!--                </v-tooltip>-->
<!--                <v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">-->
<!--                    <template v-slot:activator="{ on: onTooltip }">-->
<!--                        <v-btn @click.native.stop.prevent="showGallerySelectDialog(item)" icon class="mb-2"-->
<!--                               v-on="{...onTooltip}">-->
<!--                            <v-icon>mdi-notebook-plus</v-icon>-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <span>{{ $t('app.Gallery.Add') }}...</span>-->
<!--                </v-tooltip>-->

<!--                <v-tooltip v-if="!speedDialShow && showBackside===false && item.isBlackAndWhite===true" left>-->
<!--                    <template v-slot:activator="{ on: onTooltip }">-->
<!--                        <v-btn @click.stop="toggleColorized" v-on="onTooltip" icon>-->
<!--                            <v-icon v-if="colorized">mdi-invert-colors-off</v-icon>-->
<!--                            <v-icon v-if="!colorized">mdi-invert-colors</v-icon>-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <span>{{ $t('app.Photograph.Colorized') }}</span>-->
<!--                </v-tooltip>-->



<!--                <v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">-->
<!--                    <template v-slot:activator="{ on: onTooltip }">-->
<!--                        <v-btn @click.native.stop.prevent="orderItemsDrawer=true" icon v-on="{...onTooltip}">-->
<!--                            <v-icon>mdi-printer</v-icon>-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <span>{{ $t('app.Photograph.Order') }}...</span>-->
<!--                </v-tooltip>-->





                <v-btn
                    v-if="showcloseButtons"
                    class="button-expand mt-2 mr-4"
                    :style="{ transform: `rotate(${rotation+45}deg)` }"
                    @click="rotateIcon"
                icon>
                    <img
                        src="@/assets/img/button-expand.png"
                        alt="Custom Icon"
                        class="plus-icon-size"
                    />
                </v-btn>
                <transition
                    name="slide"
                    @before-enter="beforeEnter"
                    @enter="(el, done) => enter(el, done, 0.1)"
                    @leave="(el, done) => leave(el, done, 0.1)"
                >
                    <div v-if="showTooltip" class="tooltip-box-share">
                        <ShareButton v-if=" metaUrl && showTooltip"
                                      :show="showButtonsInfo"
                                      imgClass="icon-size"
                                      :url="metaUrl"
                                      :title="metaTitle"
                                      :description="metaDescription"
                                      quote="Schau dir dieses RetroPic auf Nimmerso an"
                                      :hashtags="'nimmerso,'+metaHashtags.join(',')"
                                      :media="metaImage"
                                      twitterUser="nimmerso"
                                      :showButtonsInfoOutClick="showButtonsInfoOutClick"
                        ></ShareButton>
                    </div>

                </transition>
                <transition
                    name="slide"
                    @before-enter="beforeEnter"
                    @enter="(el, done) => enter(el, done, 0.15)"
                    @leave="(el, done) => leave(el, done, 0.15)"
                >
                    <div v-if="showTooltip" class="tooltip-box-share">
                        <v-tooltip left  v-model="showButtonsInfo" :open-on-hover=false>
                            <template v-slot:activator="{on}">
                                <v-btn :to="{name: 'RetrosnapCreate', params: { photograph: item['@id'] }}" v-on="on"
                                       icon large class="mb-2">
                                    <img
                                        src="@/assets/img/new RestroSnap set.svg"
                                        alt="Custom Icon"
                                        class="icon-size"
                                    />
                                </v-btn>
                            </template>
                            <div v-if="showButtonsInfoOutClick" class="custom-tooltip">
                                <p>{{ $t('app.Retrosnap.Create')}}</p>
                            </div>
                        </v-tooltip>
                    </div>

                </transition>
                <transition
                    name="slide"
                    @before-enter="beforeEnter"
                    @enter="(el, done) => enter(el, done, 0.2)"
                    @leave="(el, done) => leave(el, done, 0.2)"
                >
                    <div v-if="showTooltip" class="tooltip-box-share">
                            <template>
                                <v-tooltip left v-model="showButtonsInfo" :open-on-hover=false>
                                    <template v-slot:activator="{on}">
                                        <v-btn
                                                icon large
                                                @click="showSelection"
                                                v-on="on"
                                        >
                                            <img
                                                src="@/assets/img/Select_new.svg"
                                                alt="Custom Icon"
                                                class="icon-size-new"
                                            />
                                        </v-btn>
                                    </template>
                                    <div v-if="showButtonsInfoOutClick" class="custom-tooltip">
                                        <p>{{ $t('app.Selection') }}</p>
                                    </div>
                                </v-tooltip>
                            </template>
<!--                            <v-tooltip left style="transition-delay: 0.15s;" >-->
<!--                                <template v-slot:activator>-->
<!--                                    <v-btn @click.stop="selectPerson" v-on="onTooltip" icon class="mb-2">-->
<!--                                        <v-icon>icon-Select_Person</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </template>-->
<!--                                <span>{{ $t('app.Comment.SelectPerson') }}</span>-->
<!--                            </v-tooltip>-->
<!--                            <v-tooltip left style="transition-delay: 0.15s;">-->
<!--                                <template v-slot:activator="{ on: onTooltip }">-->
<!--                                    <v-btn @click.stop="selectBuilding" v-on="onTooltip" icon class="mb-2">-->
<!--                                        <v-icon>icon-Select_Building</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </template>-->
<!--                                <span>{{ $t('app.Comment.SelectBuilding') }}</span>-->
<!--                            </v-tooltip>-->
<!--                            <v-tooltip left style="transition-delay: 0.15s;">-->
<!--                                <template v-slot:activator="{ on: onTooltip }">-->
<!--                                    <v-btn @click.stop="selectText" v-on="onTooltip" icon class="mb-2">-->
<!--                                        <v-icon>icon-Select_X</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </template>-->
<!--                                <span>{{ $t('app.Comment.SelectText') }}</span>-->
<!--                            </v-tooltip>-->
<!--                            <v-tooltip left style="transition-delay: 0.15s;">-->
<!--                                <template v-slot:activator="{ on: onTooltip }">-->
<!--                                    <v-btn @click.stop="selectSection" v-on="onTooltip" icon class="mb-2">-->
<!--                                        <v-icon>icon-Select_empty</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </template>-->
<!--                                <span>{{ $t('app.Comment.SelectEmpty') }}</span>-->
<!--                            </v-tooltip>-->

                    </div>

                </transition>
                <transition
                    name="slide"
                    @before-enter="beforeEnter"
                    @enter="(el, done) => enter(el, done, 0.25)"
                    @leave="(el, done) => leave(el, done, 0.25)"
                >
                    <div v-if="showTooltip" class="tooltip-box-share">
                        <v-tooltip left v-if="showTooltip" v-model="showButtonsInfo" :open-on-hover=false>
                            <template v-slot:activator="{on}">
                                <v-btn @click.native.stop.prevent="showGallerySelectDialog(item)" icon class="mb-2"
                                       v-on="on">
                                    <img
                                        src="@/assets/img/album set.svg"
                                        alt="Custom Icon"
                                        class="icon-size"
                                    />
                                </v-btn>
                            </template>
                            <div v-if="showButtonsInfoOutClick" class="custom-tooltip">
                                <p>{{ $t('app.Gallery.Add') }}</p>
                            </div>
                        </v-tooltip>
                    </div>

                </transition>
                <transition
                    name="slide"
                    @before-enter="beforeEnter"
                    @enter="(el, done) => enter(el, done, 0.3)"
                    @leave="(el, done) => leave(el, done, 0.3)"
                >
                    <div v-if="showTooltip" class="tooltip-box-share">
                        <v-tooltip  left v-model="showButtonsInfo" :open-on-hover=false>
                            <template v-slot:activator="{on}">
                                <v-btn v-if="item['place']"
                                       :to="{name: 'MapShowGeo', params: { geo: item.place.geo.latitude+','+item.place.geo.longitude }}"
                                       icon large v-on="on"
                                class="ml-2">
                                    <img
                                        src="@/assets/img/marker set.svg"
                                        alt="Custom Icon"
                                        class="icon-size"
                                    />
                                </v-btn>
                            </template>
                            <div v-if="showButtonsInfoOutClick" class="custom-tooltip">
                                <p>{{ $t('app.Photograph.ShowOnMap') }}</p>
                            </div>
                        </v-tooltip>
                    </div>

                </transition>
                <transition
                    name="slide"
                    @before-enter="beforeEnter"
                    @enter="(el, done) => enter(el, done, 0.35)"
                    @leave="(el, done) => leave(el, done, 0.35)"
                >
                    <div v-if="showTooltip" class="tooltip-box-share">
                        <v-tooltip v-model="showButtonsInfo" left :open-on-hover=false>
                            <template v-slot:activator="{on}">
                                <v-btn  icon v-on="on" @click=openCommentSection>
                                    <img
                                        src="@/assets/img/comment set.svg"
                                        alt="Custom Icon"
                                        class="icon-size"
                                    />
                                </v-btn>
                            </template>
                            <div v-if="showButtonsInfoOutClick" class="custom-tooltip">
                                <p>{{ $t('app.Photograph.ShowComments') }}</p>
                            </div>
                        </v-tooltip>
                    </div>

                </transition>








            </v-card-actions>

            <!--<v-card-actions v-if="item && pathEditMode" class=""
                            style="position:fixed; bottom:20px; left:20px">
                <v-btn primary @click.stop="pathEditOk" icon large>
                    <v-icon>mdi-check-bold</v-icon>
                </v-btn>
            </v-card-actions>-->
            <v-tooltip v-if="showFlipOver" top>
                <template v-slot:activator="{ on: onTooltip }">
                    <button class="toggle-backside-button" type="button" v-on="onTooltip"
                            @click="toggleBackside">
                        <img class="toggle-backside-button-img"
                            :src="toggleBacksideButtonIcon"
                        />

                        <img class="toggle-backside-button-icon" src="/img/icons/FlipOver.svg" alt="backside"/>

                        <!--<v-icon v-if="!showBackside">mdi-arrange-send-backward</v-icon>
                        <v-icon v-if="showBackside">mdi-arrange-bring-forward</v-icon>-->
                    </button>
                </template>
            </v-tooltip>
            <div class="additionalPhotographsContainer"
                 v-if="shouldShowAdditionalPhotographs"
                 :style="(additionalPhotographsOpen ? 'width: 100vw;' : 'width: 100px;')">
                <div class="additionalPhotographsContainerScroll" v-dragscroll.x="true" @dragscrollstart="onDragStart"
                     @click.capture="onDragClick">
                    <button class="additionalPhotographsButton" type="button"
                            v-if="item && (item['additionalPhotographs'].length>0 || item['retrosnaps'].length>0)"
                            v-click-outside="closeAdditionalPhotographs"
                    >


                        <img v-for="(additionalPhotograph, index) in item['additionalPhotographs']"
                             :key="additionalPhotograph['id']"
                             class="additionalPhotographsImage"
                             @click.stop="toggleAdditionalPhotographsOpen(additionalPhotograph)"
                             :style="(additionalPhotographsOpen ? 'left: '+(20 + 80*index)+'px; transform: rotate(0)' : 'left: 20px; transform: rotate('+(Math.random()*60-30)+'deg)')"

                             :src="entrypoint+'/thumbnail/'+'icon/'+additionalPhotograph['filePath'].replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg'))"
                             alt=""/>

                    </button>
                </div>
            </div>
            <v-btn v-if="item" @click="showCollectionInfoDialog=true" x-small class="attributionLayer"
                   v-html="attribution"></v-btn>

        </v-card>
        <div
            v-if=" item && !showTooltip &&  item.originalName && !item.originalName.includes('_a')"

            id="info-part"
            class="grey-background"
            :style="dynamicStyle"
            @click="showMoreInfo"
        >

            <v-card
                class="rounded-card"
               >
                <v-card-title @click="toggleInfo" class="info-title-style d-flex justify-center align-center" v-if="getTranslation(item).name">
                    {{ getTranslation(item).name }}
                </v-card-title>
                <v-card-text  id ='vector' class="light-wave pa-0" >
                    <v-row align="center" justify="center" no-gutters>
                        <v-col
                            cols="7"
                            class="date-pic pl-5 pt-3"
                        >
                            <span v-if="item && item.dateText">{{ this.getMonthFromDate(item.dateText) }}</span> <span v-if="item && item.fromYaer">{{item.fromYear}}</span>
                           <br>
                            <span class="place-pic" v-if="item.place">
                                {{this.city}}
                                <br>
                                <span class="street-name">{{this.street}}</span>
                            </span>
                        </v-col>
                        <v-col class="d-flex justify-end pt-2 pr-3 " cols="5" >
                            <v-btn icon   class="mr-3 ml-4" @click="scrollToCommentSection">
                                <img
                                    v-if="item.comments.length>0"
                                    src="@/assets/img/Speech_Bubble_noStroke.svg"
                                    alt="Custom Icon"
                                    class="speech-bubble"
                                />

                                <p v-if="item.comments.length>1" class="comment-count">{{item.comments.length}}</p>
                                <img
                                    v-if="item.comments.length<=0"
                                    src="@/assets/img/Speech BubbleEm.svg"
                                    alt="Custom Icon"
                                    class="speech-bubble-empty"
                                />
                            </v-btn>
                            <v-btn
                                   @click="openRetroSnaps(item['retrosnaps'])"
                                   icon large class="pb-4 ml-1">
                                <img
                                    v-if="item.retrosnaps.length>0"
                                    src="@/assets/img/RetroSnap.svg"
                                    alt="Custom Icon"
                                    class="mt-4 retroSnap-icon"
                                />
                                <p v-if="item.retrosnaps.length>1" class="retrosnaps-count">{{item.retrosnaps.length}}</p>
                                <img
                                    v-if="item.retrosnaps.length<=0"
                                    src="@/assets/img/RetroSnap black.svg"
                                    alt="Custom Icon"
                                    class="mt-3 retroSnap-icon-empty"
                                />
                            </v-btn>
                            <v-btn
                                :style="{ opacity: getTranslation(item).description ? 1 : 0}"
                                icon large class="pb-6 ml-1 mr-7">
                                <img
                                    src="@/assets/img/info.svg"
                                    alt="Custom Icon"
                                    class="mt-4 retroSnap-icon"
                                />
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row  v-if="getTranslation(item).description" no-gutters class="pl-1 pr-1" align="center" justify="center">
                        <!-- Left Column with animated line -->
                        <v-col cols="5" class="line-container">
                            <div class="animated-line-left" id="left-line" ></div>
                        </v-col>

                        <!-- Center Column with the dots image -->
                        <v-col cols="2" align="center" justify="center">
                            <v-btn icon>
                                <v-img
                                    :src="require('@/assets/img/dots.png')"
                                    alt="Custom Icon"
                                    class="custom-icon-dots"
                                />
                            </v-btn>
                        </v-col>

                        <!-- Right Column with animated line -->
                        <v-col cols="5" class="line-container">
                            <div class="animated-line-right" id="right-line"></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-card-text class="pr-3 pl-3">
                            <p v-if="getTranslation(item).description"
                               class="description-text" v-html="getTranslation(item).description"></p>
                        </v-card-text>
                    </v-row>
                    <v-row no-gutters class="mt-5" v-if="metaHashtags">
                        <v-col  cols="12">
                            <div  class="title-bg-shadow">
                                <v-btn
                                    v-for="(hashtag, index) in metaHashtags" :key="index"
                                    class="meta-hashtag-chip mr-5"
                                    label
                                    style="background-color: #FFE89A;"
                                    text-color="black"
                                    outlined
                                    @click:close="removeHashtag(index)"
                                >
                                    {{ hashtag }}
                                </v-btn>
                                <div class="mt-2 mb-2" v-if="item && item.place && item.place.postalAddress.streetAddress" >
                                    <div class="streetBox" >
                                        <div class="streetBoxBorder">
                                            <div class="corner top-left"></div>
                                            <div class="corner top-right"></div>
                                            <div class="corner bottom-left"></div>
                                            <div class="corner bottom-right"></div>
                                            <div class="content">
                                                {{item.place.postalAddress.streetAddress}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row no-gutters  id="comment-section">
                        <v-col>
                            <div>
                                <div v-if="token && personItem && personItem.name" class="new-comment">
                                    <v-card class="new-comment-style" >
                                        <v-card-title>
                                            <v-avatar
                                                :size="avatarSize"
                                                style="left:-20px"
                                                class="pa-0 ma-0"
                                            ><svg width="520" height="520" version="1.1" viewBox="0 0 190.5 190.5" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:osb="http://www.openswatchbook.org/uri/2009/osb" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <metadata>
                                                    <rdf:RDF>
                                                        <cc:Work rdf:about="">
                                                            <dc:format>image/svg+xml</dc:format>
                                                            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                                                            <dc:title/>
                                                        </cc:Work>
                                                    </rdf:RDF>
                                                </metadata>
                                                <g transform="translate(0 -106.5)">
                                                    <g transform="translate(2.5034 -7.6052)">
                                                        <circle cx="92.747" cy="209.36" r="60.136" fill="#b9c7e2" style="paint-order:normal"/>
                                                        <path d="m92.747 140.54a68.812 68.812 0 0 0-68.813 68.812 68.812 68.812 0 0 0 68.813 68.813 68.812 68.812 0 0 0 68.812-68.813 68.812 68.812 0 0 0-68.812-68.812zm0 11.602a57.21 57.21 0 0 1 57.21 57.21 57.21 57.21 0 0 1-57.21 57.21 57.21 57.21 0 0 1-57.21-57.21 57.21 57.21 0 0 1 57.21-57.21z" style="paint-order:normal"/>
                                                    </g>
                                                </g>
                                                <g transform="translate(0 -106.5)">
                                                    <image x="-21.489" y="81.531" width="233.74" height="233.74" preserveAspectRatio="none" :xlink:href="entrypoint+'/avatars/'+((personItem && personItem.pictureUrl && personItem.pictureUrl.indexOf('https')===-1) ? personItem['pictureUrl'] : '0')+'.png'"/>
                                                </g>
                                            </svg>

                                            </v-avatar>
                                            <div>
                                                <h3  class="text-h5 mb-1 new-comment-name">{{personItem.name}}</h3>
                                                <v-text-field
                                                    v-model="commentText"
                                                    label="Neuen Kommentar schreiben..."
                                                    rows="1"
                                                    outlined
                                                    class="comment-text-style mb-1 custom-text-field"
                                                    @focus="showSubmitComment = true"
                                                    :disabled="submitCommentLoading"
                                                    color="black"
                                                ></v-text-field>

                                                <!-- Submit Button with Loading Indicator -->
                                                <v-btn
                                                    class="mt-1"
                                                    v-if="showSubmitComment && !submitCommentLoading"
                                                    @click="addNewComment"
                                                    color="primary"
                                                >
                                                    Comment
                                                </v-btn>

                                                <!-- Loading State on Submit Button -->
                                                <v-btn
                                                    v-if="showSubmitComment && submitCommentLoading"
                                                    class="mt-1"
                                                    color="primary"
                                                    :loading="submitCommentLoading"
                                                    disabled
                                                >
                                                    Submitting...
                                                </v-btn>
                                            </div>
                                        </v-card-title>
                                    </v-card>
                                </div>
                                <div v-else  class="new-comment">
                                    <v-card class="new-comment-style" >
                                        <v-card-title>
                                            <v-avatar
                                                :size="avatarSize"
                                                style="left:-20px"
                                                class="pa-0 ma-0"
                                            ><svg width="520" height="520" version="1.1" viewBox="0 0 190.5 190.5" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:osb="http://www.openswatchbook.org/uri/2009/osb" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <metadata>
                                                    <rdf:RDF>
                                                        <cc:Work rdf:about="">
                                                            <dc:format>image/svg+xml</dc:format>
                                                            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                                                            <dc:title/>
                                                        </cc:Work>
                                                    </rdf:RDF>
                                                </metadata>
                                                <g transform="translate(0 -106.5)">
                                                    <g transform="translate(2.5034 -7.6052)">
                                                        <circle cx="92.747" cy="209.36" r="60.136" fill="#b9c7e2" style="paint-order:normal"/>
                                                        <path d="m92.747 140.54a68.812 68.812 0 0 0-68.813 68.812 68.812 68.812 0 0 0 68.813 68.813 68.812 68.812 0 0 0 68.812-68.813 68.812 68.812 0 0 0-68.812-68.812zm0 11.602a57.21 57.21 0 0 1 57.21 57.21 57.21 57.21 0 0 1-57.21 57.21 57.21 57.21 0 0 1-57.21-57.21 57.21 57.21 0 0 1 57.21-57.21z" style="paint-order:normal"/>
                                                    </g>
                                                </g>
                                                <g transform="translate(0 -106.5)">
                                                    <image x="-21.489" y="81.531" width="233.74" height="233.74" preserveAspectRatio="none" :xlink:href="entrypoint+'/avatars/'+((token && personItem && personItem.pictureUrl && personItem.pictureUrl.indexOf('https')===-1) ? personItem['pictureUrl'] : '0')+'.png'"/>
                                                </g>
                                            </svg>

                                            </v-avatar>
                                            <div>
                                                <v-text-field
                                                    @click="openLoginPopUp"
                                                    label="Neuen Kommentar schreiben..."
                                                    rows="1"
                                                    outlined
                                                    @focus="openLoginPopUp"
                                                    class="comment-text-style mb-1 custom-text-field"
                                                    color="black"
                                                ></v-text-field>

                                                <!-- Submit Button with Loading Indicator -->
                                                <v-btn
                                                    class="mt-1"
                                                    v-if="showSubmitComment && !submitCommentLoading"
                                                    @click="addNewComment"
                                                    color="primary"
                                                >
                                                    Comment
                                                </v-btn>

                                                <!-- Loading State on Submit Button -->
                                                <v-btn
                                                    v-if="showSubmitComment && submitCommentLoading"
                                                    class="mt-1"
                                                    color="primary"
                                                    :loading="submitCommentLoading"
                                                    disabled
                                                >
                                                    Submitting...
                                                </v-btn>
                                            </div>
                                        </v-card-title>
                                    </v-card>
                                </div>
                                <div
                                    class="old-comment"
                                    v-for="(commentItem, index) in sortedComments"
                                    :key="index"
                                >
                                    <v-card class="old-comment-style" >
                                        <v-card-title>
                                            <v-avatar class="pa-2 ma-0" :size="avatarSize"><svg width="720" height="720" version="1.1" viewBox="0 0 200.5 200.5" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:osb="http://www.openswatchbook.org/uri/2009/osb" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <metadata>
                                                    <rdf:RDF>
                                                        <cc:Work rdf:about="">
                                                            <dc:format>image/svg+xml</dc:format>
                                                            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                                                            <dc:title/>
                                                        </cc:Work>
                                                    </rdf:RDF>
                                                </metadata>
                                                <g transform="translate(0 -106.5)">
                                                    <g transform="translate(2.5034 -7.6052)">
                                                        <circle cx="92.747" cy="209.36" r="60.136" fill="#b9c7e2" style="paint-order:normal"/>
                                                        <path d="m92.747 140.54a68.812 68.812 0 0 0-68.813 68.812 68.812 68.812 0 0 0 68.813 68.813 68.812 68.812 0 0 0 68.812-68.813 68.812 68.812 0 0 0-68.812-68.812zm0 11.602a57.21 57.21 0 0 1 57.21 57.21 57.21 57.21 0 0 1-57.21 57.21 57.21 57.21 0 0 1-57.21-57.21 57.21 57.21 0 0 1 57.21-57.21z" style="paint-order:normal"/>
                                                    </g>
                                                </g>
                                                <g transform="translate(0 -106.5)">
                                                    <image x="-21.489" y="81.531" width="233.74" height="233.74" preserveAspectRatio="none" :xlink:href="entrypoint+'/avatars/'+((commentItem && commentItem.author.pictureUrl && commentItem.author.pictureUrl.indexOf('https')===-1) ? commentItem.author.pictureUrl : '0')+'.png'"/>
                                                </g>
                                            </svg></v-avatar>
                                            <div>
                                                <div style="display: flex; align-items: center;">
                                                    <!-- Author name div -->
                                                    <div class="text-h5 mb-0 new-comment-name" style="margin-right: 10px;">
                                                        {{ commentItem.author.name }}
                                                    </div>

                                                    <!-- Date div -->
                                                    <div class="text-caption">
                                                        {{ moment(Date.parse(commentItem['dateCreated'])).fromNow() }}
                                                    </div>
                                                </div>
                                                <v-textarea
                                                    v-html="commentItem.text"
                                                    rows="1"
                                                    color="black"
                                                    disabled
                                                    no-resize
                                                    auto-grow
                                                    class="comment-text-style-old mb-1 pa-2"
                                                ></v-textarea>
                                            </div>
                                        </v-card-title>
                                    </v-card>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </div>

        <v-bottom-sheet v-if="item" v-model="showContrast" inset hide-overlay>
            <v-card class="pa-4">
                <v-btn style="position:absolute; top:0; right:0" icon @click="showContrast = !showContrast">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-row class="pt-8">
                    <v-col md="2" class="pa-0">{{ $t('app.Photograph.Brightness') }}</v-col>
                    <v-col md="10" class="pa-0">
                        <v-slider
                            v-model="brightness"
                            dense
                            append-icon="mdi-plus"
                            prepend-icon="mdi-minus"
                            thumb-label
                            :thumb-size="20"
                            :min="-100"
                            :max="100"
                            @click:append="brightness+=10"
                            @click:prepend="brightness-=10"
                        ></v-slider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2" class="pa-0">{{ $t('app.Photograph.Contrast') }}</v-col>
                    <v-col md="10" class="pa-0">
                        <v-slider
                            v-model="contrast"
                            dense
                            append-icon="mdi-plus"
                            prepend-icon="mdi-minus"
                            thumb-label
                            :thumb-size="20"
                            :min="-100"
                            :max="100"
                            @click:append="contrast+=10"
                            @click:prepend="contrast-=10"
                        ></v-slider>
                    </v-col>
                </v-row>

            </v-card>


        </v-bottom-sheet>

<!--        <v-bottom-sheet content-class="photographShowComments" inset hide-overlay-->
<!--                        v-model="showComments"-->
<!--                        v-if="showComments"-->
<!--                        scrollable-->
<!--                        class="noBackground"-->
<!--        >-->
<!--            <v-card v-if="item" class="bgBlur">-->
<!--                <v-btn top right absolute icon class="pa-0" @click="showComments = false" style="z-index:1">-->
<!--                    <v-icon>mdi-close</v-icon>-->
<!--                </v-btn>-->
<!--                <v-card-title>{{ commentItems.length }} {{ $t('app.Comments') }}</v-card-title>-->
<!--                <v-card-text class="py-4" v-if="commentItems.length==0">Keine Kommentare vorhanden</v-card-text>-->

<!--                <v-card-text class="" ref="commentsContainer">-->

<!--                    <comment-item-->
<!--                        v-for="commentItem in commentItems"-->
<!--                        hover-->
<!--                        elevation="2"-->
<!--                        class="my-2 py-1"-->
<!--                        :color="(activeComment===commentItem.id ? 'grey lighten-4' : 'grey lighten-5')"-->
<!--                        :id="'comment_'+commentItem.id"-->
<!--                        :ref="'comment_'+commentItem.id"-->
<!--                        :categories="categories"-->
<!--                        :comment-item="commentItem"-->
<!--                        :comment-click="commentClick"-->
<!--                        :active-comment="activeComment"-->
<!--                        :edit-comment="editComment"-->
<!--                        :delete-comment="deleteComment"-->
<!--                        :upvote-comment="upvoteComment"-->
<!--                        :downvote-comment="downvoteComment"-->
<!--                        :add-comment-to-gallery="addCommentToGallery"-->
<!--                        :report-inappropriate-comment="reportInappropriateComment"-->
<!--                        :imagegalleries="imagegalleries"-->
<!--                        :key="commentItem.id">-->

<!--                    </comment-item>-->

<!--                </v-card-text>-->
<!--                <v-card-actions v-if="item">-->
<!--                    <v-btn-->
<!--                        rounded-->
<!--                        outlined-->
<!--                        block-->
<!--                        style="margin-bottom:7px; pointer-events: stroke;"-->
<!--                        @click="addComment()"-->
<!--                    >-->
<!--                        <v-row>-->
<!--                            <v-col class="text-capitalize text-left">{{ $t('app.Comment.Comment') }}...</v-col>-->
<!--                            <v-col class="text-right">-->
<!--                                <v-icon>-->
<!--                                    mdi-comment-plus-->
<!--                                </v-icon>-->
<!--                            </v-col>-->
<!--                        </v-row>-->


<!--                    </v-btn>-->

<!--                </v-card-actions>-->
<!--            </v-card>-->
<!--        </v-bottom-sheet>-->

        <v-bottom-sheet
            inset
            hide-overlay
            scrollable
            v-if="showCommentDialog" v-model="showCommentDialog"
        >
            <comment-dialog
                :do-comment-dialog-close="doCommentDialogClose"
                :edit-rectangle="editRectangle"
                :new-comment="newComment"
                :on-send-comment-form="onSendCommentForm"
                :update-comment-field="updateCommentField"
                :submit-comment="submitComment"
            >
            </comment-dialog>
        </v-bottom-sheet>
        <v-dialog
            v-if="showSelectPerson" v-model="showSelectPerson"
            class="custom-dialog"
            max-width="400"
        >
        <v-card>
            <v-card-text>
                <v-autocomplete
                    ref="autocomplete"
                    v-model="newMention"
                    :items="filteredMentions"
                    label="Enter name"
                    outlined
                    item-text="name"
                    :search-input.sync="search"
                    allow-overflow
                    return-object
                    hide-no-data
                >
                    <!-- Custom slot for displaying name and image -->
                    <template v-slot:item="{ item }">
                        <v-row align="center" no-gutters>
                            <v-col class="pa-0" cols="auto">
                                <v-img
                                    :src="entrypoint + '/image_galleries/' + item.imagePath + '/big.jpeg'"
                                    max-width="32"
                                    max-height="32"
                                    contain
                                />
                            </v-col>
                            <v-col class="pa-1" cols>
                                <span>{{ item.name }}</span>
                            </v-col>
                        </v-row>
                    </template>
                </v-autocomplete>

                <!-- Save button -->
                <v-btn @click="saveInput" >Save</v-btn>

            </v-card-text>
        </v-card>
        </v-dialog>
        <v-dialog
            v-model="showFaceDialog"
            max-width="390"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('app.Photograph.FaceKnown') }}
                </v-card-title>
                <v-card-text>{{ $t('app.Photograph.FaceKnownText') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="editFace"
                    >
                        {{ $t('app.Photograph.FaceInsertData') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="showCollectionInfoDialog"
            v-if="item"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ item.collection.headline }}
                    <v-spacer></v-spacer>
                    <v-btn top center absolute icon @click="showCollectionInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-subtitle class="pt-6" v-if="item.collection.about">{{ item.collection.about }}</v-card-subtitle>
                <v-card-text v-if="item.collection.text" v-html="item.collection.text"></v-card-text>
                <v-card-text>
                    <v-btn small @click.stop.prevent="showPhotosFromCollection">{{ item.collection.photographsCount }}
                        RetroPics
                    </v-btn>
                </v-card-text>

                <v-card-text v-if="this.item.collection.collectionCopyrightUrl"><a
                    :href="this.item.collection.collectionCopyrightUrl"
                    target="_blank">{{ this.item.collection.collectionCopyrightUrl }}</a></v-card-text>
                <v-card-text v-if="this.item.collection.licenseName">
                    <a v-if="this.item.collection.licenseUrl" :href="this.item.collection.licenseUrl" rel="license"
                       target="_blank">{{ this.item.collection.licenseName }}</a>
                    <span v-else>{{ this.item.collection.licenseName }}</span>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="gallerySelectDialog"  @click:outside="handleClickOutside" max-width="400">
            <v-card>
                <v-card-title>{{ $t('app.Gallery.Add') }}</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        value=""
                        :label="$t('app.Gallery.Select')"
                        :items="imagegalleries"
                        item-value="@id"
                        item-text="headline"
                        v-model="selectedGallery"
                        dense
                        filled
                        :placeholder="$t('app.Gallery.Select')"
                    >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar
                                    tile
                                    size="64"
                                >
                                    <v-img
                                        :src="entrypoint+'/image_galleries/'+data.item['thumbnail']+'/icon'"

                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.headline"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :to="{ name: 'ImageGalleryCreate' }"
                        color="secondary"
                        plain
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('app.Gallery.Create') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        plain
                        text
                        @click="selectGalery"
                    >
                        {{ $t('app.Gallery.AddShort') }}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-dialog
            v-if="showCommentLoginDialog"
            v-model="showCommentLoginDialog"
            transition="dialog-bottom-transition"
            max-width="600"
            class="comment-login-dialog"
        >
            <v-card class="text-center">
                <v-toolbar
                >{{ $t('app.Comment.PleaseLogIn') }}
                </v-toolbar>
                <v-card-title class="text-h5">

                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="showCommentLoginDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>{{ $t('app.Comment.LoginText') }}<br/>
                    {{ $t('app.Comment.LoginText2') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="goToStart">{{ $t('app.Comment.LoginRegister') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createAlbum" max-width="500px" v-show="createAlbum">
            <v-card>
                <v-card-title>
                    <span class="text-h5">More Information</span>
                </v-card-title>

                <v-card-text>

                    <v-form v-model="formDescriptionValid">
                        <!-- Name field -->
                        <v-textarea
                            v-model="newAlbumDescription"
                            label="Description"
                            autocomplete="description"
                            outlined
                            auto-grow
                            rows="4"
                            :rules="[v => !!v || 'Description is required']"
                            required
                        ></v-textarea>

                        <!-- Mother field -->
                        <Mention
                            :inputType="'mother'"
                            :filterItems="filterMother"
                            :entrypoint="entrypoint"
                            v-model="mother"
                            label="Enter mother name"
                        />



                        <!-- Father field -->
                        <Mention
                            :inputType="'father'"
                            :filterItems="filterFather"
                            :entrypoint="entrypoint"
                            v-model="father"
                            label="Enter father name"
                        />
                    </v-form>

                </v-card-text>

                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="createAlbum = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text :disabled="!formDescriptionValid" @click="saveMentionMoreData">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-navigation-drawer
            v-model="orderItemsDrawer"
            fixed
            right
            clipped
            :width="$vuetify.breakpoint.smAndUp ? '320px' : '100%'"
            style="z-index:10002;"
            class="fill-height orderItemsDrawer"

        >
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="mt-3">Was möchtest du drucken?</v-list-item-title>
                        <v-btn top right absolute icon @click="orderItemsDrawer = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense style="overflow-y:auto; flex-grow: 5" fluid>
                <v-list-item v-for="(product, index) in products" :key="index">
                    <v-expand-transition>
                        <product :value="product" :handle-product-config="onProductSelect"></product>
                    </v-expand-transition>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>

        <v-dialog
            id="cropper-modal"
            ref="cropper-modal"
            v-model="cropperModal"
            style="z-index:10003;"
            :fullscreen="isMobile"
        >
            <v-card class="text-center">
                <v-toolbar
                >Select Person
                </v-toolbar>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="changeCropper" class="tall-button">OK</v-btn>
                </v-card-actions>
                <v-card-title class="text-h5">

                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="cropperModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>


                    <cropper v-if="item && item.filePath"
                             ref="cropper"
                             class="cropper"
                             :src="entrypoint+'/thumbnail/display/'+item.filePath"
                             :stencil-props="{
                      aspectRatio: cropperRatio
                    }"
                             style="width:100%; min-height:300px"
                             :resizeImage="false"
                             :default-size="defaultSize"
                             @change="calcDpi"
                             @

                    ></cropper>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>

treet-name{
    font-size: 20px;
}
.custom-plus-icon::before {
    content: '+';
    font-size: 40px;
    color: black; /* This colors the plus sign */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Ensures the plus sign appears above the circle */
}
.speech-bubble{
    width:60px;
    height: 60px;
    filter:drop-shadow( 0.55rem 0.55rem 5px gray)
}
.speech-bubble-empty{
    width:45px;
    height: 45px;
}
.retroSnap-icon{
    width:50px;
    height: 50px;
    filter:drop-shadow( 0.55rem 0.55rem 5px gray)
}
.retroSnap-icon-empty{
    width:35px;
    height: 35px;
}
.photographTitle {
    text-shadow: 0px 2px 3px #000000;
    top: max(30px, env(safe-area-inset-top, 30px));
    hyphens: auto;
    hyphenate-limit-chars: auto;
    hyphenate-limit-lines: 2;
    position: fixed;
    padding-top: 0;
    font-weight: bold;
    max-width: calc(100vw - 60px);
}
.custom-icon-dots {
    max-width: 320px;
    max-height: 24px;
    width: 100%;
    height: auto;
}


@media (min-width: 1024px) {
    .custom-icon-dots{
        max-width: 540px;
        max-height: 100px;
    }
}
.close-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 8px;
    margin-right: 16px;
}

::v-deep .v-text-field .v-input__control input {
    color: black !important;
}
::v-deep .v-text-field .v-label {
    color: rgba(40, 44, 57, 1) !important;
    font-family: Poppins,serif;
    font-weight: 300;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    padding-top: 1px;
    margin-bottom: 20px;
}
::v-deep .v-text-field__slot{
    bottom: 5px;
}
.v-application .primary--text {
    color: black !important;
    caret-color: black !important;
}

.street-filter{
    background-color: blue !important;
    color: white !important;
}
.meta-hashtag-chip {
    font-size: 16px;
    font-weight: 500;
    color: black;
    padding: 8px 16px;
}
.line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.animated-line-right {
    width: 100%;
    height: 2px; /* Line thickness */
    background-color: #000; /* Line color */
    transform: translateX(100px);
    transition: transform 0.5s ease-out;
}
.animated-line-left{
    width: 100%;
    height: 2px; /* Line thickness */
    background-color: #000; /* Line color */
    transform: translateX(-100px);
    transition: transform 0.5s ease-out;
}


/* Apply expand-x transition */
.expand-line-enter-active,
.expand-line-leave-active {
    transition: width 1s ease-out;
}

/* Initial state: no width */
.expand-line-enter, .expand-line-leave-to {
    width: 0;
}

/* Final state: full width */
.expand-line-enter-to, .expand-line-leave {
    width: 100%;
}

.v-tooltip__content {
    font-size: 50px !important;
    opacity: 1 !important;
    background-color: transparent !important;
}
.title-bg {
    background-color: #282C39 !important;
    height: 50px !important;
    width: 100%;
}
.place-pic{
    font-family: "Josefin Sans",serif ;
    font-weight: bold;
    font-size: 28px;
    line-height: 24px;
    color: #282C39;
    margin-top: 5px;
    font-style: normal;
}
.yin-yang {
    height: 540px;
    background:
        radial-gradient(75% 35% at 68% 82%, #A5A09C 0%, #D0C2AF 0% 99%, transparent 101%),
        radial-gradient(75% 33% at 35% 16%, #D0C2AF 0%, #A5A09C 0% 99%, transparent 101%),
        conic-gradient(#A5A09C 50%, #D0C2AF 0)
}
.new-comment{
    background-color: #B6AA99;
}
.fit-to-screen{
    position: fixed;
    right: 40vw;
    top: 25px;
    z-index: 1000;
    transition: opacity 500ms;
}
@media (min-width: 1200px) {
    .fit-to-screen {
        right: 50vw;
    }
}
.info-title-style-more{
    font-family: Roboto,serif;
    color: white;
    position: relative;
    font-weight: 500;
    font-size: 33px!important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    background-color: #282C38;
    text-overflow: ellipsis;
    z-index: 100;
    line-height: 1.5em;
    text-align: center;
    max-height: calc(1.2em * 5);
}
.info-title-style{
    font-family: Roboto,serif;
    color: white;
    position: relative;
    font-weight: 400;
    font-size: 20px!important;
    background-color: rgba(40, 44, 57, 1);
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    line-height: 1em;
    width: 90%;
    max-height: calc(1.0em * 5);
}
.custom-dialog .v-dialog__content {
    max-height: unset !important;  /* Remove any max-height set by Vuetify */
    overflow: hidden !important;   /* Prevent scrolling inside the dialog */
    padding: 0 !important;                  /* Optional: remove padding if needed */

}
.light-wave{
    border-radius: 0 80px 0 0;
    transition: border-radius 2s ease-in-out;
    background-color: #A5A09C;
    padding-top: 20px;
}
.light-wave-more-info{
    background-color: #A5A09C;
    padding-top: 20px;
    overflow-x: hidden
}
.light-wave-color{
    height: 50px;
    background-color: #A5A09C !important;
}
</style>
<!--suppress CssUnusedSymbol -->
<style>
* button {
    outline: none;
}
.actions-style{
    z-index:7;
    position:fixed;
    bottom:7vh;
    right:-70px;
    gap:2vh;
    flex-direction: column-reverse;

}
.title-bg-shadow{
    background-color: #282C39 !important;
    box-shadow: 0px 3px 12px 6px #00000080;
    min-height: 100px !important;
    width: 100%;
    padding: 20px;
}
.photographShowInfo {
    max-width: fit-content;
    box-shadow: none;
    max-height: 340px;
    pointer-events: none;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;

}
.tall-button{
    padding: 50px 24px !important;
}
.selected-dialog{
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}
.button-expand{
    color: #FFE89A !important;
    font-size: 38px !important;
    cursor: pointer !important;
    right: 60px !important;
    transition: transform 0.3s
}
.date-pic{
    font-family: "Josefin Sans",serif;
    font-weight: 700;
    font-style: italic;
    font-size: 30px;
    line-height: 24px;
    color: black;
}
.icon-size{
    height: 60px;
    width: 60px;
}
.plus-icon-size{
    height:37px ;
    width: 37px;
}
.icon-size-new{
    height: 40px;
    width: 40px;
    position: relative;
    right: 5px;
}
.photographShowInfo .btn, .photographShowInfo a {
    pointer-events: auto;
}
.tooltip-box-share{
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: column-reverse;
}

@keyframes slideDown {
  from{right: -60px}
    to{right: 60px}
}

.new-comment-name{
    color: #2A5BBD;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    font-family: Josefin Sans, sans-serif;
}
.photographShowInfo .v-card__subtitle {
    padding: 0;
}

.photographShowInfo > .v-card {
    background-color: initial !important;
    max-height: 240px;
    overflow-y: auto;
    mask-image: linear-gradient(transparent, black 5%, black 95%, transparent);
    mask-mode: alpha;
    padding-top: 20px;
    padding-right: 25px;
    pointer-events: stroke;

}

.attributionLayer {
    bottom: 0;
    right: 0;
    position: fixed;
    height: 30px;
    padding: 5px 20px 5px 5px;
    text-shadow: 1px 1px 2px black;
    background-color: rgba(50, 50, 50, .2) !important;
    text-transform: none;
}
.fade-out {
    transition: opacity 1s ease-out;  /* Adjust the time as needed */
}

.fade-out-leaving {
    opacity: 0;
}
.zoomImageDialog i.v-icon {
    text-shadow: 0 1px 2px black;
    /*-webkit-text-fill-color: white;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: black;*/
}

.closeWinButton {
    z-index: 10001;
    top: max(30px, env(safe-area-inset-top, 30px));
}
.cogWinButton {
    z-index: 10001;
    position: relative;
    bottom: 94vh;
    left: 70vw;
}

.commentHidden {
    display: none !important;
}

.toggle-backside-button {
    position: fixed;
    top: 20%;
    left: 20px;
}
.toggle-backside-button-img {
    box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
}


.toggle-backside-button-icon {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 25%;
    right: -15px;
    -webkit-text-fill-color: black;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: white;
}

.additionalPhotographsButton {
    left: 10px;
    top: 30%;

}
.tools-layer{
    position: absolute;
    top: 20%;
    left: 140px;
    display: flex;
    flex-direction: column;
    gap: 40px
}
.comment-count{
    padding: 0px 2px 0px 2px;
    background-color:cornflowerblue;
    position: absolute;
    top: 5px;
    right: 0px;
    border-radius: 5px;
}
.retrosnaps-count{
    background-color:transparent;
    position: absolute;
    top:12px;
    font-weight: bold;
    right: 0px;
    border-radius: 5px;
}
.new-comment-style{
    background: linear-gradient(180deg, #6F7367 0%, #B6AA99 7.02%);
    border-radius: unset!important;
    color:black;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
.old-comment-style{
    background:  rgba(165, 160, 156, 1) !important;
    border-radius: unset!important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
.mention-style{
    width: 250px !important;
}
.additionalPhotographsContainer {
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    width: 150px;
    height: 120px;
    display: block;
    /*direction: rtl;*/
    left: 0;
    position: fixed;
    top: 30%;
    right: 0;
    transition: all 0.5s ease-out;
    scroll-behavior: smooth;
}

.additionalPhotographsContainerScroll {
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    height: 140px;
}


.additionalPhotographsImage {
    box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
    position: absolute;
    transition: all 0.5s ease-out;
    display: inline-block;
    margin-left: 15px;
}

.additionalPhotographsContainerScroll div {
    position: absolute;
    transition: all 0.5s ease-out;
    display: inline-block;
}

.commentOverlay {
    outline: 12px auto #0A7EbE;
    background-color: rgba(0, 0, 0, .4);
    position: absolute;

}
.selectPersonOverlay{
    border: 1px solid white;
    width: 250px;
    height: 250px;
}

.commentOverlay > .textDiv {
    /*color: rgba(255, 255, 255, .8);
    text-shadow: 0 2px 1px rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    overflow: hidden;*/
    display: none;
    justify-content: center;
    align-items: center;
}

.commentOverlay:hover, .commentOverlay:focus {
    background-color: rgba(0, 0, 0, .1);
}

.commentOverlay .handle {
    outline: 12px auto #0A7EbE;
    width: 8px;
    height: 8px;
    background-color: white;
    position: absolute;
    display: none;
}
.comment-text-style{
    width: 240px !important;
    height: 55px;
    background-color: #DED8FE;
    color: rgba(40, 44, 57, 1) !important;
    border-radius: 12px;
    box-shadow: inset 0 8px 14px rgb(0 0 0 / 50%)
}
.comment-text-style-old{
    width: 240px !important;
    background-color:rgba(217, 217, 217, 1);
    color: rgba(40, 44, 57, 1) !important;
    border-radius: 12px;
}
.comment-text{
    height: 50px;
    width: 100%;
    min-width: 230px;
    padding: 10px;
    background-color: rgba(182, 170, 153, 1);
}
.commentOverlay .commentOverlayOk, .commentOverlay .commentOverlayAbort {
    display: none
}

.commentEditable .handle {
    display: block;
}

.commentEditable .commentOverlayOk, .commentEditable .commentOverlayAbort {
    display: block;
}
.name-selected-item{
    width: 200px;
    height: 50px;
    display: flex;
    padding-left: 10px;
    justify-content: left;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    text-transform: capitalize;
}

.commentOverlay .handletl {
    top: -4px;
    left: -4px;
    cursor: nw-resize;
}
.layer-selection{
    top:0;
    width:100%;
    content:'';
    display:block;
    position:absolute;
    left:0;
    height:100%;
}
.commentOverlay .handletr {
    top: -4px;
    right: -4px;
    cursor: ne-resize;
}

.commentOverlay .handlebl {
    bottom: -4px;
    left: -4px;
    cursor: sw-resize;
}
.close-selection-layer{
    position: absolute;
    bottom: 0;
    cursor: pointer;
    right: 10px;
}
.commentOverlay .handlebr {
    bottom: -4px;
    right: -4px;
    cursor: se-resize;
}

.commentTooltipDiv {
    display: block;
    max-width: 200px;
    text-wrap: normal;
    word-wrap: break-word;

}

.commentTooltipDiv p {
    max-width: 200px;
    word-wrap: break-word;
}

.zoomImageDialog .leaflet-tooltip p {
    white-space: normal;
    min-width: 250px;
    max-width: 300px;
}

.commentsContainerText p {
    margin-bottom: 0;
}

.leaflet-control-attribution {
    color: #daa081 !important;
}

.tooltipBox {
    background-color: rgb(100, 100, 100, .5);
    text-shadow: 0 2px 1px rgba(0, 0, 0, .5);
    padding: 0 10px 0 10px;
    max-width: 250px;
}

.tooltipBox p {
    margin: 8px 0 8px 0;
}

/*

.retroSnapThumbnail {

    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.retroSnapThumbnail:after {
    color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: inline-block;
    left: 0;
    opacity: 0;
    width:24px;
    height:24px;
    pointer-events: none;
    position: absolute;
    top: 0;
    content: "\e905";
}*/


.retroSnapThumbnailContainer {
    /*max-width: 64px;
    max-height: 64px;*/
    display: flex;
    scroll-behavior: smooth;
}

.retroSnapThumbnailContainer img {
   /* margin-left: 15px; */
}

.retroSnapThumbnailContainer::after {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 30px;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(255, 255, 255, 0.5);
    content: "\e906";
    position: absolute;
    top: 0;
    right: 0;
}

/*
.retroSnapThumbnail::before {
    color: rgba(255, 255, 255, 0.5);
    content: "\e905";
}

.retroSnapThumbnail {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
*/

.photographShowInfo .description-text p {
    margin-bottom: 0 !important;
}
.description-text{
    padding-left: 20px;
    padding-right: 20px;
}
.description-text p{
    margin: unset;
}
.cropper{
    min-height:300px;
    min-width:300px;
}
.grey-background {
    position: absolute;
    cursor: pointer;
    animation: moveToBottom 1s forwards;
    width: 900px;
    background-size: contain;
    background-image: url("../../assets/img/dark panel auto layout horizontal.png");
    overflow: hidden;
}
@media (max-width: 910px) {
    .grey-background {
        width: 100%;
    }
    .grey-background .rounded-card {
        border-radius: 50px 50px 0% 0% !important;
    }
}
@media (max-width: 768px) {
    .grey-background {
        width: 100%;
        overflow: hidden;
    }
    .grey-background .rounded-card {
        border-radius: 30px 30px 0% 0% !important;
    }
    .icon-size{
        width: 80px;
        height: 80px;
    }
    .icon-size-new{
        width: 60px;
        height: 60px;
    }
    .actions-style{
        gap: 5vh;
        right: -65px;
    }
}
@keyframes moveToBottom {
    from {
        bottom: -10%; /* Start above the container */
    }
    to {
        bottom: 0%; /* End at the bottom of the container */
    }
}
.rounded-card{
    border-radius: 50px 50px 0% 0% !important;
    background-color: rgba(40, 44, 57, 1) !important;
}
.rounded-card-more-info{
    height: 540px;
    background-color: #282C39 !important;
}

</style>
<script>
import Mention from "@/components/photograph/Mention.vue";

require('openseadragon-filtering')
import OpenSeadragon from 'openseadragon'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import { ENTRYPOINT } from '@/config/entrypoint'
import fetch from '../../utils/fetch'
import isWebpSupported from '../../utils/isWebpSupported'
import CommentDialog from '../comment/CommentDialog'
import ShareButton from '../share/ShareButton'
import { dragscroll } from 'vue-dragscroll'
import Product from './Product'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import moment from 'moment';
/*import {
    TiptapVuetify,
    Bold,
    Italic,
    Underline,
    BulletList,
    OrderedList,
    ListItem,
    Blockquote,
    HardBreak
} from 'tiptap-vuetify'*/

export default {
    directives: {
        'dragscroll': dragscroll
    },
    components: {
        Mention,
        Product, CommentDialog, ShareButton, Cropper //, TiptapVuetify
    },
    computed: {
        ...mapState(['settings', 'user/token','user']),
        ...mapGetters({
            deleteError: 'photograph/del/error',
            error: 'photograph/show/error',
            isLoading: 'photograph/show/isLoading',
            item: 'photograph/show/retrieved',
            personItem: 'person/show/retrieved',
            settings: 'settings/items',
            token: 'user/token/token',
            token_data: 'user/token/token_data',
            commentDeletedItem: 'comment/del/deleted',
            commentError: 'comment/list/error',
            commentItems: 'comment/list/items',
            commentIsLoading: 'comment/list/isLoading',
            commentView: 'comment/list/view',
            commentCreated: 'comment/create/created',
            commentRetrieved: 'comment/update/retrieved',
            commentUpdated: 'comment/update/updated',
            categories: 'category/list/items',
            mentions: 'mention/list/items',
            products: 'product/list/items',
        }),
        dynamicStyle() {
            return {
                height: this.getTranslation(this.item).description ? '250px' : '150px',
                maxHeight: this.getTranslation(this.item).description ? '250px' : '150px',
                transition: 'max-height 0.5s, height 2s, z-index 0.3s',
            };
        },
        avatarSize() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return 110;  // Smaller size for mobile screens
            } else if (this.$vuetify.breakpoint.mdAndUp) {
                return 110;  // Larger size for wide screens
            }
            return 200;  // Default size for other screen sizes
        },
        sortedComments() {
            // Create a copy of the array and sort the copy
            return this.commentItems.slice().sort((a, b) => {
                return new Date(b.dateCreated) - new Date(a.dateCreated);  // Sort descending
            });
        },
        showFlipOver(){
            return this.item && this.item['backFilePath'] && this.toggleBacksideButtonIcon !== null && !this.dialog;
        },
        filteredMentions() {
            // Filter the mentions based on the user's input
            const mentionName = this.newMention
            if((mentionName===null || mentionName==='') &&  this.search!==null){
                var lowercasedInput = this.search.toLowerCase()
            }else if (typeof mentionName==="object"){
                 lowercasedInput = this.newMention.name.toLowerCase();
            }
            return this.mentions.filter(mention =>
                mention.name.toLowerCase().includes(lowercasedInput)
            );
        },
        filterMother() {
            // Filter the mentions based on the user's input
            const mentionName = this.mother
            if((mentionName===null || mentionName==='') &&  this.motherSearch!==null){
                var lowercasedInput = this.motherSearch.toLowerCase()
            }else if (typeof mentionName==="object"){
                lowercasedInput = this.mother.name.toLowerCase();
            }
            return this.mentions.filter(mention =>
                mention.name.toLowerCase().includes(lowercasedInput)
            );
        },
        filterFather() {
            // Filter the mentions based on the user's input
            const mentionName = this.father
            if((mentionName===null || mentionName==='') &&  this.fatherSearch!==null){
                var lowercasedInput = this.fatherSearch.toLowerCase()
            }else if (typeof mentionName==="object"){
                lowercasedInput = this.father.name.toLowerCase();
            }
            return this.mentions.filter(mention =>
                mention.name.toLowerCase().includes(lowercasedInput)
            );
        },
        shouldShowAdditionalPhotographs() {
            return this.item && (this.item['additionalPhotographs'].length > 0 || this.item['retrosnaps'].length > 0) && !this.dialog;
        },
        metaDescription() {
            return this.$t('app.MetaDescription');
        },
        attribution () {
            let attribution = typeof this.item !== 'undefined' && typeof this.item.collection !== 'undefined' ? this.item.collection.headline : ''
            if (typeof this.item !== 'undefined' && typeof this.item.collection !== 'undefined' && this.item.collection.licenseName) {
                attribution += ', '
                attribution += this.item.collection.licenseUrl ? '<a href="' + this.item.collection.licenseUrl + '" rel="license noreferrer" target="_blank">' + this.item.collection.licenseName + '</a>' : this.item.collection.licenseName
            }
            //attribution+= ' | '+'&copy; <a href="https://nimmerso.com/">nimmerso.com</a>';
            return attribution
        },
        isAdmin () {
            return (this.token_data && this.token_data.roles.indexOf('ROLE_ADMIN') !== -1)
        },
        isEditor () {
            return (this.token_data && this.token_data.roles.indexOf('ROLE_EDITOR') !== -1)
        },
        isOwner () {
            return this.personItem && this.item && this.item.collection && this.item.collection.owner && this.personItem['@id'] === this.item.collection.owner && this.item['@type'] === 'https://schema.org/Photograph'
            //return (this.token_data && this.token_data.roles.indexOf('ROLE_EDITOR')!==-1);
        }

    },
    data () {

        return {
            showMore: false,
            showButtonsInfoOutClick:false,
            city:'',
            street:'',
            placeCities: {},
            submitCommentLoading:false,
            chip:true,
            showLines:false,
            showInfoPart:true,
            inactivityTimer: null,
            zoomIsVisible: true,
            timeout: null,
            isZoomedIn:false,
            formDescriptionValid:false,
            newAlbumDescription:'',
            createAlbum:false,
            mother: '',
            father: '',
            motherSearch:'',
            fatherSearch:'',
            newMention:'',
            search:'',
            people: [
                { name: 'John Doe', avatar: 'avatar1.png' },
                { name: 'Jane Smith', avatar: 'avatar2.png' },
            ],
            newPersonName: '',
            entrypoint: ENTRYPOINT,
            showMoreInfoPart:false,
            showSubmitComment:false,
            isInfoOpen:true,
            imageDataUrl:null,
            tilesurl: process.env.VUE_APP_TILES_URL,
            rotation: 0,
            commentText:'',
            isUpdating: false,
            showSelectPerson:false,
            showTooltip: false,
            showcloseButtons:true,
            dialog:false,
            showButtonsInfo: false,
            colorized: false,
            showBackside: false,
            toggleBacksideButtonIcon: null,
            imagegalleries: [],
            showInfo: true,
            showComments: false,
            showCommentDialog: false,
            newComment: {box: null, isPrivate: false},
            box: null,
            pathEditMode: false,
            textBoxPos: {x: null, y: null},
            tooltipBoxPos: {x: null, y: null},
            tooltipText: '',
            activeComment: null,
            filteredCommentItems: [],
            webpSupported: true,
            additionalPhotographsOpen: false,
            imageRotation: 0,
            showContrast: false,
            brightness: 0,
            contrast: 0,
            showToggleBacksideTooltip: false,
            showTextlayer: false,
            metaItem: {},
            metaTitle: 'Nimmerso - am selben Ort, zu andrer Zeit',
            metaUrl: null,
            metaImage: null,
            metaHashtags: [],
            viewer: null,
            showFaceDialog: false,
            selectedFace: null,
            dragged: false,
            dragTimeout: null,
            hideGui: false,
            hideGuiTimeout: null,
            showCollectionInfoDialog: false,
            speedDialShow: false,
            selectSpeedDialShow: false,
            audioPlaying: false,
            audioObj: null,
            selectedGallery: null,
            gallerySelectDialog: false,
            selectedSlide: null,
            descriptionShowAll: false,
            isActive: false,
            showCommentLoginDialog: false,
            orderItemsDrawer: false,
            cropperModal: false,
            cropperRatio: 1,
            defaultPosition: null,
            cropperImage: {},
            isMobile: false,
            selectedProduct:null,
            productConfig: null,
            dpi: 0,
            evaluation: 'red'
        }
    },

    watch: {
        personItem: function () {
            this.imagegalleries = this.personItem.imageGalleries
        },
        isUpdating (val) {
            clearTimeout(this.timeout)

            if (val) {
                this.timeout = setTimeout(() => (this.isUpdating = false), 3000)
            }
        },
        item: function () {
            if (this.item === [] || this.item === null) return
            if (this.viewer !== null) this.viewer.destroy()
            this.metaItem = this.item
            this.metaTitle = this.getTranslation(this.metaItem).name + ' - Nimmerso'
            this.metaDescription = this.htmlAsText(this.getTranslation(this.metaItem).description + ' © ' + this.attribution)
            if (this.showBackside) {
                this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.backFilePath
            } else {
                this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.filePath
            }
            if(this.item['backFilePath']) this.toggleBacksideButtonIcon =this.entrypoint+'/thumbnail/'+'icon/'+(this.showBackside ? this.item['filePath'] : this.item['backFilePath']).replace(/\.jpeg/,(this.webpSupported ? '.webp' : '.jpeg'))
            this.speedDialShow=this.showBackside;
            this.metaUrl = this.getCompleteUrl()
            this.metaHashtags = []
            if (this.categories) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }
            if (this.item.isBlackAndWhite === false) {
                this.colorized = false
            }
            this.$nextTick(() => {
                this.initOpenSeaDragonViewer()
            })
            this.getCity(this.item.place)

        },

        categories: function () {
            this.metaHashtags = []
            if (this.item) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }
        },
        $route: function () {
            this.init()
        },
        showCommentDialog: function () {
            //window.console.log('showCommentDialog watch', this.showCommentDialog)
            if (this.showCommentDialog === false && this.pathEditMode === false) this.commentDialogClose()
        },
        commentCreated: function () {
            this.getComments('/comments?photograph=' + this.$route.params.id)
        },
        commentItems: function () {
            this.filterCommentItems()
            //window.console.log()
            if (this.viewer) this.addRects()
        },
        showBackside: function () {
            this.filterCommentItems()
            if (this.showBackside) {
                this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.backFilePath
            } else {
                this.metaImage = this.entrypoint + '/thumbnail/display/' + this.metaItem.filePath
            }
            if(this.item['backFilePath']) this.toggleBacksideButtonIcon =this.entrypoint+'/thumbnail/'+'icon/'+(this.showBackside ? this.item['filePath'] : this.item['backFilePath']).replace(/\.jpeg/,(this.webpSupported ? '.webp' : '.jpeg'))
            this.speedDialShow=this.showBackside;

            this.updateViewer()
            if (this.filteredCommentItems) {
                this.filteredCommentItems.forEach((commentItem) => {
                    if (commentItem.box !== null) {
                        this.addCommentRect(commentItem.id, commentItem.box, commentItem.text, commentItem.faceId)
                    }
                })
            }
            this.zoomToActiveCommentRect()
        },
        showTextlayer: function () {
            let commentOverlays = document.getElementsByClassName('commentOverlay')
            if (this.showTextlayer) {
                commentOverlays.forEach((commentOverlay) => {
                    commentOverlay.classList.remove('commentHidden')
                })
            } else {
                commentOverlays.forEach((commentOverlay) => {
                    commentOverlay.classList.add('commentHidden')
                })
            }

        },
        contrast: function () {
            this.setTilesFilter()
        },
        brightness: function () {
            this.setTilesFilter()
        },
        imageRotation: function () {
            this.viewer.degrees = this.imageRotation
            this.viewer.viewport.setRotation(this.imageRotation)
        },
        colorized: function () {
            this.updateViewer()
        },
        hideGui: function () {
            let commentOverlays = document.getElementsByClassName('commentOverlay')
            commentOverlays.forEach((commentOverlay) => {
                commentOverlay.style.opacity = this.hideGui ? '0' : '1'
                commentOverlay.style.transition = this.hideGui ? 'opacity 500ms' : 'opacity 500ms'
            })
            let selectPersonOverlays = document.getElementsByClassName('selectPersonOverlay')
            selectPersonOverlays.forEach((selectPersonOverlay) => {
                selectPersonOverlay.style.opacity = this.hideGui ? '0' : '1'
                selectPersonOverlay.style.transition = this.hideGui ? 'opacity 500ms' : 'opacity 500ms'
            })
            const feedbackButton = document.querySelector('.feedbackButton')
            if (feedbackButton) {
                feedbackButton.style.opacity = this.hideGui ? '0' : '1'
                feedbackButton.style.transition = 'opacity 500ms'
            }
            let photographShowInfoOverlay = document.querySelector('.photographShowInfo')
            if (photographShowInfoOverlay) {
                photographShowInfoOverlay.style.opacity = this.hideGui ? '0' : '1'
                photographShowInfoOverlay.style.transition = 'opacity 500ms'
            }
            let showMenu = document.querySelector('.v-menu__content')

            if (showMenu && this.hideGui) {
                showMenu.classList.remove('menuable__content__active');
                showMenu.style.display='none';
                this.showTooltip = false
                this.rotation = (this.rotation - 45)

            }

        },
        /*showInfo: function(){
            this.showInfo=true;
        }*/

    },

    beforeDestroy () {
        this.reset()
        this.isActive = false
        if (this.viewer) {
            this.viewer.removeHandler('zoom', this.updateZoomState);
        }
        if (typeof window !== 'undefined') window.removeEventListener('resize', this.onResize, {passive: true})
    },
    deactivated () {
        //window.console.log('deactivated');
        this.isActive = false
    },

    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                {name: 'description', content: this.metaDescription},

                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:title', content: this.metaTitle},
                {name: 'twitter:description', content: this.metaDescription},
                // image must be an absolute path
                {name: 'twitter:image', content: this.metaImage},

                {property: 'og:title', content: this.metaTitle},
                {property: 'og:site_name', content: 'Nimmerso'},
                {property: 'og:description', content: this.metaDescription},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: this.metaUrl},
                {property: 'og:image', content: this.metaImage}
            ]
        }
    },

    created () {
        this.isActive = true
        this.getCategories()
        this.getMentions()
        const categoryIds = ['/product_categories/64e76ea0a2ec3600ce54b9e3']
        this.getProducts('products?productCategories[]=' + categoryIds.join('&productCategories[]='))
        this.webpSupported = isWebpSupported()
        this.onResize()
        window.addEventListener('resize', this.onResize, {passive: true})
        this.init()

    },
    methods: {
        ...mapMutations({
            setError: 'photograph/show/PHOTOGRAPH_SHOW_SET_ERROR',
            setsearchfilters: 'searchfilters/setsearchfilters',
            setsettings: 'settings/setsettings'
        }),
        ...mapActions({
            del: 'photograph/del/del',
            reset: 'photograph/show/reset',
            retrieve: 'photograph/show/retrieve',
            updateImageGallery: 'imagegallery/update/update',
            updateRetrievedImageGallery: 'imagegallery/update/updateRetrieved',
            retrieveImageGallery: 'imagegallery/update/retrieve',
            personRetrieve: 'person/show/retrieve',
            gettokendata: 'user/token/gettokendata',
            gettoken: 'user/token/gettoken',
            createComment: 'comment/create/create',
            getComments: 'comment/list/default',
            getMentions: 'mention/list/default',
            getCategories: 'category/list/default',
            getProducts: 'product/list/default',
            saveMentionOrUpdate: 'mention/create/create'
        }),
        toggleInfo() {
            if (this.showMore) {
                this.closeMoreInfo(); // Call close function if info is currently shown
            } else {
                this.showMoreInfo(); // Call show function if info is currently hidden
            }
        },
        showButtonsInfoMethod(){
            setTimeout(() => {
                this.showButtonsInfo = !this.showButtonsInfo;
            }, 100);
          this.showButtonsInfoOutClick = true
        },
        handleClickOutside(){
            this.showButtonsInfo=false
            this.showButtonsInfoOutClick=false
        },
        async getCity(place) {

            const latlng = place.geo;

            try {
                const response = await fetch('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latlng.latitude + '&lon=' + latlng.longitude + '&zoom=16&addressdetails=1', {
                    method: 'GET',
                    headers: new Headers({'Content-Type': 'application/json'}),
                });

                if (!response.ok) {
                    throw new Error(`Geocode failed with status: ${response.status}`);
                }

                const data = await response.json();

                if (data && data.address) {
                    this.city = data.address.city_district || ''
                    this.street = data.address.road || ""
                } else {
                    window.console.error('Address not found in the response');
                    return 'Unknown';
                }
            } catch (e) {
                window.console.error('Geocode was not successful for the following reason: ' + e.message);
                return 'Error';
            }
        },
        openRetroSnaps(retrosnaps){
            if (retrosnaps.length === 0) return

            const retrosnapIds = [];

            for (const key in retrosnaps) {
                if (retrosnaps[key]['@id']) {
                    retrosnapIds.push(retrosnaps[key]['@id']);
                }
            }
            this.$router.push({name: 'RetrosnapShow', params: {id: retrosnapIds[0]}});
        },
        initOpenSeaDragonViewer(){
            let options = {
                id: 'imageviewer',
                tileSources: [],
                maxZoomLevel: 20,
                crossOriginPolicy: 'Anonymous',
                minZoomLevel: 0.3,
                showNavigator: false,
                visibilityRatio: 1.0,
                constrainDuringPan: true,
                //navigatorPosition: 'TOP_LEFT',
                showNavigationControl: false,
                homeFillsViewer: window.innerWidth < 600 && !this.showBackside,
                degrees: this.imageRotation,
                gestureSettingsTouch: {
                    pinchRotate: false
                },
                gestures: {
                    dbltap: {
                        enabled: false,  // Disable double-tap (double-click) zoom
                    }
                },
                gestureSettingsMouse: {
                    clickToZoom:false,
                },
                debugMode: false,
                immediateRender: true
            }
            this.viewer = OpenSeadragon(options)
            this.viewer.addHandler('canvas-click', (evt) => {
                if (evt.quick) {
                    //window.console.log('toggleGui',this.hideGui);
                    this.hideGui = !this.hideGui
                    if (this.hideGui){
                        this.closeMoreInfo()
                    }else{
                        const info = document.getElementById('info-part')
                        setTimeout(() => {
                            info.style.maxHeight = this.getTranslation(this.item).description ? '250px' : '150px',
                            info.style.overflow = 'hidden'
                        }, 1);
                    }
                    this.showInfoPart = !this.showInfoPart
                    this.showButtonsInfoOutClick = false
                }
            })
            // Handler for double-click (zoom in/out and fit behavior)
            this.viewer.addHandler('canvas-double-click', () => {
                // Check if the image is zoomed in
                if (!this.isZoomedIn) {
                    this.totalZoom()
                } else {
                    this.actualPixel()
                }
            });
            this.viewer.addHandler('zoom', () => {
                this.updateZoomState();
            });
            this.viewer.addHandler('canvas-key-down', (evt) => {
                //[ - / _, shift+W, shift+up arrow ] - zoom viewport out
                //[ = / +, shift+S, shift+down arrow ] - zoom viewport in
                evt.preventDefault = true // disable default keyboard controls
                evt.preventFlip = true // disable vertical panning with arrows and W or S keys
                evt.preventRotation = true // disable horizontal panning with arrows and A or D keys
                evt.originalEvent.stopPropagation()
                evt.originalEvent.preventDefault()
                if (evt.originalEvent.key == 'f') return
                if (evt.originalEvent.key == 'r') return
            })
            this.viewer.addHandler('canvas-key', (evt) => {
                //[ - / _, shift+W, shift+up arrow ] - zoom viewport out
                //[ = / +, shift+S, shift+down arrow ] - zoom viewport in
                evt.preventDefault = true // disable default keyboard controls
                evt.preventFlip = true // disable vertical panning with arrows and W or S keys
                evt.preventRotation = true // disable horizontal panning with arrows and A or D keys
                evt.originalEvent.stopPropagation()
                evt.originalEvent.preventDefault()
                if (evt.originalEvent.key == 'f') return
                if (evt.originalEvent.key == 'r') return
                if (
                    evt.originalEvent.type !== 'keydown' || (
                        evt.originalEvent.key !== '-' &&
                        evt.originalEvent.key !== '_' &&
                        !(evt.originalEvent.key === 'w' && evt.originalEvent.shiftKey === true) &&
                        !(evt.originalEvent.key === 'ArrowUp' && evt.originalEvent.shiftKey === true) &&

                        evt.originalEvent.key !== '+' &&
                        evt.originalEvent.key !== '=' &&
                        !(evt.originalEvent.key === 's' && evt.originalEvent.shiftKey === true) &&
                        !(evt.originalEvent.key === 'ArrowDown' && evt.originalEvent.shiftKey === true)
                    )
                ) {
                    return
                }
                //Zoom-In:
                if (
                    evt.originalEvent.key === '+' ||
                    evt.originalEvent.key === '=' ||
                    (evt.originalEvent.key === 's' && evt.originalEvent.shiftKey === true) ||
                    (evt.originalEvent.key === 'ArrowDown' && evt.originalEvent.shiftKey === true)
                ) {
                    this.viewer.viewport.zoomBy(2)
                    this.viewer.viewport.applyConstraints()
                }
                //Zoom-Out:
                if (
                    evt.originalEvent.key === '-' ||
                    evt.originalEvent.key === '_' ||
                    (evt.originalEvent.key === 'w' && evt.originalEvent.shiftKey === true) ||
                    (evt.originalEvent.key === 'ArrowUp' && evt.originalEvent.shiftKey === true)
                ) {
                    this.viewer.viewport.zoomBy(0.5)
                    this.viewer.viewport.applyConstraints()
                }

                evt.preventDefault = true
                //window.console.log(evt)

            })
            this.updateViewer()
            if (document.querySelector('.additionalPhotographsContainer')) {
                document.querySelector('.additionalPhotographsContainer').addEventListener('transitionend', (evt) => {
                    if (document.querySelector('.additionalPhotographsContainer') !== evt.target) return
                    evt.target.scrollLeft = -1000
                    //window.console.log(evt)
                }, false)
            }
        },
        // Remove the hashtag from the array
        removeHashtag(index) {
            this.metaHashtags.splice(index, 1);
        },
        moment: function (date) {
            return moment(date).locale('de');
        },
        scrollToCommentSection() {
            setTimeout(() => {
                const element = document.getElementById('comment-section');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 500);
        },
        openCommentSection() {
          this.rotateIcon()
            setTimeout(() => {
               this.showMoreInfo()
            }, 500);
          this.scrollToCommentSection()
        },
        async addNewComment() {
            // Start the loading state
            this.submitCommentLoading = true;

            // Only proceed if commentText is not empty
            if (this.commentText.trim() !== '') {
                this.newComment = {
                    box: null,
                    text: this.commentText,
                    isPrivate: false,
                    photograph: this.item['@id'], // assuming this is the photograph ID
                };

                try {
                    // Create the new comment (simulate API call)
                    await this.createComment(this.newComment);

                    // After creating the comment, reset the text area and button state
                    this.commentText = '';
                    this.showSubmitComment = false;

                    // Fetch the updated list of comments
                    await this.getComments('/comments?photograph=' + this.$route.params.id);
                } catch (error) {
                    window.console.error("Error during comment creation or fetching:", error);
                    alert("There was an issue with submitting the comment. Please try again.");
                } finally {
                    // End the loading state regardless of success or failure
                    this.submitCommentLoading = false;
                }
            } else {
                // If commentText is empty, end the loading and do nothing
                this.submitCommentLoading = false;
            }
        },
        showMoreInfo(){
            this.showMore = true;
            const info = document.getElementById('info-part')
            const rightVector = document.getElementById('vector')
            info.style.height = 'auto';       // Adjust height based on content
            info.style.maxHeight = '100vh';   // Limit to viewport height if the content grows too tall
            info.style.overflowY = 'hidden';
            info.style.zIndex= "100"
            rightVector.style.borderRadius="0 50px 0 0"
            this.$nextTick(() => {
                // Direct DOM manipulation to change the width of the lines
                const leftLine = document.querySelector('#left-line');
                const rightLine = document.querySelector('#right-line');
                setTimeout(() => {
                    info.style.overflowY = 'auto';
                }, 500);
                // Trigger animation after a delay (simulating when component is loaded)
                setTimeout(() => {
                    leftLine.style.transform = 'translateX(0)';
                    rightLine.style.transform = 'translateX(0)';
                }, 500);
            })
        },
        closeMoreInfo(){
            this.showMore = false;
            const info = document.getElementById('info-part')
            setTimeout(() => {
                info.style.maxHeight = '0vh';
                info.style.overflow = 'hidden'
            }, 1);

        },
        // Method to reset the zoom level to 1 (Fit-to-screen)
        resetZoom() {
            const bounds = this.viewer.world.getHomeBounds();

            const imageWidth = this.viewer.source.width;
            const imageHeight = this.viewer.source.height;

            const viewportWidth = this.viewer.container.clientWidth;
            const viewportHeight = this.viewer.container.clientHeight;

            const actualPixelZoomX = imageWidth / viewportWidth;
            const actualPixelZoomY = imageHeight / viewportHeight;

            const actualPixelZoom = Math.min(actualPixelZoomX, actualPixelZoomY);
            //zoom to actual Pixel
            this.viewer.viewport.zoomTo(actualPixelZoom);


            // Pan to the center of the image
            const imageCenter = bounds.getCenter();
            this.viewer.viewport.panTo(imageCenter);

            // Update the zoom state after zooming
            this.updateZoomState();
        },
        actualPixel(){

            const imageWidth = this.viewer.source.width;
            const imageHeight = this.viewer.source.height;

            const viewportWidth = this.viewer.container.clientWidth;
            const viewportHeight = this.viewer.container.clientHeight;
            const actualPixelZoomX = imageWidth / viewportWidth;
            const actualPixelZoomY = imageHeight / viewportHeight;

            const actualPixelZoom = Math.min(actualPixelZoomX, actualPixelZoomY);

            // Zoom to actual Pixel (1:1 zoom)
            this.viewer.viewport.zoomTo(actualPixelZoom);

        },
        totalZoom() {
            if (this.viewer) {
                const bounds = this.viewer.world.getHomeBounds();

                // Fit the image to the viewport with the contain effect
                this.viewer.viewport.fitBounds(bounds);
            }

            // Update the zoom state after fitting bounds
            this.updateZoomState();
        },

        updateZoomState() {
            // OpenSeadragon's getZoom() method returns the current zoom level
            const zoomLevel = this.viewer.viewport.getZoom();

            // We consider the viewer zoomed in if zoom level is greater than 1
            this.isZoomedIn = zoomLevel > 1;
        },
        rotateIcon() {
            if (this.showTooltip){
                this.rotation = (this.rotation + 45)
            }else{
                this.rotation = (this.rotation - 45)
            }
            this.showTooltip = !this.showTooltip;
            this.showButtonsInfo=false;
        },
        async saveInput() {
            // If newMention is an empty string, use search value
            if (this.newMention === '') {

                this.newMention = this.search;
                this.createAlbum = true;
            }else{
                try {
                    await this.saveMentionOrUpdate({
                        newMention: this.newMention,
                        cropperImage: this.cropperImage,
                    })
                } catch (e) {
                    this.error = e;
                }
                finally {
                    await this.getMentions();
                    this.showSelectPerson = false;
                    this.cropperModal = false;
                    this.newMention = '';
                }
            }

        },
        async saveMentionMoreData() {
             var category = this.categories.filter((category) => category.name == 'Person')
            try {
                await this.saveMentionOrUpdate({
                    newMention: this.newMention,
                    cropperImage: this.cropperImage,
                    newAlbumDescription:this.newAlbumDescription,
                    mother:this.mother,
                    father:this.father,
                    category:[category[0]['id']]
                })
            } catch (e) {
                this.error = e;
            }
            finally {
                await this.getMentions();
                this.showSelectPerson = false;
                this.cropperModal = false;
                this.newMention = '';
                this.createAlbum = false;
                this.mother = ''
                this.father = ''
                this.newAlbumDescription = ''
            }
        },
        showSelection(){
            this.showTooltip=false
            this.showcloseButtons = false
            this.dialog = true;
            if (this.viewer !== null) this.viewer.destroy()
        },
        closeDialog() {
            this.dialog = false;
            this.showcloseButtons = true
            this.showTooltip = false
            this.rotation = 0
            this.initOpenSeaDragonViewer()
        },
        beforeEnter(el) {
            el.style.right = '-40px';
        },
        enter(el, done,index) {

            el.offsetWidth; // Trigger reflow
            el.style.transition = 'right '+index+'s ease';
            el.style.right = '70px'; // Move to the right

        },
        leave(el, done,index) {
            el.style.transition = 'right '+index+'s ease';
            el.style.right = '-40px'; // Move off-screen again
            el.addEventListener('transitionend', done, { once: true });
        },

        setRedirectRoute(route) {
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {redirectRoute: route}))
        },
        goToStart(){
            this.showLoginDialog=false;
            this.setRedirectRoute(this.$route.fullPath);
            this.$router.push({path: '/login'})
        },
        init () {
            if (!this.isActive) return
            this.getComments('/comments?photograph=' + this.$route.params.id)
            this.retrieve(decodeURIComponent(this.$route.params.id))
            if (this.token && this.token_data && this.token_data.person_id !== null) {
                this.personRetrieve('/people/' + this.token_data.person_id)
            }

            //this.colorized = this.settings.showColorized
            this.colorized = this.$route.path.indexOf('/color') !== -1
            if (
                this.$route.name.indexOf('PhotographShowBack') > -1
            ) {
                this.showBackside = true
            } else {
                this.showBackside = false
            }

            this.showTextlayer = typeof this.settings.showTextlayer !== 'undefined' ? this.settings.showTextlayer : false

        },

        updateViewer () {
            if (!this.isActive) return
            if (!this.item) return
            //window.console.log('showBackside', this.showBackside)
            let size = this.showBackside ? this.item.backSize : this.item.size
            this.imageWidth = size[0]
            this.imageHeight = size[1]
            this.maxLevel = size[0] / 256
            this.maxLevel = Math.ceil(Math.log(Math.max(this.imageWidth, this.imageHeight) / 256))
            this.viewer.clearOverlays()
            this.viewer.addHandler('open', () => {
                this.addRects()
            })
            this.viewer.open(
                {
                    tileSize: 256,
                    minLevel: 0,
                    maxLevel: this.maxLevel,
                    width: this.imageWidth, //6400 //6515
                    height: this.imageHeight, //4096 //4116
                    getTileUrl: (level, x, y) => {
                        return this.tilesurl + (!this.showBackside ? (this.colorized ? 'colorized/' : '') + this.item['filePath'] : this.item['backFilePath']) + '/' + level + '-' + x + '-' + y + (this.webpSupported ? '.webp' : '.jpeg')
                    },
                    /*overlays: [{
                        id: 'example-overlay',
                        px: 100,
                        py: 100,
                        width: 500,
                        height: 100,
                        className: 'commentOverlay'
                    }],*/
                }
            )

            //this.viewer.setMaxZoom(this.maxLevel);

        },
        zoomToActiveCommentRect () {
            //window.console.log('zoomToActiveCommentRect', this.activeComment)
            this.$nextTick(() => {
                if (this.activeComment !== null) {
                    //window.console.log('rect_' + this.activeComment)
                    let commentOverlay = this.viewer.getOverlayById('rect_' + this.activeComment)

                    if (commentOverlay) {
                        //window.console.log('zoomToActiveCommentRect, commentOverlay: ', commentOverlay.getBounds(this.viewer.viewport))
                        this.viewer.viewport.fitBoundsWithConstraints(commentOverlay.getBounds(this.viewer.viewport), true)
                    }

                    let commentItem = this.commentItems.filter((cI) => cI.id === this.activeComment)[0]

                    this.metaTitle = this.getTranslation(this.metaItem).name + ' - Nimmerso'
                    this.metaDescription = this.htmlAsText(commentItem.text) + this.htmlAsText(this.getTranslation(this.metaItem).description + ' © ' + this.attribution)
                    this.metaImage = this.entrypoint + commentItem['@id'] + '/showThumbnail/display.jpeg'
                    //this.metaUrl = this.getCompleteUrl()

                }
            })

        },
        addCommentRect (id, box, commentText, faceId) {
            if (faceId) {
                let faceElt = document.getElementById('facerect_' + faceId)
                if (faceElt) {
                    faceElt.parentNode.removeChild(faceElt)
                }
            }

            let elt = document.querySelector('div.commentOverlayOrig').cloneNode(false)
            elt.style.display = 'block'
            elt.id = 'rect_' + id
            elt.ref = id
            //this.$refs[id]=elt;

            //elt.innerText+=commentText;
            let rect = typeof box.x == 'undefined' ? new OpenSeadragon.Rect(box[0], box[1], box[2], box[3], box[4]) : new OpenSeadragon.Rect(box.x, box.y, box.width, box.height, box.degrees)
            //window.console.log(rect)
            this.viewer.addOverlay({
                element: elt,
                location: rect,

            })
            elt.classList.remove('commentOverlayOrig')
            elt.classList.remove('commentEditable')
            elt.classList.add('commentOverlay')
            if (!this.showTextlayer) elt.classList.add('commentHidden')
            elt.innerHTML = '<div class="textDiv">' + commentText + '</div>'
            elt.onclick = (event) => {
                if (event.target.classList.contains('commentEditable')) return false
                if (event.target.id === 'rect_editboxe') return false
                this.showComments = true
                //let oldActiveComment=this.activeComment;
                this.activeComment = event.target.parentNode.id.split('_')[1]
                //if(oldActiveComment!==this.activeComment) {
                //window.console.log(event.target.parentNode, {id: this.item['@id'], comment_id: this.activeComment});
                this.$router.replace({
                    name: 'PhotographShowComment' + (this.colorized ? 'Color' : ''),
                    params: {id: this.item['@id'], comment_id: this.activeComment},
                })

            }
            new OpenSeadragon.MouseTracker({
                element: elt,
                enterHandler: (event) => {
                    // Show tooltip
                    this.tooltipText = event.originalEvent.target.innerHTML
                    this.$nextTick(() => {
                        let tooltipBox = document.querySelector('.tooltipBox')
                        //window.console.log('tooltipBox', tooltipBox)
                        let mousex = event.originalEvent.pageX + 20, //Get X coodrinates
                            mousey = event.originalEvent.pageY + 20, //Get Y coordinates
                            tipWidth = tooltipBox.offsetWidth, //Find width of tooltip
                            tipHeight = tooltipBox.offsetHeight, //Find height of tooltip

                            //Distance of element from the right edge of viewport
                            tipVisX = window.innerWidth - (mousex + tipWidth),
                            //Distance of element from the bottom of viewport
                            tipVisY = window.innerHeight - (mousey + tipHeight)

                        if (tipVisX < 20) { //If tooltip exceeds the X coordinate of viewport
                            mousex = event.originalEvent.pageX - tipWidth - 20
                        }
                        if (tipVisY < 20) { //If tooltip exceeds the Y coordinate of viewport
                            mousey = event.originalEvent.pageY - tipHeight - 20
                        }

                        //window.console.log(tipWidth, tipHeight, tipVisX, tipVisY)

                        this.tooltipBoxPos = {x: mousex, y: mousey}
                        //window.console.log('show tooltip', event, this.tooltipBoxPos);
                    })
                },
                exitHandler: () => {
                    // Hide tooltip
                    this.tooltipText = ''
                    this.tooltipBoxPos = {x: null, y: null}
                    //window.console.log('hide tooltip',event);
                }
            }).setTracking(true)

        },
        addEditRect (id, box) {

            if(id==='selectPerson'){
                let elt = document.querySelector('div.selectPersonOverlayOrig').cloneNode(true)
                elt.style.display = 'block'
                elt.id = 'rect_' + id
                elt.ref = id
                let rect =  new OpenSeadragon.Rect(box.x, box.y, box.width, box.height, box.degrees)
                this.viewer.addOverlay({
                    element: elt,
                    location: rect
                })
                elt.classList.remove('selectPersonOverlayOrig')
                elt.classList.add('selectPersonOverlay')
                this.dialog=false


                new OpenSeadragon.MouseTracker({
                    element: elt,
                    dragHandler: (e) => {
                        let overlay = this.viewer.getOverlayById(e.eventSource.element.id)
                        window.overlay = overlay
                        let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                        overlay.position.x += delta.x
                        overlay.position.y += delta.y
                        this.viewer.forceRedraw()

                    },
                })
                new OpenSeadragon.MouseTracker({
                    element: elt.querySelector('.selectPersonOverlayOk'),
                    clickHandler: (e) => {
                        e.originalEvent.preventDefault()
                        e.originalEvent.stopPropagation()
                        setTimeout(() => {
                            this.personSelectPathEditOk()
                        }, 500)
                    }
                })
                new OpenSeadragon.MouseTracker({
                    element: elt.querySelector('.selectPersonOverlayAbort'),
                    clickHandler: () => {
                        this.abortPathEdit()
                    }
                })
            }
            else {
                let elt = document.querySelector('div.commentOverlayOrig').cloneNode(true)
                elt.style.display = 'block'
                elt.id = 'rect_' + id
                elt.ref = id
                let rect = typeof box.x == 'undefined' ? new OpenSeadragon.Rect(box[0], box[1], box[2], box[3], box[4]) : new OpenSeadragon.Rect(box.x, box.y, box.width, box.height, box.degrees)

                //elt.onclick=this.handlePathClick;
                this.viewer.addOverlay({
                    element: elt,
                    location: rect
                })
                elt.classList.add('commentEditable')
                elt.classList.remove('commentOverlayOrig')
                elt.classList.add('commentOverlay')
                this.dialog = false
                this.calcTextBoxPos()

                new OpenSeadragon.MouseTracker({
                    element: elt,
                    dragHandler: (e) => {

                        let overlay = this.viewer.getOverlayById(e.eventSource.element.id)
                        window.overlay = overlay
                        if (!overlay.element.classList.contains('commentEditable')) return false
                        let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                        overlay.position.x += delta.x
                        overlay.position.y += delta.y
                        this.viewer.forceRedraw()
                        this.calcTextBoxPos()
                    },
                    //dragEndHandler: () => {window.console.log('dragEndHandler')},
                })
                new OpenSeadragon.MouseTracker({
                    element: elt.querySelector('.handletl'),
                    dragHandler: (e) => {
                        let overlay = this.viewer.getOverlayById(e.eventSource.element.parentNode.id)
                        if (!overlay.element.classList.contains('commentEditable')) return false
                        let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                        overlay.position.x += delta.x
                        overlay.position.y += delta.y
                        overlay.width -= delta.x
                        overlay.height -= delta.y
                        this.viewer.forceRedraw()
                        this.calcTextBoxPos()
                    },
                    //dragEndHandler: () => {window.console.log('dragEndHandler tl')},
                })
                new OpenSeadragon.MouseTracker({
                    element: elt.querySelector('.handletr'),
                    dragHandler: (e) => {
                        let overlay = this.viewer.getOverlayById(e.eventSource.element.parentNode.id)
                        if (!overlay.element.classList.contains('commentEditable')) return false
                        let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                        overlay.position.y += delta.y
                        overlay.width += delta.x
                        overlay.height -= delta.y
                        this.viewer.forceRedraw()
                        this.calcTextBoxPos()
                    },
                    //dragEndHandler: () => {window.console.log('dragEndHandler tl')},
                })

                new OpenSeadragon.MouseTracker({
                    element: elt.querySelector('.handlebl'),
                    dragHandler: (e) => {
                        let overlay = this.viewer.getOverlayById(e.eventSource.element.parentNode.id)
                        if (!overlay.element.classList.contains('commentEditable')) return false
                        let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                        overlay.position.x += delta.x
                        overlay.width -= delta.x
                        overlay.height += delta.y
                        this.viewer.forceRedraw()
                        this.calcTextBoxPos()
                    },
                    //dragEndHandler: () => {window.console.log('dragEndHandler tl')},
                })
                new OpenSeadragon.MouseTracker({
                    element: elt.querySelector('.handlebr'),
                    dragHandler: (e) => {
                        let overlay = this.viewer.getOverlayById(e.eventSource.element.parentNode.id)
                        if (!overlay.element.classList.contains('commentEditable')) return false
                        let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true)
                        overlay.width += delta.x
                        overlay.height += delta.y
                        this.viewer.forceRedraw()
                        this.calcTextBoxPos()
                    },
                    //dragEndHandler: () => {window.console.log('dragEndHandler tl')},
                })
                new OpenSeadragon.MouseTracker({
                    element: elt.querySelector('.commentOverlayOk'),
                    clickHandler: (e) => {
                        e.originalEvent.preventDefault()
                        e.originalEvent.stopPropagation()
                        setTimeout(() => {
                            this.pathEditOk()
                        }, 500)
                    }
                })
                new OpenSeadragon.MouseTracker({
                    element: elt.querySelector('.commentOverlayAbort'),
                    clickHandler: () => {
                        this.abortPathEdit()
                    }
                })
            }
        },

        addFacesRect (id, box) {

            let elt = document.querySelector('div.commentOverlayOrig').cloneNode(false)
            elt.style.display = 'block'
            elt.id = 'facerect_' + id
            elt.ref = id

            let rect = new OpenSeadragon.Rect(box[0], box[1], box[2], box[3], 0)
            //window.console.log(rect)

            this.viewer.addOverlay({
                element: elt,
                location: rect
            })
            elt.classList.remove('commentOverlayOrig')
            elt.classList.remove('commentEditable')
            elt.classList.add('commentOverlay')
            if (!this.showTextlayer) elt.classList.add('commentHidden')
            elt.innerHTML = '<div class="textDiv">?</div>'

            //window.console.log(elt)

            elt.onclick = (event) => {
                if (event.target.classList.contains('commentEditable')) return false
                if (event.target.id === 'rect_editboxe') return false
                this.selectedFace = event.target.parentNode.id.split('_')[1]
                this.showFaceDialog = true
            }
        },
        editFace () {
            this.showFaceDialog = false
            //window.console.log('editFAce', this.selectedFace, event)
            const face = this.item.faces[this.selectedFace]
            const box = face.bounding_box
            const rect = new OpenSeadragon.Rect(box[0], box[1], box[2], box[3], 0)
            this.showCommentDialog = true
            this.newComment = {
                faceId: this.selectedFace,
                box: rect,
                text: '',
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
        },
        getMonthFromDate(dateStr) {
            // Return an empty string if dateStr is null or undefined
            if (!dateStr) {
                return "";
            }

            // Split the input date string by the dot (.) separator
            const [day, month, year] = dateStr.split('.');

            // Check if we have valid day, month, and year values
            if (!day || !month || !year || isNaN(day) || isNaN(month) || isNaN(year)) {
                return ""; // Return an empty string if any part of the date is invalid
            }

            // Create a new Date object using the MM/DD/YYYY format
            const date = new Date(`${month}/${day}/${year}`);

            // Check if the created date is valid
            if (isNaN(date.getTime())) {
                return ""; // Return an empty string if the date is invalid
            }

            // List of month names
            const monthNames = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            // Get the day, month, and year
            const dayOfMonth = date.getDate(); // Get the day of the month (1-31)
            const monthName = monthNames[date.getMonth()]; // Get the month name
            const yearOfPic = date.getFullYear(); // Get the year

            // Build the formatted date string
            let result = "";

            // Only add day if it is valid
            if (dayOfMonth) {
                result += `${dayOfMonth}`+'.';
            }

            // Only add month if it is valid
            if (monthName) {
                // Add a space if the day is present
                if (result) result += " ";
                result += monthName+',';
            }

            // Only add year if it is valid
            if (yearOfPic) {
                // Add a space if day or month is present
                if (result) result += " ";
                result += yearOfPic;
            }

            return result; // Return the constructed string
        },
        addRects () {
            //window.console.log('addRects')
            if (this.viewer !== null) this.viewer.clearOverlays()

            if (typeof this.$route.params.comment_id !== 'undefined') {
                this.activeComment = decodeURIComponent(this.$route.params.comment_id)
                if (this.activeComment === false) this.activeComment = null
                this.showComments = true
                let commentItem = this.commentItems.filter((cI) => cI.id === this.activeComment)[0]
                if (commentItem) this.showBackside = commentItem.isBackside === true
                /*this.$nextTick(() => {
                    let top = 0
                    if (this.activeComment && this.$refs['comment_' + this.activeComment]) {
                        let vele = this.$refs['comment_' + this.activeComment][0]
                        top = vele.$el.offsetTop - 100
                        this.$refs['commentsContainer'].scrollTo(0, top)
                    }
                })*/

            }
            let commentedFaces = []
            this.filteredCommentItems.forEach((commentItem) => {
                if (commentItem.box !== null) {
                    if (commentItem.faceId !== null) commentedFaces.push(commentItem.faceId)
                    //window.console.log(commentItem)
                    this.addCommentRect(commentItem.id, commentItem.box, commentItem.text), commentItem.faceId
                }
            })

            if (!this.showBackside && this.item.faces !== null && this.item.faces.length > 0) {
                this.item.faces.forEach((face, id) => {
                    if (commentedFaces.indexOf(id) === -1) {
                        this.addFacesRect(id, face.bounding_box)
                    }

                })

            }

            this.zoomToActiveCommentRect()
        },

        calcTextBoxPos () {

            let overlay = this.viewer.getOverlayById('rect_editbox')
            //window.console.log('calcTextBoxPos', overlay.element)
            if (!overlay.element.classList.contains('commentEditable')) return false
            //let delta = this.viewer.viewport.deltaPointsFromPixels(e.delta, true);
            let pathEditModeTextBox = document.querySelector('.pathEditModeTextBox')
            //window.console.log(pathEditModeTextBox)
            if (pathEditModeTextBox == null) return
            let y = overlay.element.offsetTop // + overlay.element.offsetHeight
            /*if (y < 0) {
                y = 0
            } else if (y + pathEditModeTextBox.offsetHeight > document.body.offsetHeight) {
                y = document.body.offsetHeight - pathEditModeTextBox.offsetHeight
            }*/
            let x = overlay.element.offsetLeft
            /*if (x < 0) {
                x = 0
            } else if (x + pathEditModeTextBox.offsetWidth > document.body.offsetWidth) {
                x = document.body.offsetWidth - pathEditModeTextBox.offsetWidth
            }*/

            this.textBoxPos = {
                x: x,
                y: y
            }
        },
        closeWin () {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/feed')
            //this.isActive=false;
        },

        rotateImage () {
            this.imageRotation += 90
            if (this.imageRotation >= 360) this.imageRotation -= 360
        },

        onSendCommentForm (commentData) {
            this.newComment = commentData
            if (this.newComment.id == null) {
                this.create(this.newComment)
            } else {
                this.updateComment(this.newComment)
            }

        },

        updateCommentField (field, value) {
            //window.console.log(field, value)
            Object.assign(this.newComment, {[field]: value})
        },

        deleteItem (item) {
            if (window.confirm('Are you sure you want to delete this item?')) {
                this.del(item).then(() => this.$router.push({name: 'PhotographList'}).catch(err => {
                    window.console.log(err)
                }))
            }
        },
        toggleBackside () {
            //this.showBackside = !this.showBackside
            if (!this.showBackside) {
                this.$router.replace({
                    name: 'PhotographShowBack' + (this.colorized ? 'Color' : ''),
                    params: {id: this.$route.params.id}
                })
            } else {
                this.$router.replace({
                    name: 'PhotographShow' + (this.colorized ? 'Color' : ''),
                    params: {id: this.$route.params.id}
                })
            }
            //this.updateViewer()

            //this.calcMap()
        },

        selectGalery () {
            if (this.selectedGallery === null) return
            let imagegallery = null
            this.imagegalleries.forEach((item) => {
                if (item['@id'] === this.selectedGallery) {
                    imagegallery = item
                    return true
                }
            })

            this.addToGallery(imagegallery, this.item)

        },

        addToGallery (imagegallery, item) {
            imagegallery.photographs.push('/photographs/' + item['id'])
            imagegallery.photographs = [...new Set(imagegallery.photographs)]
            //this.updateRetrievedImageGallery({ photographs: imagegallery.photographs })
            this.updateGallery(imagegallery)
        },

        getTranslation (item) {
                if (!item || typeof item['translations'] === 'undefined') return {
                    name: '-',
                    description: '-'
                }
                let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
                let translation = typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
                return (typeof translation === 'undefined') ? {name: '-', description: '-'} : translation
        },

        showGallerySelectDialog (slide) {
            this.selectedSlide = slide
            this.gallerySelectDialog = true
            this.showButtonsInfoOutClick =false
        },

        updateGallery (gallery) {
            let ig = JSON.parse(JSON.stringify(gallery))
            if (typeof ig.retrosnaps !== 'undefined') ig.retrosnaps = this.arrayToIris(ig.retrosnaps)
            if (typeof ig.subGalleries !== 'undefined') ig.subGalleries = this.arrayToIris(ig.subGalleries)
            if (ig.parentGalleries) delete ig.parentGalleries
            return fetch(ig['@id'], {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify(ig)
            })
                .then(response => response.json())
                .then(() => {
                    this.gallerySelectDialog = false
                    //window.console.log(data)
                })
                .catch((e) => {
                    this.$store.error = e.message
                    return e

                })
        },

        addComment () {
            if (this.token_data == null) {
                //not logged in:
                this.showCommentLoginDialog = true
                return
            }

            this.showComments = false
            this.showCommentDialog = true

            this.newComment = {
                box: null,
                text: '',
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
        },
        commentDialogClose () {
            //window.console.log('commentDialogClose')
            this.pathEditMode = false
            this.box = null
        },
        doCommentDialogClose () {
            this.showCommentDialog = false
            this.commentDialogClose()
        },
        editComment (commentItem) {
            //window.console.log('commentItem', commentItem)
            this.showComments = false
            this.newComment = commentItem
            this.showCommentDialog = true
        },
        deleteComment (commentItem) {
            if (window.confirm(this.$t('app.Comment.DeleteConfirm'))) {
                return fetch(commentItem['@id'], {
                    method: 'DELETE',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(commentItem)
                })
                    .then(() => {
                        this.getComments('/comments?photograph=' + this.$route.params.id)
                        //window.console.log('deleted')
                    })
                    .catch((e) => {
                        this.setError(e)
                        return e

                    })
            }
        },
        reportInappropriateComment (commentItem) {
            window.console.log('reportInappropriateComment', commentItem)
        },
        addCommentToGallery (imagegallery, commentItem) {
            //commentItem.galleries.push(imagegallery['@id']);
            const galleries = [...commentItem.galleries, imagegallery['@id']].filter(function (item, pos, self) {
                return self.indexOf(item) === pos
            })
            //window.console.log(galleries)
            //this.updateCommentField('galleries',galleries)
            Object.assign(commentItem, {'galleries': galleries})
            this.updateComment(commentItem)
            /*imagegallery.photographComments.push('/comments/' + commentItem['id'])
            imagegallery.photographComments = [...new Set(imagegallery.photographComments)]
            this.updateGallery(imagegallery)*/
        },
        editRectangle () {
            this.viewer.clearOverlays()
            this.showCommentDialog = false
            this.pathEditMode = true
            this.box = this.newComment.box
            if (this.box == null) {

                let bounds = this.viewer.viewport.getBounds()
                window.bounds = bounds
                let center = this.viewer.viewport.getCenter()
                let w = bounds.width / 2
                let h = bounds.height / 2
                this.box = {
                    x: center.x - w / 2,
                    y: center.y - h / 2,
                    width: w,
                    height: h,
                    degrees: 0
                }
            }
            //window.console.log(this.box)

            this.addEditRect('editbox', this.box)
        },
        pathEditOk () {
            window.vvv = this.viewer
            this.box = this.viewer.getOverlayById('rect_editbox').getBounds(this.viewer.viewport)
            //window.console.log(this.box)

            this.showCommentDialog = true
            this.pathEditMode = false
            this.newComment.box = this.box
            this.viewer.getOverlayById('rect_editbox').element.classList.remove('commentEditable')
            //this.viewer.getOverlayById('rect_editbox').destroy();
            this.box = null

        },
        async personSelectPathEditOk() {
            // Access the OpenSeadragon viewer
            window.vvv = this.viewer
            try {
                // Get the bounds of the overlay (the rectangle you want to capture)
                const bounds = this.viewer.getOverlayById('rect_selectPerson').getBounds(this.viewer.viewport);

                // Create a canvas to render the box as an image
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set canvas size to the box's width and height
                canvas.width = bounds.width;
                canvas.height = bounds.height;

                // Render the content inside the bounds to the canvas
                await this.renderBoxToCanvas(canvas, bounds, ctx);

                // Convert canvas to image data URL (base64 PNG)
                const imageDataUrl = canvas.toDataURL('image/png');

                // Set the image data URL to display the captured image
                this.imageDataUrl = imageDataUrl;
            } catch (error) {
                window.console.error('Error in captureAndDisplayBox:', error);

            }
        },

        // Method to render the box content to a canvas
        async renderBoxToCanvas(canvas, bounds, ctx) {
            // If you're using OpenSeadragon or a similar viewer, extract the content from the viewer
            const image = await this.getImageInsideBounds(bounds);

            // Draw the image to the canvas inside the specified bounds
            ctx.drawImage(image, bounds.x, bounds.y, bounds.width, bounds.height, 0, 0, bounds.width, bounds.height);
        },
        // Capture the section of the image inside the bounds of the viewer
        async getImageInsideBounds(bounds) {
            return new Promise((resolve, reject) => {
                // Here we assume that `this.viewer` is an OpenSeadragon viewer
                // or any viewer that supports getContext for rendering.

                // Check if the viewer is ready and has an image source
                if (this.viewer && this.viewer.world) {
                    // Use OpenSeadragon's `getImage` method to get the image at the current viewport
                    const tileSource = this.viewer.world.getItemAt(0).source;
                    // Use a canvas to capture the visible area within the bounds
                    const tempCanvas = document.createElement('canvas');
                    const tempCtx = tempCanvas.getContext('2d');

                    // Set the tempCanvas size to the bounds
                    tempCanvas.width = bounds.width;
                    tempCanvas.height = bounds.height;

                    // Assuming you have access to the image or tile data to render
                    // For simplicity, let's assume the viewer has an image object that we can draw:
                    const image = new Image();
                    image.src = tileSource.url;

                    image.onload = () => {
                        // We want to draw the portion of the image within the bounds
                        tempCtx.drawImage(image, bounds.x, bounds.y, bounds.width, bounds.height, 0, 0, bounds.width, bounds.height);
                        resolve(tempCanvas);
                    };

                    image.onerror = (error) => {
                        reject(error);
                    };
                } else {
                    reject('Viewer or image not available');
                }
            });
        },
        // Send the image (base64 data) to the backend
        // uploadImageToBackend(dataUrl) {
            // // Convert base64 data URL to the actual image data (remove metadata like "data:image/png;base64,")
            // const base64Data = dataUrl.split(',')[1];
            //
            // // Prepare the payload
            // const payload = {
            //     image: base64Data,
            //     filename: 'cropped-image.png', // Optional, you can pass the filename you want
            // };

            // // Send to the backend using axios or fetch
            // axios.post('/api/upload-image', payload)
            //     .then(response => {
            //         console.log('Image successfully uploaded:', response.data);
            //         // Handle response (e.g., show success message)
            //     })
            //     .catch(error => {
            //         console.error('Error uploading image:', error);
            //         // Handle error (e.g., show error message)
            //     });
        // },

        abortPathEdit () {
            this.showCommentDialog = false
            this.pathEditMode = false
            if(this.viewer.getOverlayById('rect_selectPerson')){
                this.viewer.getOverlayById('rect_selectPerson').destroy()
            }
            else{
                this.viewer.getOverlayById('rect_editbox').element.classList.remove('commentEditable')
                this.viewer.getOverlayById('rect_editbox').destroy()
            }

            this.box = null
            this.viewer.clearOverlays()
            this.addRects()
            //window.console.log('abortPathEdit')
        },
        submitComment () {
            this.viewer.clearOverlays()
            this.showCommentDialog = false
            this.commentDialogClose()
            //window.console.log(this.newComment)
            if (this.newComment['@id']) {
                this.updateComment(this.newComment)
            } else {
                this.createComment(this.newComment)
            }

        },
        updateComment (comment) {

            return fetch(comment['@id'], {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify(comment)
            })
                .then(response => response.json())
                .then(() => {
                    //window.console.log(data)
                    this.getComments('/comments?photograph=' + this.$route.params.id)
                })
                .catch((e) => {
                    window.console.error(e)
                    this.setError(e)
                    return e

                })
        },
        commentClick (commentItem) {
            //window.console.log(commentItem)
            //this.zoomToActiveCommentRect()
            if (this.activeComment === commentItem.id) return
            this.activeComment = commentItem.id
            //this.showBackside = commentItem.isBackside === true
            this.showTextlayer = true
            if (commentItem.isBackside) {
                this.$router.replace({
                    name: 'PhotographShowBackComment' + (this.colorized ? 'Color' : ''),
                    params: {id: this.item['@id'], comment_id: this.activeComment},
                })
            } else {
                this.$router.replace({
                    name: 'PhotographShowComment' + (this.colorized ? 'Color' : ''),
                    params: {id: this.item['@id'], comment_id: this.activeComment},
                })
            }

        },
        filterCommentItems () {

            this.filteredCommentItems = this.commentItems.filter((item) => (item.box && ((item.isBackside === true) === this.showBackside)))
            //window.console.log(this.showBackside, this.filteredCommentItems)

        },
        upvoteComment (commentItem) {
            //window.console.log('upvote')
            return fetch(commentItem['@id'] + '/vote/up', {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(() => {
                    //window.console.log(data)
                    this.getComments('/comments?photograph=' + this.$route.params.id)
                })
                .catch((e) => {
                    this.setError(e)
                    return e

                })
        },
        downvoteComment (commentItem) {
            //window.console.log('downvote')
            return fetch(commentItem['@id'] + '/vote/down', {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(() => {
                    //window.console.log(data)
                    this.getComments('/comments?photograph=' + this.$route.params.id)
                })
                .catch((e) => {
                    this.setError(e)
                    return e

                })
        },
        toggleAdditionalPhotographsOpen (target) {
            if (this.item['additionalPhotographs'].length + this.item['retrosnaps'].length === 1) {
                this.additionalPhotographsOpen = true
            }
            if (this.additionalPhotographsOpen) {
                let targetId = target['@id'] //encodeURIComponent(target['@id'])
                if (target['@id'].indexOf('/retrosnaps/') > -1) {
                    this.$router.push({name: 'RetrosnapShow' + (this.colorized ? 'Color' : ''), params: {id: targetId}})
                } else {
                    this.$router.push({
                        name: 'PhotographShow' + (this.colorized ? 'Color' : ''),
                        params: {id: targetId}
                    })
                }

            } else {
                this.additionalPhotographsOpen = true
            }
        },
        toggleTextlayer () {
            this.showTextlayer = !this.showTextlayer
            this.setsettings({showTextlayer: this.showTextlayer})
            this.$store.commit('settings/setsettings', {showTextlayer: this.showTextlayer})
        },
        closeAdditionalPhotographs () {
            if (!this.dragged) {
                this.additionalPhotographsOpen = false
            }
        },
        setTilesFilter: function () {
            //window.console.log(this.brightness, ((this.contrast + 100) / 200) * 2)
            this.viewer.setFilterOptions({
                filters: {
                    processors: [
                        OpenSeadragon.Filters.BRIGHTNESS((this.brightness * 2)),
                        OpenSeadragon.Filters.CONTRAST(((this.contrast + 100) / 200) * 2)
                    ]
                }
            })
            //document.querySelectorAll('.leaflet-tile').forEach((item) => { item.style.filter = 'contrast(' + (this.contrast / 100 + 1) + ') brightness(' + (this.brightness / 100 + 1) + ')' })
        },
        getCompleteUrl: function () {
            return window.location.origin + this.$route.fullPath
        },
        htmlAsText (value) {

            return (typeof value == 'undefined' || value == null) ? '' : value.replace(/<\/?[^>]+(>|$)/g, '')
        },
        onDragStart () {
            clearTimeout(this.dragTimeout)

            this.dragged = false
            this.dragTimeout = setTimeout(() => { this.dragged = true }, 100) // Minimal delay to be regarded as drag instead of click
        },
        onDragClick (e) {
            if (this.dragged) {
                e.preventDefault()
                e.stopPropagation()
            }

            this.dragged = false
        },

        showPhotosFromCollection () {
            this.$router.push({name: 'PhotographCollectionShow', params: {id: this.item.collection['@id']}})
            return false
        },
        toggleVoice () {

            if (!this.audioPlaying) {
                //audio starten:
                if (this.audioObj === null) {
                    this.audioObj = new Audio(this.entrypoint + '/getAudio/' + this.$root.$i18n.locale + '/' + this.item['@id'])
                    this.audioObj.addEventListener('canplaythrough', event => {
                        event.target.play()
                        this.audioPlaying = true
                    })
                    this.audioObj.addEventListener('ended', () => {
                        this.audioPlaying = false
                    })
                } else {
                    this.audioObj.play()
                    this.audioPlaying = true
                }
            } else {
                //audio stoppen:
                this.audioObj.pause()
                this.audioPlaying = false
            }

        },
        openLoginPopUp(){
            if (this.token == null) {
                //not logged in:
                this.showCommentLoginDialog = true
                return
            }
        },
        toggleColorized () {
            let routeName = 'PhotographShow'
            let routeParams = this.$route.params
            if (this.showBackside) routeName += 'Back'
            if (this.activeComment) {
                routeName += 'Comment'
            }
            if (this.colorized) {
                this.$router.replace({name: routeName, params: routeParams})
            } else {
                this.$router.replace({name: routeName + 'Color', params: routeParams})
            }

        },
        selectSection () {
            this.newComment = {
                box: null,
                text: '',
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
            this.editRectangle()
        },
        // selectPerson () {
        //
        //     let category = this.categories.filter((category) => category.name == 'Person')
        //     //window.console.log(category);
        //     this.newComment = {
        //         box: null,
        //         text: '',
        //         category: category[0]['@id'],
        //         isPrivate: false,
        //         'photograph': this.item['@id'],
        //         isBackside: this.showBackside !== false
        //     }
        //     this.editRectangle()
        // // },
        // selectPerson(){
        //     this.viewer.clearOverlays()
        //     this.showCommentDialog = false
        //     this.pathEditMode = true
        //     this.box = this.newComment.box
        //     if (this.box == null) {
        //
        //         let bounds = this.viewer.viewport.getBounds()
        //         window.bounds = bounds
        //         let center = this.viewer.viewport.getCenter()
        //         let w = bounds.width / 2
        //         let h = bounds.height / 2
        //         this.box = {
        //             x: center.x - w / 2,
        //             y: center.y - h / 2,
        //             width: 0.4,
        //             height: 0.4,
        //             degrees: 0
        //         }
        //     }
        //     window.console.log(this.box)
        //
        //     this.addEditRect('selectPerson', this.box)
        // },
        selectPerson (){
            const coords = this.getStencilCoordinates()
            if (coords != null) {
                this.defaultPosition = {
                    left: coords.left,
                    top: coords.top,
                }
                this.defaultSize = {
                    width: coords.width,
                    height: coords.height,
                }
            }
            this.cropperModal = true
            if (this.$refs.cropper) {
                if (coords !== null) {
                    this.$refs.cropper.setCoordinates({
                        width: coords.width,
                        height: coords.height,
                        left: coords.left,
                        top: coords.top
                    })
                }
            }
        },
        getStencilCoordinates () {
            let bounds = this.viewer.viewport.getBounds()
            window.bounds = bounds
            let center = this.viewer.viewport.getCenter()
            let w = bounds.width / 2
            let h = bounds.height / 2
            return {
                left: center.x - w / 2,
                top: center.x - h / 2,
                width: 350,
                height: 350,
            }
        },
        selectBuilding () {
            let category = this.categories.filter((category) => category.name == 'Gebäude')
            //window.console.log(category);
            this.newComment = {
                box: null,
                text: '',
                category: category[0]['@id'],
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
            this.editRectangle()
        },
        selectCar() {
            let category = this.categories.filter((category) => category.name == 'Automobile')
            window.console.log(category);
            this.newComment = {
                box: null,
                text: '',
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
            this.editRectangle()
        },
        selectText () {
            let category = this.categories.filter((category) => category.name == 'Text')
            //window.console.log(category);
            this.newComment = {
                box: null,
                text: '',
                category: category[0]['@id'],
                isPrivate: false,
                'photograph': this.item['@id'],
                isBackside: this.showBackside !== false
            }
            this.editRectangle()
        },
        toggleOrderItems () {
            this.orderItemsDrawer = true
        },
        /*getStencilCoordinates () {
            const factor = this.item.ratio < 1 ? this.item.size[1] / 1024 : this.item.size[0] / 1024
            if (!this.item.thumbnailCoordinates || !this.item.thumbnailCoordinates[this.editThumbnailType]) return null
            return {
                left: this.item.thumbnailCoordinates[this.editThumbnailType].left / factor * this.item.size[0],
                top: this.item.thumbnailCoordinates[this.editThumbnailType].top / factor * this.item.size[0],
                width: this.item.thumbnailCoordinates[this.editThumbnailType].width / factor * this.item.size[0],
                height: this.item.thumbnailCoordinates[this.editThumbnailType].height / factor * this.item.size[0]
            }
        },*/
        onProductSelect (product, productConfig) {
            //window.console.log(product, productConfig)
            this.cropperModal = true
            this.cropperRatio = 1
            //window.console.log(product.additionalAttributes.files[0])
            if (product.additionalAttributes.files[0]) {
                this.cropperRatio = product.additionalAttributes.files[0].width / product.additionalAttributes.files[0].height
            }
            this.selectedProduct=product;
            this.productConfig=productConfig;
            this.$nextTick(() => {
                this.$refs.cropper.refresh();
            });
            /*
                        const coords = this.getStencilCoordinates()
                        if (coords != null) {
                            this.defaultPosition = {
                                left: coords.left,
                                top: coords.top,
                            }
                            this.defaultSize = {
                                width: coords.width,
                                height: coords.height,
                            }
                        }
                        */

        },
        onCropperChange () {

        },
        calcDpi({ coordinates ,canvas}) {
            window.console.log(coordinates, canvas)
        },
        changeCropper () {
            const { canvas} = this.$refs.cropper.getResult()
            this.getMentions()
            // window.console.log('changeCropper', coordinates, canvas.width, canvas.height)
            // this.calcDpi();

            this.cropperImage = canvas.toDataURL()
             this.showSelectPerson=true

            // this.cropperModal = false
        },
        onResize () {
            this.isMobile = window.innerWidth < 600
        },
        defaultSize ({imageSize, visibleArea}) {
            return {
                width: (visibleArea || imageSize).width,
                height: (visibleArea || imageSize).height,
            }
        },
        startInactivityTimer() {
            this.inactivityTimer = setTimeout(() => {
                this.fadeOutZoom();
            }, 5000);
        },
        resetInactivityTimer() {
            if (this.inactivityTimer) {
                clearTimeout(this.inactivityTimer); // Clear the existing timer
            }
            this.startInactivityTimer(); // Start a new one
        },
        fadeOutZoom() {
            // Trigger the fade-out behavior
            this.zoomIsVisible = false; // This will hide the zoom element after 2 seconds of inaction
        }

    },
    mounted() {
            this.startInactivityTimer();

    },
    destroyed() {
        if (this.inactivityTimer) {
            clearTimeout(this.inactivityTimer);
        }
    }
}
</script>
