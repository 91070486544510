<template>
    <v-row
        class="d-flex align-center justify-center "
        :class="!displayColumn ? '' : 'flex-column'"
        no-gutters
    >
        <v-col
            cols="auto"
            class="d-flex justify-center mr-1 mb-2"
        >
            <v-img
                :src="entrypoint+'/media/calltoactions/button-HastDuBilder2.png'"
                alt="Report Collection"
                height="auto"
                class="clickable-img"
                @click="handleReportClick"
            ></v-img>
        </v-col>

        <v-col
            cols="auto"
            class="d-flex justify-center align-center mb-2"
        >
            <v-img
                :src="entrypoint+'/media/calltoactions/button-browseCollections2.png'"
                alt="Browse Collections"
                height="auto"
                class="clickable-img"
                cover
                @click="handleBrowseClick"
            ></v-img>
        </v-col>
        <v-col
            cols="auto"
            class="d-flex justify-center align-center mb-2"
        >
            <v-btn
                v-if="showCancelButton"
                class="cancel-button"
                alt="Cancel"
                @click="goBack"
            >
                <img class="arrow-cancel" src="@/assets/img/Arrow-simple90.svg" alt="OK">
                Zurück
            </v-btn>
        </v-col>
    </v-row>
</template>
<style>
.cancel-button{
    background-color: transparent!important;
}
.clickable-img {
    cursor: pointer; /* Change cursor to pointer to indicate clickable area */
    transition: transform 0.3s ease; /* Smooth transform for hover effect */
    border-radius: 8px;
    max-width: 350px;
}

.clickable-img:hover {
    transform: scale(1.05); /* Slightly enlarge the image on hover */

}
@media screen and (max-width: 710px) {
    .clickable-img{
        max-width: 400px;
    }
}
@media screen and (max-width: 510px) {
    .clickable-img{
        max-width: 350px;
    }
}
@media screen and (max-width: 410px) {
    .clickable-img{
        max-width: 300px;
    }
}
@media screen and (max-width: 310px) {
    .clickable-img{
        max-width: 250px;
    }
}
</style>
<script >
import {ENTRYPOINT} from "@/config/entrypoint";

export default {
    props: {
        displayColumn: {
            type: Boolean,
            required: true
        },
        showCancelButton: {
            type: Boolean,
            default: true  // Set default value to true for showCancelButton
        }
    },
    data(){
        return{
            entrypoint: ENTRYPOINT,
        }
    },
    methods: {
        goBack(){
            window.history.back();
        },
        handleBrowseClick() {
            this.$router.push({name: 'CollectionsList'})
        },
        handleReportClick() {
            window.location.href = ' https://nimmerso.app/report_collection';
        },
    },
};
</script>

