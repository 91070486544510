<template>
    <div class="collection-intro-container">
        <v-toolbar
            dense
            class="apptoolbar"
            @click="goToFeed"
        >
            <router-link to="/feed">
                <img
                    src="@/assets/img/nimmerso_typeface.svg"

                    :alt="$t('app.Title')"
                    class="logo_nav_bar"
                />
            </router-link>
            <v-spacer></v-spacer>

            <v-spacer v-if='deferredInstallPrompt'></v-spacer>
            <v-tooltip v-if='deferredInstallPrompt' bottom>
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn small :icon="isMobile" @click="showInAppInstallPromotion" v-on="onTooltip"  data-v-step="2">
                        <!--<v-icon color="grey darken-1">mdi-plus-box</v-icon>-->
                        <img src="/img/icons/Ebicon_512x512.png" style="width:32px; height:32px;" />
                        <span v-if="!isMobile">{{ $t('app.installButton') }}</span>
                    </v-btn>
                </template>
                <span>{{ $t('app.installButton') }}</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="showInAppInstallPromotionDialog"
                max-width="490"
                style="z-index: 1001"
            >
                <v-card>
                    <v-card-title class="text-h5 pb-10">{{ $t('app.installTitle') }}</v-card-title>
                    <v-card-text class="text-justify">
                        <img src="@/assets/img/screenshot_android_nimmerso.png" style="float: left; margin: 0px 15px 15px 0px;" />
                        {{ $t('app.installDescription') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="secondary"
                            text
                            @click="installDismiss()"
                        >
                            {{ $t('app.installDismiss') }}
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="primary"
                            text
                            @click="installAgree()"
                        >
                            {{ $t('app.installAgree') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <div class="container_for_buttons">
        <landing-buttons :displayColumn="true"/>
        </div>
    </div>
</template>

<script>
import LandingButtons from "@/components/landing/LandingButtons.vue";
export default {
    components: {LandingButtons},
    data: function () {
        return {
            deferredInstallPrompt: null,
            isMobile: false,
            showInAppInstallPromotionDialog: false,
            tourSteps: [
                {
                    target: '[data-v-step="0"]',  // We're using document.querySelector() under the hood
                    content: `<strong>Lass dir Bilder in deiner Nähe anzeigen!</strong>`
                },
                {
                    target: '[data-v-step="1"]',
                    content: 'Hier kannst du nach verschiedenen Bildern suchen.'
                },
                {
                    target: '.masonry-item:first-of-type',  // We're using document.querySelector() under the hood
                    content: `<strong>Klicke auf die Vorschau um Details anzusehen</strong>`
                },
                {
                    target: '[data-v-step="3"]',
                    content: 'Ein Klick auf diesen Button öffnet das Menü',
                },
                {
                    target: '[data-v-step="4"]',
                    content: 'Du kannst uns deine Meinung zu Nimmerso sagen indem du hier klickst. Wir würden uns freuen!',
                    before: () => new Promise((resolve) => {
                        // Time-consuming UI/async operation here
                        this.drawer=true;
                        setTimeout(resolve,500);
                        //resolve('foo')
                    })
                },
            ],
        }
    },
    created () {
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevents the default mini-infobar or install dialog from appearing on mobile
            e.preventDefault();
            // Save the event because you'll need to trigger it later.
            this.deferredInstallPrompt = e;
            if (this.deferredInstallPrompt) {

                this.tourSteps.splice(2, 0, {
                    target: '[data-v-step="2"]',
                    content: 'Installiere ein Nimmerso-Icon auf deinem Smartphone, damit du immer sofort Nimmerso nutzen kannst.',
                });
            }
        })
    },
    methods: {
        goToFeed() {
            this.$router.push('/feed');
        },
        onResize () {
            this.isMobile = window.innerWidth < 600
        },
        showInAppInstallPromotion(){
            this.showInAppInstallPromotionDialog=true;

        },
        installDismiss(){
            this.showInAppInstallPromotionDialog=false;
        },
        async installAgree(){
            this.showInAppInstallPromotionDialog=false;
            // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
            this.deferredInstallPrompt.prompt();
            // Find out whether the user confirmed the installation or not
            const { outcome } = await this.deferredInstallPrompt.userChoice;
            // The deferredPrompt can only be used once.
            this.deferredInstallPrompt = null;
            // Act on the user's choice
            if (outcome === 'accepted') {
                window.console.log('User accepted the install prompt.');
            } else if (outcome === 'dismissed') {
                window.console.log('User dismissed the install prompt');
            }
        },
    },
    beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted () {
        this.onResize()

        window.addEventListener('resize', this.onResize, { passive: true })

    }
};
</script>

<style scoped>
.apptoolbar{
    cursor: pointer;
}
.collection-intro-container {
    position:absolute;
    background-color:  #111016;
    top: 0;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
}


.container_for_buttons{
    background-color: #111016 !important;
    width: 100% !important;
    margin-top: 55px;
    background-image: url("../../assets/img/retro-background_1200x.webp");
    background-size: 50%;
    min-height: 100vh;
    background-position: right;
    background-repeat: no-repeat;
}
@media screen and (max-width: 1200px) {
    .container_for_buttons {
        width: 100% !important;
        margin-top: 55px;
        background-image: url("../../assets/img/retro-background_1200x.webp");
        background-size: 80%;
    }
}
@media screen and (max-width: 710px) {
    .container_for_buttons{
        background-image: unset;
    }
    .logo-to-home{
        position: relative;
        left: 30%;
    }
}
@media screen and (max-width: 510px) {
    .logo-to-home{
        left: 25%;
    }
}
@media screen and (max-width: 410px) {
    .logo-to-home{
        left: 16%;
    }
}
@media screen and (max-width: 310px) {
    .logo-to-home{
        left: 8%;
    }
}
</style>
