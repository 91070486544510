<template >
    <div if="item" >
        <form  @submit.prevent="handleSubmit(item)" style="overflow-y:auto" >
            <v-card v-if="step===0" class="pop-up-style">
                <v-card-title  class="style-feedback-dialog">{{$t('app.FeedbackDialogDescription3')}}</v-card-title>
                <v-card-subtitle  class="style-feedback-dialog">
                    <span>{{$t('app.FeedbackDialogSubDescription3')}}</span>
                    <br>
                    <span>{{$t('app.FeedbackDialogSubDescription4')}}</span>
                </v-card-subtitle>
                <v-card-text >
                    <v-textarea
                    label="Nachricht"
                    class="feedback-textbox style-feedback-dialog"
                    v-model="feedbackText"
                    outlined
                    color="black"
                    auto-grow
                ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeWin" class="cancel-style">
                        ABBRECHEN
                    </v-btn>
                    <v-btn
                        v-show ="feedbackText"
                        color="primary"
                        @click="step = 1"
                    >
                        {{$t('app.continue1')}}
                    </v-btn>

                </v-card-actions>
            </v-card>
            <v-card v-if="step===1" class="pop-up-style">
                <v-card-title>{{$t('app.FeedbackDialogDescription2')}}</v-card-title>
                <v-card-text>
                    <v-rating
                        v-model="feedbackRating"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        half-increments
                        hover
                        large
                    ></v-rating>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="step=0">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="handleSubmit"
                    >
                        {{$t('app.FeedbackSubmit')}}
                    </v-btn>

                </v-card-actions>
            </v-card>
    </form>
    </div>
</template>
<style>
.noBackground{
    background-color: transparent !important;
}
.pop-up-style{
    background-color: #B6AA99 !important;
}
.cancel-style{
    color: #373737 !important;
}
.feedback-textbox .v-input__slot {
    background-color: #DFD9D3 !important;
}
.feedback-textbox .v-input__slot .v-text-field__slot textarea {
    color:black !important;
}

.feedback-textbox .v-input__slot .v-text-field__slot label {
    color:black !important;
}
.style-feedback-dialog{
    color: black !important;
}

.style-feedback-dialog .v-textarea{
    color: black !important;
}
::v-deep .feedback-textbox input {
    color: black;
}
::v-deep .v-text-field .v-label {
    color: rgba(40, 44, 57, 1) !important;
}

</style>

<script>


export default {
    components: {
    },
    props: {
        handleSubmit: {
            type: Function,
            required: true
        },
        handleUpdateField: {
            type: Function,
            required: true
        },
        handleClose: {
            type: Function,
            required: true
        },
        handleShowReportBug: {
            type: Function,
            required: true
        },
        editRectangle: {
            type: Function,
            required: false
        },

        values: {
            type: Object,
            required: true
        },

        errors: {
            type: Object,
            default: () => {}
        },

        initialValues: {
            type: Object,
            default: () => {}
        }
    },

    computed: {

        item () {
            return this.initialValues || this.values
        },

        violations () {
            return this.errors || {}
        }
    },
    data: () => ({
        step: 0,
        feedbackRating: null,
        feedbackText: '',
    }),
    created () {
        this.step=0;
    },
    beforeDestroy () {
        window.console.log('beforeDestroy')
        this.step=0;
    },
    beforeMount () {
        this.step=0;
    },
    watch: {
        item: {
            handler:function() {
                this.feedbackRating = this.item.additionalData.rating ?? null;
                this.feedbackText = this.item.text;
                if(this.item.text==='') this.step=0;
            },
            deep: true
        },
        feedbackText: function () {
            this.handleUpdateField('text',this.feedbackText);
        },
        feedbackRating: function () {
            this.handleUpdateField('additionalData',{rating: this.feedbackRating});
        },

    },
    methods: {
        isInvalid (key) {
            return Object.keys(this.violations).length > 0 && this.violations[key]
        },
        closeWin(){
            this.step=0;
            this.handleClose();
        },

    }
}
</script>
