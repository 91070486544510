<template>
    <v-dialog v-if="orders" v-model="orders" content-class="orderDialog" fullscreen
              transition="fade-transition">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>
        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-card v-if="photographcollection && order">

            <v-row class="pt-4">
                <v-col
                    cols="12"
                    sm="8"

                >
                    <v-card>
                        <v-card-subtitle class="pb-0">{{ $t('app.Order.ContributingTo') }}</v-card-subtitle>
                        <v-card-title class="pt-1">{{ photographcollection.headline }}</v-card-title>


                        <OrderForm
                            :handle-update-field="updateField"
                            :handle-valid-change="handleValidChange"
                            :values="order"
                            :initial-values="order"
                            :errors="violations"/>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                >

                    <v-card v-if="order">
                        <v-card-title>{{ $t('app.Order.ContributionSummary') }}</v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item :key="i" v-for="(item,i) in order.items">
                                    <v-list-item-avatar v-if="item.product.images">
                                        <v-img
                                            :src="'/favicon.ico'"
                                            aspect-ratio="1"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>

                                        <v-list-item-title>{{ getTranslation(item.product).name }}<br/>
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-for="(config, key) in item.config" :key="key">{{getProductConfigKey(item.product,key)}}: {{getProductConfig(item.product,key,config)}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>{{ $t('app.Order.Subtotal') }}
                                        <div class="text-right text-no-wrap" >
                                            € {{ formatPrice(order.priceWithVat) }}
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>{{ $t('app.Order.Shipping') }}
                                        <div class="text-right text-no-wrap">
                                            {{ order.shippingPrice === 0 ? $t('app.Order.Free') :'€ '+ formatPrice(order.shippingPrice)  }}
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>{{ $t('app.Order.Total') }}
                                        <div class="text-right text-no-wrap">
                                            € {{ formatPrice(order.priceTotal) }}
                                        </div>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                            <v-list>
                                <v-list-item>
                                    <v-checkbox
                                        v-model="orderAgree"
                                    />
                                    <div
                                        v-html="$t('app.Order.agree', { privacylink:'/privacy', agblink: '/agb'})">
                                    </div>
                                </v-list-item>
                            </v-list>
                            <v-btn :disabled="!isOrderValid" x-large color="primary" block @click="onSendForm">
                                {{ $t('app.Order.SubmitPayment') }}
                            </v-btn>
                            {{ $t('app.Order.HelpPayment') }}
                        </v-card-text>
                    </v-card>


                </v-col>
            </v-row>


            <v-footer class="text-justify pa-4">
                <v-btn small dark v-if="order" @click="goToCollection">{{ $t('app.back') }}
                </v-btn>
            </v-footer>
        </v-card>
        <v-card v-if="activeOrder !== null && !activeOrder">
            <v-card-title>Fehler</v-card-title>
            <v-card-text>Kein aktiver Eintrag gefunden.</v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import OrderForm from './Form'
import { mapActions, mapGetters } from 'vuex'
import { ENTRYPOINT } from '@/config/entrypoint'
//const {mapGetters, mapActions} = createNamespacedHelpers('order/create')

export default {
    components: {
        OrderForm
    },

    data () {
        return {
            orderAgree: false,
            entrypoint: ENTRYPOINT,
            isValid: false,
            activeOrder: null,
        }
    },

    computed: {
        ...mapGetters({
            error: 'order/create/error',
            isLoading: 'order/create/isLoading',
            created: 'order/create/created',
            violations: 'order/create/violations',
            searchfilters: 'searchfilters/items',
            photographcollection: 'photographcollection/show/retrieved',
            orders: 'order/list/items',
            order: 'order/update/retrieved',
            updated: 'order/update/updated',
            personItem: 'person/show/retrieved',
            token: 'user/token/token',
            refresh_token: 'user/token/refresh_token',
            token_data: 'user/token/token_data',
        }),
        isOrderValid () {
            return this.orderAgree && this.isValid
        }
    },
    mounted() {
        this.checkBackButton();
    },
    watch: {
        // eslint-disable-next-line object-shorthand,func-names
        created: function (created) {
            if (!created) {
                return
            }

            this.$router.push({name: 'OrderUpdate', params: {id: created['@id']}})
        },
        personItem: function () {
            this.retrieveOrders('/people/' + this.personItem.id + '/orders?photographCollection=' + decodeURIComponent('/photograph_collections/' + this.$route.params.id))

        },
        orders: function () {
            //this.order=this.orders[0];
            this.activeOrder = false
            this.orders.forEach(o => {
                if (o.status == null) {
                    this.activeOrder = true
                    this.retrieve(o['@id'])
                }
            })
            if(this.activeOrder==false){
                setTimeout(()=>{
                    this.$router.push({name:'PhotographCollectionShow', params: {id: '/photograph_collections/'+this.$route.params.id}})
                }, 5000)
            }
            // this.retrieve(this.orders[0]['@id'])

        },
        order: function () {
            window.console.log('watch order', this.order)
            if (
                this.order.status == null &&
                this.order.paymentStatus === 'open' &&
                this.order.paymentCheckoutUrl !== null &&
                this.order.paymentMethod !== 'banktransfer'
            ) {
                window.location.href = this.order.paymentCheckoutUrl
            }
        },
        updated: function () {
            const {
                status,
                paymentStatus,
                paymentCheckoutUrl,
                paymentMethod,
                paymentRedirectUrl
            } = this.updated;

            const isPaymentOpen = paymentStatus === 'open';
            const isStatusNull = status == null;
            const hasCheckoutUrl = paymentCheckoutUrl !== null;
            const isBankTransfer = paymentMethod === 'banktransfer';
            const hasRedirectUrl = paymentRedirectUrl !== null;

            if (isStatusNull && isPaymentOpen && hasCheckoutUrl) {
                if (isBankTransfer && hasRedirectUrl) {
                    // Redirect to order created URL
                    window.location.href = paymentRedirectUrl;
                } else if (!isBankTransfer) {
                    // Redirect to payment checkout URL
                    window.location.href = paymentCheckoutUrl;
                }
            }
        }
    },
    created () {
        this.retrievePhotographCollection('/photograph_collections/' + decodeURIComponent(this.$route.params.id))
        if (this.token_data) {
            this.personRetrieve('/people/' + this.token_data.person_id)
        }
    },
    methods: {
        ...mapActions({
            create: 'order/create/create',
            retrievePhotographCollection: 'photographcollection/show/retrieve',
            retrieveOrders: 'order/list/default',
            personRetrieve: 'person/show/retrieve',
            update: 'order/update/update',
            retrieve: 'order/update/retrieve',
            updateRetrieved: 'order/update/updateRetrieved'
        }),
        formatPrice(price) {
            // Convert price to a number and fix to two decimal places
            const formattedPrice = Number(price).toFixed(2);
            // Use toLocaleString for thousand separators if needed
            return formattedPrice.toLocaleString();
        },
        goToCollection(){
            this.$router.go(-1);
        },
        checkBackButton: function() {
            window.console.log('back button clicked')
            if (
                window.performance &&
                window.performance.navigation.type ===
                window.performance.navigation.TYPE_BACK_FORWARD &&
                this.type === "main"
            ) {
                window.location.reload();
            }
        },
        onSendForm (){
            this.update(this.order)
        },

        updateField (field, value) {
            //window.console.log('updateField', field, value)
            this.updateRetrieved({ [field]: value })
            //Object.assign(this.order, {[field]: value})
        },
        handleValidChange (value) {
            this.isValid = value
            window.console.log('handleValidChange', value)
        },

        getTranslation (item) {
            let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
        },
        getProductConfigKey(product,key){
            let returnValue=key;
            const configOption = product.configOptions.find((o) => o.id === key);
            if(configOption && typeof configOption.label !=='undefined' && typeof configOption.label[this.$root.$i18n.locale] !=='undefined'){
                returnValue=configOption.label[this.$root.$i18n.locale];
            }
            return returnValue;
        },
        getProductConfig(product, key, config){
            let returnValue=config;
            const configOption = product.configOptions.find((o) => o.id === key);
            const choice= configOption ? configOption.choices.find((c) => c.value === config) : null;
            if(choice && typeof choice.name !=='undefined' && typeof choice.name[this.$root.$i18n.locale] !=='undefined') returnValue = choice.name[this.$root.$i18n.locale];

            return returnValue;
        }
    }
}
</script>
<style scoped>
.agreement-links {
    position: absolute;
    z-index: 2;
}
</style>
