<template>
    <div class="puzzle-panel panel">
        <img id="robot-sign" src="@/assets/img/little_robot.png" alt="Ein kleiner Retro-Roboter">
        <h2>Kurz prüfen,<br> dass du kein Roboter bist :)</h2>
        <!-- Puzzle Grid -->
        <div class="puzzle-grid">
            <img
                v-for="(tile, index) in tiles"
                :key="tile"
                :src="backendUrl + folder + '/' + tile"
                :data-index="index"
                :ref="'tile-' + index"
            :class="{ 'center-tile': tile === '4.jpg' || tile === '5.jpg' || tile === '6.jpg', 'no-border': solved }"
            :draggable="tile !== '5.jpg' && tile !== '4.jpg' && tile !== '6.jpg'"
            @dragstart="handleDragStart(index)"
            @dragover.prevent
            @drop="handleDrop(index)"
            @touchstart="handleTouchStart(index, $event)"
            @touchmove="handleTouchMove($event)"
            @touchend="handleTouchEnd($event)"
            />
        </div>

        <div class="buttons-box">
            <button id="cancel-button" type="button" @click="$emit('close')">
                <img class="cancel-x" src="@/assets/img/x_goldenRatio.svg" alt="Cancel">
                Aufgeben
            </button>
            <button id="new-puzzle-button" type="button" @click="updatePuzzle">
                Anderes Puzzle
            </button>
            <button id="validate-button" type="button" @click="validateSolution">
                Lösen
                <img class="arrow-simple" src="@/assets/img/Arrow-simple90b.svg" alt="OK">
            </button>
        </div>
    </div>
</template>

<script>
import {ENTRYPOINT} from '@/config/entrypoint'

export default {
    data() {
        return {
            tiles: [], // Shuffled tiles from backend
            userOrder: [], // Track user-rearranged tiles
            folder: "",
            backendUrl: ENTRYPOINT,
            draggedTileIndex: null,
            solved: false,
        };
    },
    async mounted() {
        await this.loadPuzzle();
    },
    methods: {
        async loadPuzzle() {
            const response = await fetch(`${this.backendUrl}/captcha`);
            const { folder: fetchedFolder, shuffled_tiles } = await response.json();

            this.folder = fetchedFolder;
            this.tiles = shuffled_tiles;
            this.userOrder = [...shuffled_tiles]; // Copy of the shuffled order

            window.console.log("Folder:", this.folder);
            window.console.log("User Solution:", this.userOrder);
        },
        async updatePuzzle() {
            await this.loadPuzzle();
        },
        handleDragStart(index, event) {
            if (this.tiles[index] !== "5.jpg" || this.tiles[index] !== "4.jpg"  ||this.tiles[index] !== "6.jpg" ) {
                this.draggedTileIndex = index;
                event.dataTransfer.effectAllowed = "move";
            }
        },

        handleDrop(index) {
            if (this.draggedTileIndex !== null && this.tiles[index] !== "5.jpg" && this.tiles[index] !== "4.jpg"  && this.tiles[index] !== "6.jpg") {
                const tilesCopy = [...this.tiles];
                // Swap tiles visually
                [tilesCopy[this.draggedTileIndex], tilesCopy[index]] = [tilesCopy[index], tilesCopy[this.draggedTileIndex]];

                // Update userOrder to reflect the new order
                [this.userOrder[this.draggedTileIndex], this.userOrder[index]] = [
                    this.userOrder[index],
                    this.userOrder[this.draggedTileIndex]
                ];
                this.tiles = [...tilesCopy];
            }

            this.draggedTileIndex = null;
        },
        handleTouchStart(index, event) {
            if (this.tiles[index] !== "5.jpg" ||this.tiles[index] !== "4.jpg"  ||this.tiles[index] !== "6.jpg") {
                this.draggedTileIndex = index;
                const touch = event.touches[0];
                this.startTouchX = touch.clientX;
                this.startTouchY = touch.clientY;

                // Store the initial position of the tile (used for relative movement)
                const draggedTile = this.$refs[`tile-${this.draggedTileIndex}`][0];
                this.startPosition = {
                    left: draggedTile.offsetLeft,
                    top: draggedTile.offsetTop
                };
            }
        },

        handleTouchMove(event) {
            if (this.draggedTileIndex !== null) {
                const touch = event.touches[0];
                const draggedTile = this.$refs[`tile-${this.draggedTileIndex}`][0];

                // Calculate the movement delta from the initial touch position
                const deltaX = touch.clientX - this.startTouchX;
                const deltaY = touch.clientY - this.startTouchY;

                // Move the tile using `transform: translate()`
                draggedTile.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
            }
        },

        handleTouchEnd(event) {
            if (this.draggedTileIndex !== null) {
                const touch = event.changedTouches[0];
                const draggedTile = this.$refs[`tile-${this.draggedTileIndex}`][0];

                // Reset the transform to place the tile back into the grid layout
                draggedTile.style.transform = '';

                // Get the tile closest to the drop position
                const closestTileIndex = this.getClosestTileIndex(touch.clientX, touch.clientY);
                if (closestTileIndex !== null && this.tiles[closestTileIndex] !== "5.jpg" && this.tiles[closestTileIndex] !== "4.jpg" && this.tiles[closestTileIndex] !== "6.jpg") {
                    this.handleDrop(closestTileIndex);
                }

                // Reset dragged tile
                this.draggedTileIndex = null;
            }
        },

        getClosestTileIndex(x, y) {
            // Logic to calculate which tile is closest to the drop position
            let closestIndex = null;
            let minDistance = Infinity;

            // Iterate over the refs keys, not using forEach but using Object.keys
            Object.keys(this.$refs).forEach(refKey => {
                const tileRef = this.$refs[refKey];

                // Each ref is an array (even if it has only one element)
                const rect = tileRef[0].getBoundingClientRect();
                const tileCenterX = rect.left + rect.width / 2;
                const tileCenterY = rect.top + rect.height / 2;

                const distance = Math.sqrt(Math.pow(tileCenterX - x, 2) + Math.pow(tileCenterY - y, 2));

                if (distance < minDistance) {
                    minDistance = distance;
                    closestIndex = parseInt(refKey.replace('tile-', ''), 10); // Extract the index
                }
            });

            return closestIndex;
        },
        async validateSolution() {
            const response = await fetch(`${this.backendUrl}/verify-captcha`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ folder: this.folder, user_solution: this.userOrder }),
            });

            if (!response.ok) {
                window.console.error("Failed to validate solution:", response.statusText);
                return;
            }

            const result = await response.json();
            if (result.success) {
                this.solved = true; // Apply the tint effect

                window.console.log(result.message)
                // Delay transition to next screen
                setTimeout(() => {
                    window.console.log("Next screen triggered!");
                    this.$emit('solved');
                }, 1500);
            } else {
                window.console.log(result.message); // "Incorrect order. Try again!"
            }
        }
    }
};
</script>

<style>
.panel {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    text-align: center;
}

.puzzle-panel {
    max-height: 100vh;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    height: 100%;
    top: 0;
    border: 2px solid black;
    z-index: 2;
}

.puzzle-panel h2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Bangers', Arial, sans-serif;
    font-size: 1.1rem;
    color: black;
    letter-spacing: 2px;
    padding: 8px;
    border: 1px solid black;
    background-color: #ffe89a;
}


#robot-sign {
    position: absolute;
    right: -80px;
    max-width: 160px;
    height: auto;
    z-index: 2;
}

.puzzle-grid {
    display: grid;
    grid-template-columns: repeat(3, 23vh);
    grid-template-rows: repeat(3, 23vh);
    justify-content: center;
    gap: 1px;
    margin: 20px auto 0 auto;
    background-color: var(--dark-3);
    border-radius: 10px;
}

.puzzle-grid img {
    width: 23vh;
    height: 23vh;
    border: black solid 1px;
}

.puzzle-grid img.center {
    border-color: var(--blue-3);
}

.puzzle-grid img.no-border {
    border: none !important; /* Remove borders completely */
}



.center-tile {
    border: 3px solid var(--blue-3) !important; /* Blue border to mark the correct tile */
    box-sizing: border-box; /* Ensure border doesn't shift the tile's size */
}




#validate-button .arrow-simple {
    width: 40px;
    height: auto;
    filter: brightness(0);
    transition: filter 0.3s ease, color 0.3s ease;
}

#validate-button:hover .arrow-simple {
    filter: brightness(1);
}



.buttons-box {
    display: flex;
    background-color: rgb(248 244 244 / 63%);
    color: black;
    align-items: center;
    justify-content: space-evenly;

    max-height: 60px;
    width: 100%;
    margin: 3rem auto;
    position: relative;
}

button {
    flex: 1;
    max-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Bangers', Arial, sans-serif;
    font-size: 1.8rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

button:hover {
    color: #ffe89a
}

#cancel-button {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

#cancel-button img {
    max-width: 40px;
    height: auto;
}

#validate-button img {
    max-width: 34px;
    height: auto;
}

@media (max-width: 768px) {
    button {
        padding: 10px 15px; /* Uniform padding for both buttons */
        border: none;
        border-radius: 5px; /* Same rounded corners for both buttons */
        font-size: 0.8rem;
        flex-shrink: 0; /* Prevents buttons from shrinking in the flex container */
        height: auto; /* Ensures consistent height */
        width: 120px; /* Ensures both buttons have the same width */
        text-align: center;
    }
    .puzzle-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: max(100vh, 500px);
        box-sizing: border-box;
    }

    .puzzle-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        margin: 0 auto;
        border-radius: 5px;
    }

    .puzzle-grid img {
        width: 13vh;
        height: 13vh;
        object-fit: cover;
    }

    .puzzle-panel h2 {
        font-size: 1.2rem;
        padding: 8px;
        width: 90%;
        margin: 10px 0;
        border: 2px solid black;
        border-radius: 8px;
        background-color:#ffe89a;
        text-align: center;
    }

    .buttons-box {
        width: 100%;
        padding: 8px;
        background-color: rgb(255, 255, 255);
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 100;
        border-radius:0 0 8px 8px;
    }

    #robot-sign {
        position: relative;
        right: 0;
        max-width: 185px;
        height: auto;
        z-index: 1;
    }
}

</style>
